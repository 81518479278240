import { NEUTRAL_GRAY_400 } from '../../defaults/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`

  & h2 {
    color: ${NEUTRAL_GRAY_400};
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 20px;
  }

  & p {
    color: ${NEUTRAL_GRAY_400};
    display: flex;
    justify-content: start;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px;
  }

  & p svg {
    margin-top: 3px;
    margin-right: 10px;
  }

  & p span {
    flex: 1;
  }

`;
