export const getHashParams = (url) => {
  let params = url.split('?')[1];

  if (!params) {
    params = url.split('#')[1];
  }

  if (!params) {
    return {};
  }

  const hashParams = params.split('&').reduce(function (res, item) {
    const parts = item.split('=');

    if (parts[0]) {
      res[parts[0]] = parts[1];
    }

    return res;
  }, {});

  return hashParams;
}