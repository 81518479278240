import moment from "moment";
import { DatePickerGlobalStyle, StyledDatePicker } from './styles';
import localePtBR from 'antd/lib/date-picker/locale/pt_BR';
import localeUnUS from 'antd/lib/date-picker/locale/en_US';
import localeEsES from 'antd/lib/date-picker/locale/es_ES';
import locateKkKz from 'antd/lib/date-picker/locale/kk_KZ';

import 'moment/locale/pt-br';
import { useTranslation } from "react-i18next";

const locales = {
  'pt-BR': localePtBR,
  'en-US': localeUnUS,
  'es-MX': localeEsES,
  'kk-KZ': locateKkKz
};

const DatePicker = props => {

  const { i18n } = useTranslation();

  moment.updateLocale(i18n.language.toLowerCase(), {
    week: {
      dow: 1
    }
  });

  return (
    <>
      <StyledDatePicker
        locale={locales[i18n.language]}
        {...props} />
      <DatePickerGlobalStyle />
    </>
  );
}

export default DatePicker;
