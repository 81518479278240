import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Dropdown from "../../external/dropdown";
import FilterItem from "./filter-item";
import Menu from "../../external/menu";
import { FILTER_TYPE } from "@frontend/shared-defaults";

const Filter = ({ children, items, onSelectionChange }) => {

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [headerSelected, setHeaderSelected] = useState([]);

  const addSelected = value => setSelected([...new Set([...selected, ...value])]);

  const removeSelected = value => setSelected(selected.filter(item => !value.includes(item)));

  const getItemByValue = value => items.find(item => item.value === value);

  const getItemsByHeader = header => items.filter(item => item.header === header);

  const handleFilterChange = (value, itemSelected, type) => {
    const header = type === FILTER_TYPE.HEADER ? value : getItemByValue(value).header;
    const valuesList = type !== FILTER_TYPE.HEADER
      ? [value]
      : items.filter(item => item.header === value).map(item => item.value);

    if (itemSelected) {
      if (getItemsByHeader(header).every(item => [...selected, ...valuesList].includes(item.value))) {
        setHeaderSelected([...headerSelected, header]);
      }
      addSelected(valuesList);
    } else {
      setHeaderSelected(headerSelected.filter(item => item !== header));
      removeSelected(valuesList);
    }
  };

  const handleOpenChange = flag => {
    setOpen(flag);
  };

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selected);
    }
  }, [onSelectionChange, selected]);

  const checkIsSelected = filterItem => filterItem.type === FILTER_TYPE.HEADER
    ? headerSelected.includes(filterItem.value)
    : selected.includes(filterItem.value);

  const menu =
    <Menu
      items={
        items.map(
          filterItem => ({
            key: filterItem.value,
            label:
              <FilterItem
                type={filterItem.type}
                color={filterItem.color}
                label={filterItem.label}
                icon={filterItem.icon}
                iconColor={filterItem.iconColor}
                onSelectionChange={handleFilterChange}
                value={filterItem.value}
                isSelected={checkIsSelected(filterItem)}
              />
          })
        )
      }
    />;

  return (
    <Dropdown
      onOpenChange={handleOpenChange}
      open={open}
      overlay={menu}>
      {children}
    </Dropdown>
  )
}

Filter.propTypes = {
  children: PropTypes.element,
  items: PropTypes.array.isRequired,
  onSelectionChange: PropTypes.func,
}

export default Filter;
