import { useCallback } from 'react';
import { useTranslation as useTranslationI18n, Trans } from 'react-i18next';

export const useTranslation = () => {
  const { t } = useTranslationI18n();

  const getTranslation = useCallback(
    (key, interpolation = {}, components = {}, defaultValue = '') =>
      <Trans i18nKey={key} values={interpolation} components={components} defaultValue={defaultValue} />,
    []
  );

  const getTranslationAsString = (key, interpolation = {}, defaultValue = '') => t(key, { interpolation, defaultValue });

  const hasTranslation = (key) => t(key) !== key;

  return {
    getTranslation,
    hasTranslation,
    getTranslationAsString,
  }

}
