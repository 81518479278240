import React, { Suspense } from 'react';
import { GlobalStyle } from './screens/sms/styles';
import { LoadingScreen } from '@frontend/shared-ui';
import { ROUTE_CALLBACK, ROUTE_NOTIFY, ROUTE_SDK, ROUTE_SHARE, ROUTE_UNKNOWN } from './defaults/routes';

const App = React.lazy(() => import('./app'));
const Sdk = React.lazy(() => import('./screens/sdk'));
const Notify = React.lazy(() => import('./screens/sms/notify'));
const Callback = React.lazy(() => import('./screens/sms/callback'));
const Share = React.lazy(() => import('./screens/sms/share'));
const Unknown = React.lazy(() => import('./screens/sms/unknown'));

const getComponentByRoute = (pathName) => {
  switch (pathName) {
    case ROUTE_SDK:
      return <Sdk />;
    case ROUTE_NOTIFY:
      return <Notify />;
    case ROUTE_CALLBACK:
      return <Callback />;
    case ROUTE_SHARE:
      return <Share />;
    case ROUTE_UNKNOWN:
      return <Unknown />;
    default:
      return <App />;
  }
};

const PublicApp = () => <>
  <GlobalStyle />
  <Suspense fallback={<LoadingScreen />}>
    {getComponentByRoute(window.location.pathname)}
  </Suspense>
</>;

export default PublicApp;
