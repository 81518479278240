import { NEUTRAL_GRAY_100, NEUTRAL_GRAY_200, NEUTRAL_GRAY_400 } from "libs/shared-ui/src/defaults/colors";
import { styled } from "styled-components";

export const Wrapper = styled.div`
  align-items: center;
  background-color: ${NEUTRAL_GRAY_100};
  border-radius: 4px;
  color: ${NEUTRAL_GRAY_400};
  display: flex;
  font-size: 14px;
  gap: 10px;
  margin-bottom: 12px;
  height: 68px;
  padding: 12px;
`;

export const TextWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const IconWrapper = styled.div`
  align-items: center;
  background-color: ${NEUTRAL_GRAY_200};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  height: 44px;
  padding: 10px;
  width: 44px;
`;

export const BtnRemove = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: center;
  outline: none;
  width: 44px;
`;
