import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Currency = styled.span.attrs({ className: 'currency' })`
  margin-right: 3px;
`;

export const Integer = styled.span`
`

export const Decimal = styled.span`
`