import PropTypes from 'prop-types';
import { useNotificationSettings } from '../../hooks/useNotificationSettings';
import { StyledButton } from './styles';
import { useMemo } from 'react';
import { TEMPLATES } from '../../defaults/template-types';

const Button = ({ colorful, outline, style, ...props }) => {
  const { template } = useNotificationSettings();

  const borderRadius = useMemo(() => {
    if (
      template?.buttons?.borderRadius &&
      template?.buttons?.borderRadius !== -1
    ) {
      return template.buttons.borderRadius;
    }
    return style.borderRadius;
  }, [style.borderRadius, template?.buttons?.borderRadius]);

  if(template?.type === TEMPLATES.CREDIT_IFRAME_ONE_PAGE) {
    style.fontSizeLink = 16;
  }

  if (!colorful || !template) {
    return (
      <StyledButton
        outline={outline}
        style={{
          ...style,
          ...(outline ? { color: template.buttons?.colors?.background } : {}),
          borderRadius,
        }}
        colorful={colorful}
        {...props}
      />
    );
  }

  return (
    <StyledButton
      style={{
        ...style,
        backgroundColor: template?.buttons?.colors?.background,
        borderRadius,
        color: template?.buttons?.colors?.text,
      }}
      outline={outline}
      colorful={colorful}
      {...props}
    />
  );
};

Button.defaultProps = {
  colorful: false,
  outline: false,
  style: {},
};

Button.propTypes = {
  colorful: PropTypes.bool,
  outline: PropTypes.bool,
  style: PropTypes.object,
};

export default Button;
