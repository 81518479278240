import { Icon } from '@frontend/shared-ui';
import { useUpload } from 'libs/feature-upload/src/hook';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Wrapper } from './styles';

const ImagePreview = ({ size }) => {

  const { file } = useUpload();

  const imageURL = useMemo(() =>
    file && URL.createObjectURL(file), [file]);

  if (!file) {
    return (
      <Wrapper
        height={size.height}
        width={size.width}>
        <Icon type="image" />
      </Wrapper>
    )
  }

  return (
    <Wrapper
      imageURL={imageURL}
      height={size.height}
      width={size.width} />
  )
}

ImagePreview.propTypes = {
  size: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  })
}

export default ImagePreview;
