import styled from 'styled-components';

export const Wrapper = styled.header`
  align-items: center;
  display: flex;
  min-height: 64px;
  justify-content: space-between;
  padding-bottom: 20px;

  & h2 {
    font-size: 14px;
    height: 0;
    transition: .2s height ease-out, .2s margin-bottom ease-out, 1s opacity ease;
    margin-bottom: 0;
    opacity: 0;
  }

  & h2:has(span) {
    opacity: 1;
    height: 20px;
    margin-bottom: 0.5em;
  }
`