import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from '@frontend/shared-ui';
import { useProfiles } from '@frontend/shared-context';
import Dropdown from '../../external/dropdown';
import Menu from '../../external/menu';
import MenuItem from '../../external/menu/menu-item';
import Avatar from '../avatar';
import SidebarButton from './sidebar-button';
import { CompanyKind, CompanyList, Logout, StyledTooltip, Wrapper } from './styles';
import { PROFILE_TYPE_COMPANY, PROFILE_TYPE_ORGANIZATION } from 'libs/shared-context/src/lib/context/defaults/profile-types';
import Organization from '../../external/menu/organization';
import { COMPANIES_KIND } from '@frontend/pay/shared-defaults';
import { useTranslation } from '@frontend/feature-i18n';

const Sidebar = ({ sidebarSettings }) => {
  const { getTranslation } = useTranslation();
  const { profiles, selectedProfile, setSelectedProfile, setSelectedProfileType } = useProfiles();
  const dropdownRef = useRef(null);

  const logoutItem = useMemo(() => {
    return sidebarSettings.logout ? {
      key: 'logout',
      label: <Logout onClick={sidebarSettings.logout}>
        Sair <Icon type='logout' />
      </Logout>
    } : null;
  }, [sidebarSettings.logout]);

  const createMenuItem = useMemo(() => {
    return (company, type, isOrganization) => {

      if (!company) {
        return null;
      }

      const ItemComponent = isOrganization ? Organization : MenuItem;

      return <ItemComponent
        key={company.id}
        label={company.name}
        logo={company.logo}
        onClick={() => {
          setSelectedProfile(company);
          setSelectedProfileType(type);
          dropdownRef.current.close();
        }}
        showAvatar />;
    }
  }, [setSelectedProfile, setSelectedProfileType, dropdownRef]);

  const getCompaniesAgruparedByKind = useMemo(() => {
    return () => {
      let kinds = {}

      if (!sidebarSettings.showCompanyKinds) return kinds;

      profiles?.organization?.companies.forEach(company => {
        if (!kinds[company.kind]) {
          kinds = {
            ...kinds,
            [company.kind]: [],
          }
        }

        kinds[company.kind].push(company);
      });

      return kinds;
    }
  }, [sidebarSettings.showCompanyKinds, profiles?.organization?.companies]);

  const organizationItem = useMemo(() => {
    return {
      key: profiles?.organization?.id,
      label: createMenuItem(profiles?.organization, PROFILE_TYPE_ORGANIZATION, sidebarSettings.showCompanyKinds)
    }
  }, [createMenuItem, profiles?.organization, sidebarSettings.showCompanyKinds]);

  const menuItens = useMemo(() => {
    if (!sidebarSettings.showCompanyKinds && profiles?.organization?.companies?.length) {
      return profiles?.organization?.companies.map(
        company => ({
          key: company.id,
          label: createMenuItem(company, PROFILE_TYPE_COMPANY),
        })
      );
    }

    const companiesAgruparedByKind = getCompaniesAgruparedByKind();

    const companiesKind = Object.keys(companiesAgruparedByKind);

    if (companiesKind.length === 1) {
      return companiesAgruparedByKind[companiesKind[0]].map(
        company => ({
          key: company.id,
          label: createMenuItem(company, PROFILE_TYPE_COMPANY),
        })
      );
    } else {
      return companiesKind.map(kind => {
        const content = <CompanyList> {companiesAgruparedByKind[kind].map(company => createMenuItem(company, PROFILE_TYPE_COMPANY))} </CompanyList>;

        return {
          key: kind,
          label:
            <StyledTooltip title={content} placement="rightTop" color={"white"} trigger={'hover'} showArrow={false} >
              <CompanyKind>
                {COMPANIES_KIND[kind]}
                <Icon type='chevron-right' />
              </CompanyKind>
            </StyledTooltip>
        }
      });
    }
  }, [createMenuItem, getCompaniesAgruparedByKind, profiles?.organization?.companies, sidebarSettings.showCompanyKinds]);

  const menu = useMemo(() => {

    const items = [
      organizationItem,
      ...menuItens,
      logoutItem
    ];

    return <Menu items={items} />
  }, [logoutItem, menuItens, organizationItem]);

  return (
    <Wrapper>
      <Dropdown
        disabled={!profiles?.organization?.companies?.length}
        showBackdrop={!!profiles?.organization?.companies?.length}
        overlay={menu}
        title={!sidebarSettings.showCompanyKinds && getTranslation('chooseCompany')}
        ref={dropdownRef}>
        <div style={{ marginBottom: 8 }}>
          <Avatar
            text={selectedProfile?.name}
            size={46}
            src={selectedProfile?.logo} />
        </div>
      </Dropdown>
      {
        sidebarSettings?.items?.map(({ icon, onClick, selected, title }) => (
          <Tooltip key={title} title={title} placement="right">
            <SidebarButton
              aria-label={title}
              onClick={onClick}
              selected={selected}>
              {icon}
            </SidebarButton>
          </Tooltip>
        ))
      }
    </Wrapper>
  );
}
Sidebar.defaultProps = {
  sidebarSettings: {
    items: []
  }
}
Sidebar.propTypes = {
  sidebarSettings: PropTypes.shape({
    showCompanyKinds: PropTypes.bool,
    logout: PropTypes.func,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.element.isRequired,
        onClick: PropTypes.func.isRequired,
        selected: PropTypes.bool,
        title: PropTypes.string.isRequired,
      }).isRequired
    ),
  })
}
export default Sidebar;
