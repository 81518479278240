import { useContextSelector } from 'use-context-selector';
import { UploadContext } from '../context';

export const useUpload = () => {

  const callSelectFile = useContextSelector(UploadContext, context => context.callSelectFile);
  const errors = useContextSelector(UploadContext, context => context.errors);
  const file = useContextSelector(UploadContext, context => context.file);
  const fileId = useContextSelector(UploadContext, context => context.fileId);
  const progress = useContextSelector(UploadContext, context => context.progress);
  const reset = useContextSelector(UploadContext, context => context.reset);
  const status = useContextSelector(UploadContext, context => context.status);
  const send = useContextSelector(UploadContext, context => context.send);
  const setStatus = useContextSelector(UploadContext, context => context.setStatus);
  const setErrors = useContextSelector(UploadContext, context => context.setErrors);

  return {
    callSelectFile,
    errors,
    file,
    fileId,
    progress,
    status,
    send,
    setErrors,
    setStatus,
    reset,
  }
}
