import PropTypes from 'prop-types';
import { Wrapper } from "./styles";

const PageContent = ({ children }) => (
  <Wrapper>
    { children }
  </Wrapper>
)

PageContent.propTypes = {
  children: PropTypes.element,
};

export default PageContent;
