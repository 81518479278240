import { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { message } from "antd";
import { createContext } from 'use-context-selector';
import { getProfiles } from "../../integrations/profiles";
import { PROFILE_TYPE_COMPANY, PROFILE_TYPE_ORGANIZATION } from "../defaults/profile-types";
import { logout } from '../../defaults/auth-control';
import { setDataLayer } from '@frontend/feature-monitor';
import * as eventsName from '../defaults/events_track';
import { useErrorBoundary } from '@frontend/feature-error-handling';
import useAuthPkce from "../../hooks/useAuthPkce";
import useAuth from "../../hooks/useAuth";

const {
  EVENT_USER_PROPERTIES_UPDATE
} = eventsName;

const FIVE_SECONDS_TIMEOUT = 5000;

export const ProfilesContext = createContext({});

const ProfilesProvider = ({ children, authPkce, enableErrorBoundary }) => {
  const isErrorBoundaryEnabled = enableErrorBoundary === undefined ? false : enableErrorBoundary;
  const [profiles, setProfiles] = useState({});
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectedProfileType, setSelectedProfileType] = useState(null);
  const { showBoundary } = useErrorBoundary(isErrorBoundaryEnabled);
  const accessTokenPkce = useAuthPkce().accessToken;
  const accessTokenDefault = useAuth().accessToken;
  const accessToken = authPkce ? accessTokenPkce : accessTokenDefault;

  useEffect(() => {
    if (!profiles?.organization) return;

    if (profiles.organization.companies?.length > 1) {
      setSelectedProfile(profiles.organization);
      setSelectedProfileType(PROFILE_TYPE_ORGANIZATION);
    }
    else {
      setSelectedProfile(profiles.organization.companies[0]);
      setSelectedProfileType(PROFILE_TYPE_COMPANY);
    }
  }, [profiles]);

  useEffect(() => {
    (async () => {
      if (accessToken?.length) {
        try {
          const receivedProfiles = await getProfiles(accessToken, isErrorBoundaryEnabled);
          setProfiles(receivedProfiles);
          setDataLayer({
            event: EVENT_USER_PROPERTIES_UPDATE,
            company: receivedProfiles.organization.companies[0].name,
            userId: receivedProfiles.user.id,
            tenantId: receivedProfiles.organization.companies[0].id,
          });
        }
        catch (e) {
          if (isErrorBoundaryEnabled) {
            showBoundary?.(e);
            return
          }
          message.warn('Estamos com dificuldades para carregar os dados. Aguarde um instante.')
          setTimeout(() => {
            logout();
          }, FIVE_SECONDS_TIMEOUT);
        }
      }
    })()
  }, [accessToken, setProfiles, isErrorBoundaryEnabled, showBoundary]);

  const value = useMemo(() => ({
    profiles,
    selectedProfile,
    setSelectedProfile,
    selectedProfileType,
    setSelectedProfileType
  }), [profiles, selectedProfile, selectedProfileType]);

  return (
    <ProfilesContext.Provider value={value}>
      {children}
    </ProfilesContext.Provider>
  )
}

ProfilesProvider.propTypes = {
  children: PropTypes.element,
  authPkce: PropTypes.bool,
  enableErrorBoundary: PropTypes.bool
};

export default ProfilesProvider;
