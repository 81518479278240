import { Radio } from 'antd';
import styled from 'styled-components';
import { BLACK, NEUTRAL_GRAY_200, NEUTRAL_GRAY_300 } from '../../defaults/colors';

export const RadioGroup = styled(Radio.Group)`
  & .ant-radio-button-wrapper:first-child {
    border-radius: 8px 0 0 8px;
  }

  & .ant-radio-button-wrapper:last-child {
    border-radius: 0 8px 8px 0;
  }

  & .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
  & .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::after {
    background-color: ${NEUTRAL_GRAY_300};
  }

  & .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
  & .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:last-child {
    border-color: ${NEUTRAL_GRAY_300};
  }

  & .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: ${NEUTRAL_GRAY_200};
    border-color: ${NEUTRAL_GRAY_300};
    color: ${BLACK};
  }

  & .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):hover {
    color: ${BLACK};
  }
`

export const RadioButton = styled(Radio.Button)`
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  
  & > span:not(.ant-radio-button) {
    display: flex;

    ${({ icon }) => icon && `
      padding-left: 30px;
      align-items: center;
    `}
  }

  & svg {
    left: 18px;
    position: absolute;
  }

  &:last-of-type {
    border-radius: 0 8px 8px 0;
  }
`