import { Table } from 'antd';
import styled from 'styled-components';
import { BLACK, BLUE_400, NEUTRAL_GRAY_100, NEUTRAL_GRAY_200, NEUTRAL_GRAY_400, NEUTRAL_GRAY_600 } from '../../defaults/colors';
import Button from '../../lib/button';
import Icon from '../../lib/icon';

const getItemRender = (_, type, originalElement, t) => {
  if (type === 'prev') {
    return (
      <Button transparent>
        <Icon type="arrow-left" />
        {t('Prev')}
      </Button>
    );
  }
  if (type === 'next') {
    return (
      <Button transparent>
        {t('Next')}
        <Icon type="arrow-right" />
      </Button>
    );
  }
  return originalElement;
};

export const StyledTable = styled(Table).attrs(props => ({
  ...props,
  pagination: {
    ...props.pagination,
    itemRender: (_, type, originalElement) => (getItemRender(_, type, originalElement, props.t)),
    position: ['bottomCenter'],
    showSizeChanger: false
  },
}))`

  .ant-table,
  .ant-table-thead > tr > th {
    background: none;
    color: ${NEUTRAL_GRAY_400};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .ant-table-thead > tr > th {
    color: ${NEUTRAL_GRAY_600};
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 16px;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-pagination {
    align-items: baseline;
  }

  .ant-pagination-item {
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    color: ${NEUTRAL_GRAY_400};
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    transition: .25s background-color ease;
    text-align: center;
    width: 40px;
  }

  .ant-pagination-item:not(.ant-pagination-item-active):hover {
    background-color: ${NEUTRAL_GRAY_100};
  }

  .ant-pagination-item:not(.ant-pagination-item-active):hover a {
    color: ${BLACK};
  }

  .ant-pagination-item-active {
    background-color: ${NEUTRAL_GRAY_200};
    color: ${BLUE_400};
  }

  .ant-pagination-prev {
    position: absolute;
    left: 0;
  }

  .ant-pagination-next {
    position: absolute;
    right: 0;
  }

`;
