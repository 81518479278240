import { WHITE } from '../../../defaults/colors';
import styled, { createGlobalStyle } from 'styled-components';
 
export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Open-Sans, Helvetica, Sans-Serif;
  }
  #root {
    display: flex;
    height: 100%;
    overflow-x: hidden;
  }
`;

export const Container = styled.div`
  background: ${WHITE};
  display: flex;
  flex: 1;
`
