import styled, { createGlobalStyle } from 'styled-components';
import Button from './components/button';
import { NEUTRAL_GRAY_500, NEUTRAL_GRAY_900, WHITE, WHITE_SMOKE } from './ui/colors';

export const GlobalStyle = createGlobalStyle`
  @media screen and (max-width: 600px) {
    html,body {
      height: auto;
      min-height: 100%;
    }
  }

  html,body {
    display: flex;
    justify-content: center;
  }

  body {
    margin: 0;
    padding: 0;
  }

  #root-unico-idpay-ecommerce {
    background: ${WHITE_SMOKE};
    height: 100%;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .swal2-styled {
    background: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    outline: none !important;
  }

  .swal2-html-container {
    color: #767676;
    font-size: 14px;
    margin: 0 0 14px 0;
    padding: 8px 24px;
  }

  .swal2-html-container p {
    margin: 0;
  }

  .swal2-title p {
    color: #131516!important;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }

  .swal2-title img {
    margin-bottom: 16px;
  }

  .swal2-actions {
    margin-top: 0;
  }
`;

export const PageTitle = styled.h1`
  color: ${NEUTRAL_GRAY_900};
  font-size: 20px;
  line-height: 30px;
  width: 100%;
  display: inline-block;
  margin-top: 16px;
  max-width: 400px;
`;

export const PageSubtitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  padding-top: 8px;
  max-width: 400px;
  justify-self: center;
  color: ${NEUTRAL_GRAY_500};
`;

export const BtnSelectDevice = styled(Button)`
  padding: 15px !important;
  font-weight: normal;
  gap: 5px;

  & svg {
    width: 25px;
  }
`;

export const SelectDeviceArea = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  @media screen and (max-width: 450px) {
    & {
      flex-direction: column;
    }
  }
`;

export const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: absolute;
  background: ${WHITE};
  top: 0;
  width: 100%;

  & span {
    font-size: 24px;
  }

  & p {
    font-size: 12px;
    margin-top: 8px;
  }
`;

export const ButtonsArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding-top: 24px;
  align-items: center;
  max-width: 500px;
`;

export const FormContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  max-width: 400px;
  text-align: left;

  & input {
    font-size: 16px;
    height: 44px;
  }

  & form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  & .ant-form-item {
    margin-bottom: 0;
  }
`;
