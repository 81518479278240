import PropTypes from 'prop-types';
import { Label, Value } from './styles';

const Info = ({ label, value, bold }) => (
  <div>
    <Label>{label}</Label>
    <Value bold={bold}>{value || '-'}</Value>
  </div>
);

Info.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  bold: PropTypes.bool,
};

export default Info;
