import styled from 'styled-components';

export const Wrapper = styled.main`
  flex: 1;
  padding: 24px 32px;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d6d6d6;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #fcfcfc;
    border-radius: 20px;
  }
`
