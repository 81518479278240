import { useMemo } from 'react';
import { useErrorBoundary as useThirdPartyErrorBoundary } from 'react-error-boundary';

export const useErrorBoundary = (enableErrorBoundary = false) => {
  
  const defaultHookResponse = useMemo(() => ({
    showBoundary: () => {/* */},
    resetBoundary: () => {/* */}
  }), [])

  return (
    enableErrorBoundary
      // eslint-disable-next-line react-hooks/rules-of-hooks
      ? useThirdPartyErrorBoundary()
      : defaultHookResponse
  )
}

