import { useTranslation } from '@frontend/feature-i18n';
import PropTypes from 'prop-types';
import { getCardConsultStatus } from '../../defaults/card-consult';
import { Wrapper } from './styles';

const CardConsultStatus = ({ status: propStatus }) => {
  const { getTranslation } = useTranslation();
  const status = getCardConsultStatus(getTranslation)[propStatus];

  return (
    <Wrapper
      labelBackgroundColor={status?.colors?.labelBackground}
      labelColor={status?.colors?.label}
    >
      {status?.label}
    </Wrapper>
  );
};

CardConsultStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default CardConsultStatus;
