import PropTypes from 'prop-types';
import { useUpload } from '../../hook';
import { Bar, Wrapper } from "./styles";

const ProgressBar = ({ color }) => {

  const { progress } = useUpload();

  return (
    <Wrapper>
      <Bar color={color} percentage={progress} />
    </Wrapper>
  );
}

ProgressBar.propTypes = {
  color: PropTypes.string,
}

export default ProgressBar
