import PropTypes from 'prop-types';
import { Icon } from "@frontend/shared-ui";
import { UPLOAD_STATUS, UPLOAD_STATUS_FAIL, UPLOAD_STATUS_LOADING, UPLOAD_STATUS_SUCCESS, UPLOAD_STATUS_WAITING, UPLOAD_STATUS_WARNING } from "../../defaults/status";
import { useUpload } from "../../hook";
import ProgressBar from "../progress-bar";
import { FileDisplay, FoundErrors, Link, StatusArea, Wrapper } from "./styles";

const UploadDoc = ({ label, failLabel, successLabel }) => {

  const { callSelectFile, errors, file, status, reset } = useUpload();

  if (status === UPLOAD_STATUS_WAITING) {
    return (
      <Wrapper>
        <StatusArea>
          <Icon type="clip" />
        </StatusArea>
        {
          file
          ? (
            <FileDisplay>
              <span>{file.name}</span>
              <Icon type="trash" onClick={reset}/>
            </FileDisplay>
          )
          : <Link onClick={callSelectFile} >{label}</Link>
        }
      </Wrapper>
    );
  }

  if (status === UPLOAD_STATUS_LOADING) {
    return (
      <Wrapper>
        <StatusArea>
          <Icon type="clip" />
        </StatusArea>
        <ProgressBar color={UPLOAD_STATUS[status].color} />
      </Wrapper>
    );
  }

  if (status === UPLOAD_STATUS_SUCCESS) {
    return (
      <Wrapper>
        <StatusArea color={UPLOAD_STATUS[status].color}>
          <Icon type="check" />
        </StatusArea>
        {successLabel}
      </Wrapper>
    );
  }

  if (status === UPLOAD_STATUS_WARNING) {
    return (
      <Wrapper>
        <StatusArea color={UPLOAD_STATUS[status].color}>
          <Icon type="warning" />
        </StatusArea>
        {
          !!errors.length &&
          <FoundErrors>
            Detectamos alguns erros no arquivo
            <ul>
              {errors.map(
                error => <li key={`line__${error.line}`}>Linha {error.line}: {error.string}</li>
              )}
            </ul>
          </FoundErrors>
        }
      </Wrapper>
    );
  }

  if (status === UPLOAD_STATUS_FAIL) {
    return (
      <Wrapper>
        <StatusArea color={UPLOAD_STATUS[status].color}>
          <Icon type="remove" />
        </StatusArea>
        {failLabel}
      </Wrapper>
    );
  }
};

UploadDoc.defaultProps = {
  label: 'Anexe o arquivo',
  failLabel: 'Não foi possível anexar o arquivo',
  successLabel: 'Arquivo anexado com sucesso',
}

UploadDoc.propTypes = {
  label: PropTypes.string,
  failLabel: PropTypes.string,
  successLabel: PropTypes.string,
}

export default UploadDoc;
