import { NEUTRAL_GRAY_600, WHITE } from 'libs/shared-ui/src/defaults/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  background: ${WHITE};
  color: ${NEUTRAL_GRAY_600};
  border-radius: 8px;
  display: flex;
  flex-direction: row !important;
  padding: 16px;

  & > div {
    flex: 1;
    flex: initial;
    flex-direction: row;
    gap: 32px;
  }

  & > div:first-child {
    gap: 24px;
    display: flex;
    flex-direction: row;
  }

  & > div:not(:first-child) {
    flex: initial !important;
  }
`;
