import { useCallback } from 'react';
import { useContextSelector } from 'use-context-selector';
import { CompanySettingsContext } from '../../context/company-settings';

const useCompanySettings = () => {

  const loading = useContextSelector(CompanySettingsContext, context => context.loading);
  const setLoading = useContextSelector(CompanySettingsContext, context => context.setLoading);
  const settings = useContextSelector(CompanySettingsContext, context => context.settings);
  const setSettings = useContextSelector(CompanySettingsContext, context => context.setSettings);
  const settingsTrigger = useContextSelector(CompanySettingsContext, context => context.settingsTrigger);
  const setSettingsTrigger = useContextSelector(CompanySettingsContext, context => context.setSettingsTrigger);

  const pullSettingsTrigger = useCallback(() => {
    setSettingsTrigger(new Date().getTime());
  }, [setSettingsTrigger]);

  return {
    loading,
    settingsTrigger,
    setLoading,
    settings,
    setSettings,
    pullSettingsTrigger,
  }
}

export default useCompanySettings;