const allowedChars = [' '];

const validateJustText = (errorMessage = 'Digite apenas letras') =>
  () => ({
    validator(_, value) {
      if (value) {

        let cleanValue = value;

        allowedChars.forEach(
          char => { cleanValue = cleanValue.split(char).join('') }
        )

        const pattern = /^[a-z ]+$/i;

        if (!pattern.test(cleanValue)) {
          return Promise.reject(new Error(errorMessage));
        }
      }
      return Promise.resolve();
    }
  });

export default validateJustText;
