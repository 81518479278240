import PropTypes from 'prop-types';
import Empty from "../../external/empty";
import { Wrapper } from "./styles";

const NoData = ({ title, description }) => (
  <Wrapper>
    <Empty
      image={
        <>
          <svg width="240" height="174" viewBox="0 0 240 174" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1215_24253)">
              <path d="M13.6069 61.6632C16.0842 61.6632 18.0925 59.6478 18.0925 57.1617C18.0925 54.6755 16.0842 52.6601 13.6069 52.6601C11.1296 52.6601 9.12137 54.6755 9.12137 57.1617C9.12137 59.6478 11.1296 61.6632 13.6069 61.6632Z" fill="#A7E0FF" />
              <path d="M100.637 36.9817C107.34 20.8179 112.799 6.16505 117.146 5.82209C120.875 5.53049 119.152 16.8415 122.581 17.3137C126.01 17.7859 139.643 -0.511901 144.822 0.273427C150.001 1.05876 155.164 44.3943 157.754 44.6942C160.344 44.9941 160.856 23.1242 164.146 23.2898C167.437 23.4555 168.409 44.7356 168.409 44.7356" stroke="#8D7AD2" strokeMiterlimit="10" />
              <path d="M7.08221 69.316C7.08221 69.316 15.9972 49.2272 32.4503 51.4341C48.9035 53.641 51.2973 84.7907 72.2674 79.2685C79.9722 77.2356 86.8087 66.8855 92.8412 54.5704" stroke="#8D7AD2" strokeMiterlimit="10" />
              <path opacity="0.5" d="M214.749 71.3622C214.734 77.2123 212.563 82.85 208.654 87.1897C207.456 88.5208 206.114 89.7128 204.652 90.7435C201.155 93.2019 197.061 94.6663 192.803 94.982C188.545 95.2976 184.281 94.4528 180.462 92.5368C176.643 90.6208 173.41 87.7048 171.107 84.0971C168.803 80.4894 167.513 76.3241 167.374 72.0415C167.364 71.7532 167.359 71.4649 167.361 71.1733C167.486 64.9349 170.05 58.9959 174.499 54.6374C178.949 50.2789 184.927 47.8501 191.144 47.8749C197.362 47.8997 203.32 50.3761 207.735 54.7699C212.15 59.1638 214.666 65.1229 214.742 71.3622H214.749Z" fill="#8D7AD2" />
              <path d="M167.374 72.0414C167.364 71.7531 167.359 71.4648 167.361 71.1732C167.366 69.3544 167.579 67.5421 167.996 65.772C169.467 65 170.638 64.7779 171.123 65.3048C171.99 66.2409 170.319 69.2165 167.374 72.0414Z" fill="#8D7AD2" />
              <path d="M190.986 73.4114C191.802 71.3646 190.049 68.7369 187.071 67.5423C184.093 66.3477 181.019 67.0386 180.203 69.0854C179.388 71.1322 181.141 73.7599 184.118 74.9545C187.096 76.1491 190.171 75.4582 190.986 73.4114Z" fill="#8D7AD2" />
              <path d="M203.664 58.3685C204.616 57.5118 204.592 55.9278 203.611 54.8306C202.63 53.7334 201.063 53.5385 200.112 54.3952C199.16 55.252 199.184 56.8359 200.165 57.9331C201.146 59.0303 202.713 59.2253 203.664 58.3685Z" fill="#8D7AD2" />
              <path d="M213.676 78.3605C209.607 72.1111 202.996 63.2653 198.983 64.7217C192.902 66.9319 195.351 89.1447 193.461 89.3783C191.57 89.612 188.808 84.224 186.923 84.789C184.825 85.4186 182.992 90.3277 182.064 93.2785" stroke="#8D7AD2" strokeWidth="2" strokeMiterlimit="10" />
              <path d="M172.666 56.2736C175.943 59.5955 179.391 62.5463 180.687 61.7941C182.606 60.6807 181.594 53.7437 181.594 53.7437C181.594 53.7437 187.283 64.107 191.022 62.9572C193.548 62.1802 190.737 51.8566 189.441 47.5489" stroke="#8D7AD2" strokeWidth="2" strokeLinejoin="round" />
              <path opacity="0.5" d="M68.9375 47.3864L47.8436 39.4967L41.2118 37.0181L25.145 31.0088L58.5383 5.5867L63.1873 2.0461L63.813 6.97345L68.9375 47.3864Z" fill="#8D7AD2" />
              <path opacity="0.5" d="M41.1788 122.627L32.4916 160.258L71.7853 151.056L41.1788 122.627Z" fill="#8C6B94" />
              <path d="M53.7144 146.885C53.7144 146.885 73.4513 171.337 82.0163 171.008C85.6235 170.87 80.121 161.824 82.0542 161.373C83.9875 160.923 89.4372 169.815 96.1548 166.476C106.579 161.297 94.9991 129.307 117.031 118.843C139.062 108.379 148.582 127.074 161.228 127.888C173.874 128.701 201.263 99.4484 211.624 101.017C221.985 102.586 189.297 146.454 195.714 154.942C199.381 159.791 207.917 151.931 211.266 153.169C214.614 154.407 209.41 160.043 212.302 160.972C215.195 161.902 235.967 149.902 235.967 149.902" stroke="#070047" strokeMiterlimit="10" />
              <path d="M121.769 30.3693C121.769 30.3693 115.05 33.2389 113.514 38.047C111.979 42.855 117.032 48.0425 117.032 48.0425L133.109 44.6676L121.769 30.3693Z" fill="#2D254D" />
              <path d="M137.237 74.6841C140.034 70.3245 140.99 65.0283 139.896 59.9607C138.802 54.8932 135.747 50.4693 131.403 47.6623C127.059 44.8554 121.781 43.8952 116.732 44.9931C111.682 46.091 107.274 49.157 104.477 53.5167L137.237 74.6841Z" fill="#A5CAF3" />
              <path d="M129.117 50.2279L132.419 37.9675C132.419 37.9675 136.809 36.8425 136.739 33.8503C136.67 30.8581 133.505 31.4645 133.505 31.4645C133.505 31.4645 133.01 27.2695 128.907 26.0186C124.805 24.7677 121.435 29.0605 120.983 32.4602C120.323 37.4804 121.873 40.3301 127.025 40.3367L124.879 47.0037" fill="#8D7AD2" />
              <path d="M123.833 33.0252L122.519 35.0531" stroke="#070047" strokeMiterlimit="10" />
              <path d="M130.027 46.8512C125.855 44.4787 131.735 30.3975 131.735 30.3975C126.669 31.9863 121.931 29.6651 121.931 29.6651C121.931 29.6651 124.803 22.9385 132.194 25.538C139.586 28.1376 140.137 33.3863 140.137 33.3863C140.137 33.3863 147.789 37.2185 146.399 43.8243C145.009 50.43 137 53.1554 137 53.1554C137 53.1554 133.343 48.7367 130.027 46.8512Z" fill="#2D254D" />
              <path d="M59.2335 17.3008C59.6841 17.4956 60.0234 17.9329 60.17 18.5522C60.3167 19.1715 60.2583 19.9397 59.9324 20.6988C59.6064 21.4579 59.0901 22.0282 58.5409 22.3466C57.9917 22.665 57.442 22.7178 56.9914 22.523C56.5409 22.3282 56.2016 21.8909 56.055 21.2716C55.9083 20.6523 55.9667 19.8841 56.2926 19.125C56.6185 18.3659 57.1348 17.7956 57.6841 17.4772C58.2333 17.1588 58.783 17.106 59.2335 17.3008Z" fill="white" stroke="white" />
              <path d="M176.344 143.374L176.912 156.183L170.12 173.278L169.552 160.469L176.344 143.374Z" fill="#A7E0FF" />
              <path opacity="0.5" d="M164.163 174L117.166 155.2L116.599 142.391L163.595 161.191L164.163 174Z" fill="#8D7AD2" />
              <path d="M123.515 125.634L116.918 142.245L163.454 160.859L170.051 144.247L123.515 125.634Z" fill="white" />
              <path d="M123.658 125.964L169.73 144.394L163.316 160.545L117.239 142.113L123.654 125.964M123.377 125.318L116.597 142.383L163.592 161.183L170.374 144.118L123.38 125.316L123.377 125.318Z" fill="#1F493D" />
              <path opacity="0.5" d="M177.356 16.8961L180.727 10.8272L200.525 5.80207L197.152 11.871L177.356 16.8961Z" fill="#8D7AD2" />
              <path d="M208.094 34.0839L211.466 28.0134L206.458 8.14653L203.087 14.2154L208.094 34.0839Z" fill="#A7E0FF" />
              <path d="M202.908 14.536L183.591 19.4378L188.475 38.8237L207.792 33.9219L202.908 14.536Z" fill="white" />
              <path d="M202.729 14.8201L207.491 33.7227L188.656 38.4993L183.891 19.595L202.729 14.8151M203.087 14.2104L183.289 19.2355L188.296 39.104L208.094 34.0789L203.087 14.2104Z" fill="#070047" />
              <path d="M208.654 87.1898C207.457 88.5208 206.114 89.7129 204.652 90.7436C204.35 89.9782 204.066 89.1531 203.812 88.2849C202.491 83.7386 202.468 79.7391 203.78 79.3531C205.093 78.967 207.236 82.3353 208.568 86.8849C208.596 86.9876 208.626 87.0887 208.654 87.1898Z" fill="#8D7AD2" />
              <path d="M20.0175 80.1673L2.68597 121.223L20.0383 128.601L37.3698 87.5448L20.0175 80.1673Z" fill="white" />
              <path d="M20.1563 80.4978L37.0452 87.6817L19.9053 128.274L3.00976 121.096L20.1563 80.4978ZM19.8938 79.8467L2.36095 121.36L20.1678 128.931L37.6941 87.4183L19.8872 79.8467H19.8938Z" fill="#070047" />
              <path d="M37.6922 87.4134C38.4494 85.6196 35.0771 82.4707 30.1598 80.3801C25.2426 78.2895 20.6426 78.0488 19.8853 79.8426C19.1281 81.6364 22.5004 84.7853 27.4177 86.8759C32.3349 88.9665 36.9349 89.2072 37.6922 87.4134Z" fill="#A7E0FF" />
              <path d="M20.1672 128.925C20.9244 127.131 17.5521 123.982 12.6349 121.892C7.71765 119.801 3.1176 119.56 2.36036 121.354C1.60313 123.148 4.97547 126.297 9.89269 128.387C14.8099 130.478 19.41 130.719 20.1672 128.925Z" fill="#8D7AD2" fillOpacity="0.5" />
              <path d="M140.795 53.1637C140.795 53.1637 143.234 53.3874 144.441 56.2471C145.648 59.1067 146.113 70.2438 150.769 74.7371C155.424 79.2303 166.19 80.1714 169.452 77.5536C172.866 74.8133 173.284 71.8592 176.536 71.6687C179.789 71.4781 180.896 73.521 180.589 74.0296C180.282 74.5382 177.134 74.4736 176.827 75.1894" stroke="#2D254D" strokeMiterlimit="10" />
              <path d="M135.663 79.5171C135.663 79.5171 148.961 108.18 182.219 84.0535" stroke="#2D254D" strokeMiterlimit="10" />
              <path d="M69.7748 28.4225C69.7748 28.4225 75.1915 46.2813 79.3502 48.2479C83.5089 50.2146 102.557 53.8032 102.557 53.8032" stroke="#2D254D" strokeMiterlimit="10" />
              <path d="M78.0527 22.9485C78.0527 22.9485 85.2491 37.0893 90.1193 38.4429C94.9895 39.7966 103.063 38.9764 110.488 42.0415" stroke="#2D254D" strokeMiterlimit="10" />
              <path d="M66.4748 21.7821C66.4748 21.7821 58.1805 21.1708 57.9477 20.185C57.7149 19.1992 69.2318 18.2863 69.2318 18.2863" stroke="#2D254D" strokeMiterlimit="10" />
              <path d="M71.2868 20.9171C71.2868 20.9171 68.7741 22.574 68.6453 21.7903C68.5165 21.0066 69.6358 17.6483 72.0148 17.6234C74.3938 17.5986 78.848 19.6215 77.5867 21.5882C76.3254 23.5548 73.5221 21.6395 73.5221 21.6395" stroke="#2D254D" strokeMiterlimit="10" />
              <path d="M63.5755 21.507C63.5755 21.507 63.5507 23.979 65.616 24.3236C67.6813 24.6682 70.6926 25.0575 70.899 24.1065C71.1053 23.1555 68.1931 23.2781 67.8926 22.1184" stroke="#2D254D" strokeMiterlimit="10" />
              <path d="M69.2066 24.7064C69.2066 24.7064 71.3792 25.8496 70.7238 26.557C70.3143 26.9961 65.6191 24.327 65.6191 24.327" stroke="#2D254D" strokeMiterlimit="10" />
              <path d="M179.538 70.4046C179.538 70.4046 185.78 69.3889 186.697 71.2495C187.05 71.9653 180.608 73.6005 180.608 73.6005" stroke="#2D254D" strokeMiterlimit="10" />
              <path d="M183.584 74.868C183.584 74.868 184.175 80.6933 180.613 80.5409C179.65 80.4995 182.049 76.4303 180.631 76.0675" stroke="#2D254D" strokeMiterlimit="10" />
              <path d="M183.61 78.629C183.61 78.629 185.02 82.597 183.076 83.268C182.637 83.4188 182.702 80.8988 181.99 80.5509" stroke="#2D254D" strokeMiterlimit="10" />
              <path d="M122.527 31.7693C122.527 31.2834 122.27 30.8895 121.952 30.8895C121.635 30.8895 121.378 31.2834 121.378 31.7693C121.378 32.2551 121.635 32.649 121.952 32.649C122.27 32.649 122.527 32.2551 122.527 31.7693Z" fill="#070047" />
              <path d="M125.985 32.4925C125.986 32.0066 125.731 31.6117 125.414 31.6104C125.096 31.6091 124.837 32.002 124.835 32.4878C124.834 32.9737 125.089 33.3686 125.406 33.3699C125.724 33.3712 125.983 32.9783 125.985 32.4925Z" fill="#070047" />
              <path d="M66.0638 24.7163C66.0638 24.7163 53.9294 25.775 53.629 28.4441C53.3285 31.1132 61.0466 32.3972 61.1093 32.9357C61.1721 33.4742 52.4915 35.0465 52.7589 37.3412C53.0264 39.6358 68.3173 43.9966 68.3173 43.9966" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
              <path d="M51.6231 10.8521C51.6231 10.8521 61.6937 7.8533 61.8819 9.50846C62.1345 11.6822 41.0522 20.0358 41.0803 21.469C41.1083 22.9021 48.5094 21.966 48.6894 22.97C48.8693 23.9741 30.7092 33.0915 30.7092 33.0915" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
              <path d="M47.3593 33.6864C47.6329 35.0293 47.6735 36.8121 47.4317 38.805L41.8385 36.709C42.4285 34.9987 43.1499 33.5876 43.8889 32.6271C44.2909 32.1047 44.6828 31.7358 45.0422 31.5185C45.3979 31.3034 45.6927 31.2525 45.9362 31.3039C46.2011 31.3615 46.4749 31.5549 46.7325 31.9584C46.9915 32.3641 47.2083 32.9453 47.3593 33.6864Z" fill="white" stroke="white" />
              <path d="M106.51 80.0621C89.5844 82.5954 65.7814 101.205 66.9552 112.68C68.129 124.155 95.6367 143.584 95.6367 143.584C95.6367 143.584 90.9894 149.938 92.1384 154.837L113.004 135.257L94.1294 116.764L113.851 103.97L131.275 110.181L125.548 149.923L149.974 160.726C149.974 160.726 148.323 153.378 143.613 150.606C143.613 150.606 161.32 104.439 154.811 93.5021C148.301 82.5655 123.09 77.5818 106.51 80.0621Z" fill="#2D254D" />
              <path d="M88.3413 110.678L105.45 127.501" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
              <path d="M131.549 106.122L130.615 112.473" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
              <path d="M134.838 13.314C137.315 13.314 139.323 11.2986 139.323 8.8125C139.323 6.32636 137.315 4.31094 134.838 4.31094C132.36 4.31094 130.352 6.32636 130.352 8.8125C130.352 11.2986 132.36 13.314 134.838 13.314Z" fill="#A7E0FF" />
            </g>
            <defs>
              <clipPath id="clip0_1215_24253">
                <rect width="240" height="174" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <h1>{title}</h1>
        </>
      }
      description={description}
    />
  </Wrapper>
);

NoData.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default NoData;
