import { BLACK, NEUTRAL_GRAY_400 } from '../../../defaults/colors';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Label = styled.div`
  color: ${NEUTRAL_GRAY_400};
  font-size: 14px;
  line-height: 20px;
`;

export const Value = styled.div`
  color: ${BLACK};
  font-size: 16px;
  line-height: 24px;

  ${({ bold }) => bold && `font-weight: bold;`}
`;
