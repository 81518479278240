import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { IconWrapper, Link, TextWrapper, Wrapper } from './styles';
import File from './file';
import FILE_TYPES from '../../defaults/file-types';
import { Icon } from '@frontend/shared-ui';

const FakeUpload = ({ label, rules, onChangeFiles }, ref) => {

  const form = useRef();
  const inputRef = useRef();

  const [files, setFiles] = useState([]);

  const reset = () => {
    setFiles([]);
  }

  const removeFile = useCallback(key => {
    setFiles(
      files.filter(file => file.key !== key)
    );
  }, [files]);

  const addFile = file => {
    setFiles([...files, { key: (new Date()).getTime(), file }]);
  }

  const handleInputFileChange = evt => {
    const [inputFile] = evt.target.files;
    addFile(inputFile);

    form.current.reset();
  };

  const rulesLabel = useMemo(() => {

    if (!rules) return null;

    const labels = [];

    if (rules.maxSize) {
      labels.push(`Max ${rules.maxSize}mb`);
    }

    if (rules.extension) {
      labels.push(`Formato ${rules.extension}`);
    }

    return labels.join(' | ');

  }, [rules]);

  const inputDisabledByMaxFiles = useMemo(() => {
    if (!rules.maxFiles) return false;

    return rules.maxFiles.qty === files.length;
  }, [files.length, rules.maxFiles]);

  useImperativeHandle(ref, () => ({
    reset,
  }), []);

  useEffect(() => {
    onChangeFiles(files.map(({ file }) => file));
  }, [files, onChangeFiles]);

  return (
    <>
      <form ref={form}>
        <input
          accept={FILE_TYPES[rules.extension].type}
          data-testid="file-input"
          hidden
          onChange={handleInputFileChange}
          ref={inputRef}
          type="file" />
      </form>
      {
        files.map(({ key, file }) =>
          <File
            key={key}
            name={file.name}
            removeFile={() => removeFile(key)} />)
      }
      <Wrapper data-testid="clickable-area" disabled={inputDisabledByMaxFiles} onClick={() => inputRef?.current?.click?.()}>
        <IconWrapper>
          <Icon type="file-upload" />
        </IconWrapper>
        <TextWrapper>
          {
            inputDisabledByMaxFiles
            ? rules.maxFiles.message
            : <Link>{label}</Link>
          }
          {
            rulesLabel &&
            <span>{rulesLabel}</span>
          }
        </TextWrapper>
      </Wrapper>
    </>
  )
};

export default forwardRef(FakeUpload);
