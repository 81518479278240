import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useAntdTable } from 'ahooks';
import { useTranslation } from '@frontend/feature-i18n';
import NoData from '../no-data';
import { StyledTable } from './styles';
import { Form, Icon, Input } from "../..";

const TableTransactions = ({ columns, filters, selectedProfile, cacheKey, getMethod, onClickTransaction, searchTrigger, setSearch, setLastSearch }) => {

  const { getTranslation } = useTranslation();

  const [form] = Form.useForm();

  const location = useLocation();

  const { tableProps, search } = useAntdTable(getMethod, {
    form,
    cacheKey,
    refreshDeps: [filters]
  });

  const loading = useMemo(() =>
    tableProps.loading || !selectedProfile,
    [selectedProfile, tableProps.loading]
  );

  useEffect(() => {
    if (location?.state?.search?.length) {
      setSearch(location?.state?.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldValue('search', location?.state?.search);
    form.setFieldValue('filters', filters);

    search.submit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, location, searchTrigger]);

  useEffect(() => {
    if (!tableProps.loading) {
      setLastSearch(moment().format('hh:mm:ss'));
    }
  }, [tableProps.loading, setLastSearch]);

  return (
    <>
      <Form form={form}>
        <Form.Item hidden name="filters">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item hidden name="search">
          <Input type="hidden" />
        </Form.Item>
      </Form>
      <StyledTable
        locale={{
          emptyText:
            loading
              ? <NoData
                title="Carregando"
                description={`Buscando transações`} />
              : <NoData
                title={`Sem transações`}
                description={`Não encontramos transações para essa busca`} />
        }}
        columns={columns}
        style={{ flex: 1 }}
        className={onClickTransaction ? 'is-clickable' : ''}
        rowKey="id"
        t={getTranslation}
        onRow={item => ({
          onClick: () => onClickTransaction?.(item.id),
        })}
        {...tableProps}
        loading={{
          spinning: loading,
          indicator: <Icon type="loading" />
        }} />
    </>
  );
}

TableTransactions.propTypes = {
  cacheKey: PropTypes.string,
  columns: PropTypes.array,
  getMethod: PropTypes.func,
  filters: PropTypes.object,
  onClickTransaction: PropTypes.func,
  searchTrigger: PropTypes.any,
  selectedProfile: PropTypes.object,
  setSearch: PropTypes.func.isRequired,
  setLastSearch: PropTypes.func.isRequired,
};

export default TableTransactions;
