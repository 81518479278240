import { AnimatedInteger } from '@frontend/shared-ui';
import PropTypes from 'prop-types';
import { Currency, Decimal, Integer, Wrapper } from './styles';
import { useMemo } from 'react';

export const HighlightAmount = ({ currency, value }) => {

  const integer = Math.trunc(value);
  const decimal = Math.trunc(Number((value - integer).toFixed(2).slice(2)));

  const decimalPart = useMemo(() => {
    if (decimal === 0) {
      return <>00</>
    }

    return <>{`${decimal}`.length < 2 && '0'}<AnimatedInteger value={decimal} /></>
  }, [decimal]);

  return (
    <Wrapper>
      <Currency>{currency}</Currency>
      <Integer><AnimatedInteger value={integer} />,</Integer>
      <Decimal>{decimalPart}</Decimal>
    </Wrapper>
  )

}

HighlightAmount.defaultProps = {
  currency: 'R$'
}

HighlightAmount.propTypes = {
  currency: PropTypes.string,
  value: PropTypes.number,
}

export default HighlightAmount;
