import CurrencyInput from './input';
import styled from 'styled-components';
import { NEUTRAL_GRAY_400 } from '../../defaults/colors';

export const StyledCurrencyInput = styled(CurrencyInput)`
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  font-size: 16px;
  line-height: 16px;
  padding: 4px 11px 4px 35px;
  transition: .2s box-shadow ease;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 100%);

  &[aria-invalid="true"] {
    border: 1px solid red;
  }

  &:focus {
    border: 1px solid #3995f7;
    outline: none;
    box-shadow: 0 0 0 2px rgb(57 149 247 / 20%);
  }

  &[aria-invalid="true"]:focus {
    border: 1px solid red;
    box-shadow: 0 0 0 2px rgb(255 0 0 / 15%);
  }
`

export const Wrapper = styled.div`
  &::before {
    content: "R$";
    color: ${NEUTRAL_GRAY_400};
    font-size: 16px;
    line-height: 16px;
    display: block;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    padding: 4px 5px 4px 11px;
    pointer-events: none;
  }
`
