export const validateCPF = (rawCPF) => {
  const cpf = rawCPF.replace(/[^\d]+/g, '');

  if (cpf === '') return false;

  const invalidCPFs = new Set([
    '00000000000', '11111111111', '22222222222', '33333333333',
    '44444444444', '55555555555', '66666666666', '77777777777',
    '88888888888', '99999999999'
  ]);

  if (cpf.length !== 11 || invalidCPFs.has(cpf)) {
    return false;
  }

  const calculateDigit = (cpf, factor) => {
    let total = 0;
    for (let i = 0; i < factor - 1; i++) {
      total += parseInt(cpf.charAt(i)) * (factor - i);
    }
    const remainder = total % 11;
    return remainder < 2 ? 0 : 11 - remainder;
  };

  const firstDigit = calculateDigit(cpf, 10);
  const secondDigit = calculateDigit(cpf, 11);

  return firstDigit === parseInt(cpf.charAt(9)) && secondDigit === parseInt(cpf.charAt(10));
};
