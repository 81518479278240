import styled from 'styled-components';
import Button from '../../ui/button';
import { BLACK, BLUE_400 } from '../../ui/colors';

export const StyledButton = styled(Button).attrs(
  ({ style, outline, colorful, link, ...attrs }) => ({
    disabled: attrs.disabled,
    style: {
      background: style.backgroundColor || (colorful && BLACK),
      color: style.color,
      height: '48px',
      maxWidth: '400px',
      justifyContent: 'center',
      width: style.width ?? '100%',
      ...(style.borderRadius
        ? {
            borderRadius: `${style.borderRadius}px`,
          }
        : {}),
      ...(colorful
        ? {
            fontSize: 16,
          }
        : {}),
      ...(outline
        ? {
            fontSize: 16,
            fontWeight: 400,
            borderColor: style.color || BLACK,
            color: style.color || BLACK,
          }
        : {}),
      ...(link
        ? {
            color: BLUE_400,
            fontSize: `${style?.fontSizeLink || 14}px` ,
            fontWeight: 400,
            textDecoration: 'underline',
          }
        : {}),
    },
    iconSize: 16,
  })
)``;
