import { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { createContext } from 'use-context-selector';
import {getAccessTokenWithIntercept, getIdentityURLWithCodeChallengeWithIntercept } from "../../integrations/auth-pkce";
import { getHashParams, getByCookie } from "@frontend/shared-utility";
import {
  getAccessTokenFromStorage,
  setAccessTokenInStorage,
} from "../../defaults/auth-control";


export const AuthPkceContext = createContext({});

const AuthPkceProvider = ({ children }) => {

  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    (async () => {
      let accessToken = getAccessTokenFromStorage();
      const authorizationCode = getHashParams(window.location.href).code;

      if (!accessToken && !authorizationCode) {
        window.location.href = await getIdentityURLWithCodeChallengeWithIntercept()
        return;
      }

      if (!accessToken) {
        accessToken = await getAccessTokenWithIntercept(authorizationCode, getByCookie('codeVerifier'))
      }

      setAccessToken(accessToken);
      setAccessTokenInStorage(accessToken);

    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(() => ({
    accessToken,
    setAccessToken,
  }), [accessToken]);

  return (
    <AuthPkceContext.Provider value={value}>
      {children}
    </AuthPkceContext.Provider>
  )
}

AuthPkceProvider.propTypes = {
  children: PropTypes.element
};

export default AuthPkceProvider;
