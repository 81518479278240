import {
  REPORT_STATUS,
  REPORT_STATUS__PROCESSING,
  REPORT_STATUS__SUCCESS,
  REPORT_STATUS__ERROR,
  REPORT_STATUS__EXPIRED,
} from '@frontend/shared-defaults';
import { GREEN_300, GREEN_400, RED_400, YELLOW_400, NEUTRAL_GRAY_400, WARNING_300 } from './colors';

export const REPORT_STATUS_WITH_COLORS = {
  ...REPORT_STATUS,
  [REPORT_STATUS__SUCCESS]: {
    ...REPORT_STATUS[REPORT_STATUS__SUCCESS],
    colors: {
      label: GREEN_400,
      indicator: GREEN_300,
      detailsIndicator: GREEN_300,
    },
  },
  [REPORT_STATUS__ERROR]: {
    ...REPORT_STATUS[REPORT_STATUS__ERROR],
    colors: {
      label: RED_400,
      indicator: RED_400,
      detailsIndicator: RED_400,
    },
  },
  [REPORT_STATUS__EXPIRED]: {
    ...REPORT_STATUS[REPORT_STATUS__EXPIRED],
    colors: {
      label: YELLOW_400,
      indicator: YELLOW_400,
      detailsIndicator: WARNING_300,
    },
  },
  [REPORT_STATUS__PROCESSING]: {
    ...REPORT_STATUS[REPORT_STATUS__PROCESSING],
    colors: {
      label: NEUTRAL_GRAY_400,
      indicator: NEUTRAL_GRAY_400,
      detailsIndicator: NEUTRAL_GRAY_400,
    },
  }
}

export const REPORT_STATUS_WITH_COLORS_ARRAY =
  Object.keys(REPORT_STATUS_WITH_COLORS)
    .map(key => ({ ...REPORT_STATUS_WITH_COLORS[key], value: key }));
