import PropTypes from 'prop-types';
import Button from '../../../lib/button';
import { Wrapper } from './styles';
import Column from '../../../lib/column';
import Input from '../../input';
import { useTranslation } from '@frontend/feature-i18n';

const columnsGap = 12;
const inputDateWidth = '170px';

const Footer = ({ apply, closePicker, dates: [startDate, endDate] }) => {
  const { getTranslation } = useTranslation();

  return <Wrapper>
      <Column style={{ gap: columnsGap }}>
        <Input value={startDate?.format('ll')} style={{ width: inputDateWidth }} />
        <span>-</span>
        <Input value={endDate?.format('ll')} style={{ width: inputDateWidth }} />
      </Column>
      <Column style={{ gap: columnsGap }}>
        <Button outline onClick={closePicker}>{getTranslation('Cancel')}</Button>
        <Button disabled={!(startDate && endDate)} primary onClick={apply}>{getTranslation('Apply')}</Button>
      </Column>
    </Wrapper>
};

Footer.propTypes = {
  apply: PropTypes.func.isRequired,
  closePicker: PropTypes.func.isRequired,
  dates: PropTypes.array.isRequired,
}

export default Footer;