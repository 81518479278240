import PropTypes from 'prop-types';
import { REPORT_STATUS_WITH_COLORS } from '../../defaults/reports';
import { Wrapper } from "./styles";

const ReportStatus = ({ status: propStatus }) => {
  const status = REPORT_STATUS_WITH_COLORS[propStatus];

  return (
    <Wrapper indicatorColor={status.colors.indicator} labelColor={status.colors.label}>
      {status.label}
    </Wrapper>
  );
}

ReportStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default ReportStatus;
