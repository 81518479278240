import PropTypes from 'prop-types';
import { Wrapper } from "./styles";

const PageContentBody = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
);

PageContentBody.propTypes = {
  children: PropTypes.element,
};

export default PageContentBody;
