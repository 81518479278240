import { Menu } from "antd";
import styled from "styled-components";

export const StyledMenu = styled(Menu)`
  padding: 0;
  border-radius: 8px;
  margin-top: 12px;

  & .ant-dropdown-menu-item {
    padding: 0;
  }

  & .ant-dropdown-menu-item:first-child {
    border-radius: 10px 10px 0 0;
  }

  & .ant-dropdown-menu-item:last-child {
    border-radius: 0 0 10px 10px;
  }

  & .ant-dropdown-menu-item:first-child:last-child {
    border-radius: 10px;
  }
`
