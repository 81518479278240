export const CHARGEBACK_STATUS__NONE = 'none';
export const CHARGEBACK_STATUS__ANALYZING = 'analyzing';
export const CHARGEBACK_STATUS__WAITING = 'waiting';
export const CHARGEBACK_STATUS__PAID = 'paid';
export const CHARGEBACK_STATUS__REFUSED = 'refused';
export const CHARGEBACK_STATUS__APPROVED = 'approved';

export const getChargebackStatus = (getTranslation) => ({
  [CHARGEBACK_STATUS__NONE]: {
    label: getTranslation('transaction.chargeback.none'),
    detailLabel: getTranslation('transaction.chargeback.none'),
  },
  [CHARGEBACK_STATUS__WAITING]: {
    label: getTranslation('transaction.chargeback.waiting.label'),
    detailLabel:getTranslation('transaction.chargeback.waiting.detailLabel'),
  },
  [CHARGEBACK_STATUS__ANALYZING]: {
    label: getTranslation('transaction.chargeback.analyzing.label'),
    detailLabel: getTranslation('transaction.chargeback.analyzing.detailLabel'),
  },
  [CHARGEBACK_STATUS__APPROVED]: {
    label: getTranslation('transaction.chargeback.approved.label'),
    detailLabel: getTranslation('transaction.chargeback.approved.detailLabel'),
  },
  [CHARGEBACK_STATUS__REFUSED]: {
    label: getTranslation('transaction.chargeback.refused.label'),
    detailLabel: getTranslation('transaction.chargeback.refused.detailLabel'),
  },
});
