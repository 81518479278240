import { setDataLayer } from "../../defaults/gtm";

export const useEventsTrack = () => {
  const prefix = `${process.env.NX_PUBLIC_PRODUCT_NAME || 'EVENT'}_`;

  const sendEventSegment = async (event, data, type) => {
    const eventName = `${prefix}${event}`;
    await window.analytics[type](eventName, data);
  };

  const sendEventGTM = (event, data, dataLayerName) => {
    const eventName = `${prefix}${event}`;
    setDataLayer({ event: eventName, ...data }, dataLayerName);
  };

  const trackGTM = (event, data = {}, dataLayerName = 'dataLayer') => sendEventGTM(event, data, dataLayerName);
  const trackSegment = async (event, data = {}) => await sendEventSegment(event, data, 'track');
  const trackPageSegment = async (event, data = {}) => await sendEventSegment(event, data, 'page');
  const trackIdentify = (identify) => window.analytics.identify(identify);

  return {
    trackSegment,
    trackGTM,
    trackPageSegment,
    trackIdentify
  };
};
