import { useLDClient } from 'launchdarkly-react-client-sdk';

export const identifyClientLD = ({ userId, tenantId }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ldClient = useLDClient();

  if (userId && tenantId) {
    const userContext = {
      kind: 'user',
      key: userId,
      clientId: tenantId,
    };

    ldClient.identify(userContext);
  }
};
