import { DatePicker } from 'antd';
import 'moment/locale/pt-br';
import {default as localePtBR}from 'antd/lib/date-picker/locale/pt_BR';
import {default as localeUnUS}from 'antd/lib/date-picker/locale/en_US';
import {default as localeEsES}from 'antd/lib/date-picker/locale/es_ES';
import {default as locateKkKz}from 'antd/lib/date-picker/locale/kk_KZ';

import PropTypes from 'prop-types';
import moment from "moment";
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Footer from './footer';
import { RangePickerGlobalStyle } from './styles';
import { useTranslation } from 'react-i18next'

const locales = {
  'pt-BR': localePtBR,
  'en-US': localeUnUS,
  'es-MX': localeEsES,
  'kk-KZ': locateKkKz
};

const { RangePicker: AntdRangePicker } = DatePicker;

const defaultDates = [null, null];

const RangePicker = forwardRef(({ onChange }, ref) => {
  const { i18n } = useTranslation()

  moment.updateLocale(i18n.language.toLowerCase(), {
    week: {
      dow: 1
    }
  });

  const pickerRef = useRef();

  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState(defaultDates);

  const reset = () => setDates(defaultDates);

  const closePicker = () => {
    setTimeout(reset, 300);
    setOpen(false);
    pickerRef.current.blur();
  }

  const apply = () => {
    onChange(dates);
    closePicker();
  }

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
      pickerRef.current.focus();
    },
    reset,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [setOpen, setDates]);

  return (
    <>
      <RangePickerGlobalStyle />
      <AntdRangePicker
        dates={dates}
        locale={locales[i18n.language]}
        open={open}
        renderExtraFooter={() => <Footer apply={apply} closePicker={closePicker} dates={dates} />}
        popupStyle={{ fontSize: 16 }}
        disabled={false}
        onCalendarChange={newDates => setDates(newDates)}
        ref={pickerRef}
        value={dates} />
    </>
  )
});

RangePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default RangePicker;
