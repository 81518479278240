import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export const initI18n = (resources, defaultLanguage = 'pt-BR') => {
  const i18nConfig = {
    resources,
    fallbackLng: defaultLanguage,
    defaultNS: 'translations'
  };

  i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init(i18nConfig)
}
