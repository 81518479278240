const validateURL = (errorMessage = 'URL inválida') =>
  () => ({
    validator(_, value) {
      if (!value) {
        return Promise.resolve();
      }

      try {
        new URL(value);
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(new Error(errorMessage))
      }
    }
  });

export default validateURL;
