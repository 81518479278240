import PropTypes from 'prop-types';
import { BtnRemove, IconWrapper, TextWrapper, Wrapper } from './styles';
import { Icon } from '@frontend/shared-ui';

const File = ({ name, removeFile }) => {
  return (
    <Wrapper>
      <IconWrapper>
        <Icon type="file" />
      </IconWrapper>
      <TextWrapper>{name}</TextWrapper>
      <BtnRemove onClick={removeFile}>
        <Icon type="trash" />
      </BtnRemove>
    </Wrapper>
  );
};

File.propTypes = {
  name: PropTypes.string.isRequired,
  removeFile: PropTypes.func.isRequired,
};

export default File;
