import styled from 'styled-components';
import { NEUTRAL_GRAY_600 } from '../../defaults/colors';

export const StyledFieldsetLegend = styled.legend`
  .ant-form & {
    border: none;
    color: ${NEUTRAL_GRAY_600};
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
`;
