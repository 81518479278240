import { DatePicker } from "antd";
import { createGlobalStyle, styled } from "styled-components";
import { NEUTRAL_GRAY_400 } from "../../defaults/colors";

export const DatePickerGlobalStyle = createGlobalStyle`
  .ant-picker-date-panel .ant-picker-cell-inner {
    border-radius: 50% !important;
    width: 32px;
    height: 32px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-radius: 50%;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  border-radius: 8px !important;
  font-size: 16px;
  line-height: 16px;
  height: 44px;

  .ant-input {
    line-height: 24px;
  }

  .ant-picker-input > input {
    font-size: 16px;
  }

  &.ant-input-affix-wrapper > input.ant-input {
    padding-left: 3px;
  }

  .ant-input ::placeholder {
    color: ${NEUTRAL_GRAY_400};
    font-size: 16px;
  }

`
