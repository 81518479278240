import PropTypes from 'prop-types';
import { Component }  from 'react'
import ReactDOM from 'react-dom'
import mask from './mask.js'

class CurrencyInput extends Component {
    constructor(props) {
        super(props);
        this.prepareProps = this.prepareProps.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.setSelectionRange = this.setSelectionRange.bind(this);
        this.state = this.prepareProps(this.props);

        this.inputSelectionStart = 1;
        this.inputSelectionEnd = 1;
    }

    getMaskedValue() {
        return this.state.maskedValue;
    }

    prepareProps(props) {
        let customProps = {...props};
        delete customProps.onChange;
        delete customProps.onChangeEvent;
        delete customProps.value;
        delete customProps.decimalSeparator;
        delete customProps.thousandSeparator;
        delete customProps.precision;
        delete customProps.inputType;
        delete customProps.allowNegative;
        delete customProps.allowEmpty;
        delete customProps.prefix;
        delete customProps.suffix;
        delete customProps.selectAllOnFocus;
        delete customProps.autoFocus;

        let initialValue = props.value;
        if (initialValue === null) {
            initialValue = props.allowEmpty? null : '';
        }else{

            if (typeof initialValue === 'string') {
                if (props.thousandSeparator === ".") {
                  initialValue = initialValue.replace(/\./g, '');
                }

                if (props.decimalSeparator !== "."){
                  initialValue = initialValue.replace(new RegExp(props.decimalSeparator, 'g'), '.');
                }

                initialValue = initialValue.replace(/[^0-9-.]/g, '');
                initialValue = Number.parseFloat(initialValue);
            }
            initialValue = Number(initialValue).toLocaleString(undefined, {
                style                : 'decimal',
                minimumFractionDigits: props.precision,
                maximumFractionDigits: props.precision
            })

        }

        const { maskedValue, value } = mask(
            initialValue,
            props.precision,
            props.decimalSeparator,
            props.thousandSeparator,
            props.allowNegative,
            props.prefix,
            props.suffix
        );

        return { maskedValue, value, customProps };
    }

    componentWillReceiveProps(nextProps) {
        this.setState(this.prepareProps(nextProps));
    }

    componentDidMount(){
        let node = ReactDOM.findDOMNode(this.theInput);
        let selectionStart, selectionEnd;

        if (this.props.autoFocus) {
            this.theInput.focus();
            selectionEnd = this.state.maskedValue.length - this.props.suffix.length;
            selectionStart = selectionEnd;
        } else {
            selectionEnd = Math.min(node.selectionEnd, this.theInput.value.length - this.props.suffix.length);
            selectionStart = Math.min(node.selectionStart, selectionEnd);
        }

        this.setSelectionRange(node, selectionStart, selectionEnd);
    }

    componentWillUpdate() {
        let node = ReactDOM.findDOMNode(this.theInput);
        this.inputSelectionStart = node.selectionStart;
        this.inputSelectionEnd = node.selectionEnd;
    }

    componentDidUpdate(prevProps, prevState){
        const { decimalSeparator } = this.props;
        let node = ReactDOM.findDOMNode(this.theInput);
        let isNegative = (this.theInput.value.match(/-/g) || []).length % 2 === 1;
        let minPos = this.props.prefix.length + (isNegative ? 1 : 0);
        let selectionEnd = Math.max(minPos, Math.min(this.inputSelectionEnd, this.theInput.value.length - this.props.suffix.length));
        let selectionStart = Math.max(minPos, Math.min(this.inputSelectionEnd, selectionEnd));

        let regexEscapeRegex = /[-[\]{}()*+?.,\\^$|#\s]/g;
        let separatorsRegex = new RegExp(decimalSeparator.replace(regexEscapeRegex, '\\$&') + '|' + this.props.thousandSeparator.replace(regexEscapeRegex, '\\$&'), 'g');
        let currSeparatorCount = (this.state.maskedValue.match(separatorsRegex) || []).length;
        let prevSeparatorCount = (prevState.maskedValue.match(separatorsRegex) || []).length;
        let adjustment = Math.max(currSeparatorCount - prevSeparatorCount, 0);

        selectionEnd = selectionEnd + adjustment;
        selectionStart = selectionStart + adjustment;

        const precision = Number(this.props.precision);

        let baselength = this.props.suffix.length
            + this.props.prefix.length
            + (precision > 0 ? decimalSeparator.length : 0)
            + precision
            + 1;

        if (this.state.maskedValue.length === baselength){
            selectionEnd = this.theInput.value.length - this.props.suffix.length;
            selectionStart = selectionEnd;
        }

        this.setSelectionRange(node, selectionStart, selectionEnd);
        this.inputSelectionStart = selectionStart;
        this.inputSelectionEnd = selectionEnd;
    }

    setSelectionRange(node, start, end) {
      if (document.activeElement === node) {
        node.setSelectionRange(start, end);
      }
    }

    handleChange(event) {
        event.preventDefault();
        let { maskedValue, value } = mask(
            event.target.value,
            this.props.precision,
            this.props.decimalSeparator,
            this.props.thousandSeparator,
            this.props.allowNegative,
            this.props.prefix,
            this.props.suffix
        );

        event.persist();

        this.setState({ maskedValue, value }, () => {
            this.props.onChange(maskedValue, value, event);
            this.props.onChangeEvent(event, maskedValue, value);
        });
    }

    handleFocus(event) {
        if (!this.theInput) return;

        let selectionEnd = this.theInput.value.length - this.props.suffix.length;
        let isNegative = (this.theInput.value.match(/-/g) || []).length % 2 === 1;
        let selectionStart = this.props.prefix.length + (isNegative ? 1 : 0);
        this.props.selectAllOnFocus && event.target.setSelectionRange(selectionStart, selectionEnd);
        this.inputSelectionStart = selectionStart;
        this.inputSelectionEnd = selectionEnd;
    }


    handleBlur(event) {
        this.inputSelectionStart = 0;
        this.inputSelectionEnd = 0;
    }

    render() {
        return (
            <input
                ref={(input) => { this.theInput = input; }}
                type={this.props.inputType}
                value={this.state.maskedValue}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onMouseUp={this.handleFocus}
                {...this.state.customProps}
            />
        )
    }
}

CurrencyInput.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    decimalSeparator: PropTypes.string,
    thousandSeparator: PropTypes.string,
    precision: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    inputType: PropTypes.string,
    allowNegative: PropTypes.bool,
    allowEmpty: PropTypes.bool,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    selectAllOnFocus: PropTypes.bool
};


CurrencyInput.defaultProps = {
    onChange: function(maskValue, value, event) {/*no-op*/},
    onChangeEvent: function(event, maskValue, value) {/*no-op*/},
    autoFocus: false,
    value: '0',
    decimalSeparator: '.',
    thousandSeparator: ',',
    precision: '2',
    inputType: 'text',
    allowNegative: false,
    prefix: '',
    suffix: '',
    selectAllOnFocus: false
};


export default CurrencyInput
