
import ptBrTranslations from './resources/pt-br';
import enUsTranslations from './resources/en-us';
import esMXTranslations from './resources/es-mx';
import kkKzTranslations from './resources/kk-kz';

export const resourcesI18n = {
  'pt-BR': ptBrTranslations,
  'en-US': enUsTranslations,
  'es-MX': esMXTranslations,
  'kk-KZ': kkKzTranslations
};
