import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { createContext } from 'use-context-selector';
import { getTransactionDetails } from 'apps/pay/e-commerce/src/app/integrations/notification';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { SDK__INJECT_TRANSACTION_ID } from '../../defaults/action-types';
import { usePayEventsTrack } from '../../../../hooks/usePayEventsTrack';
import { isIframe } from '../../utility/is-frame';
import { getToken } from '../../utility/handle-token';
import { CURRENT_STEP_TO_STEP } from '../../defaults/transaction-steps';
import { STEP_CONFIRM_OWNER_DATA, STEP_ERROR, STEP_EXPIRED, STEP_INCORRECT_LINK, STEP_PREPARE, STEP_PRESENTATION, STEP_VALIDATION_FRICTIONLESS } from '../../defaults/steps';
import { ERROR__TOKEN_EXPIRED, ERROR__TOKEN_INVALID, ERROR__UNAUTHORIZED } from '../../../../defaults/errors';
import { TEMPLATE__ECOMMERCE_CONTEXTFUL } from '../../defaults/template-types';
import { ROUTE_SHARE } from '../../../../defaults/routes';
import { isRoute } from '../../utility/is-route';

export const NotificationSettingsContext = createContext({});

const NotificationSettingsProvider = ({ children }) => {
  const urlParams = new URLSearchParams(window.location.search);

  const token = getToken();
  const utm = isRoute(ROUTE_SHARE)
    ? 'share'
    : urlParams.get('utm_source');
  const id = urlParams.get('id');

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [company, setCompany] = useState(null);
  const [concluded, setConcluded] = useState(null);
  const [captureDate, setCaptureDate] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null);
  const [captureConcluded, setCaptureConcluded] = useState(false);
  const [identity, setIdentity] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [template, setTemplate] = useState(null);
  const [orderSummary, setOrderSummary] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [desktopError, setDesktopError] = useState(false);
  const [analyticsId, setAnalyticsId] = useState(null);
  const [transactionToken, setTransactionToken] = useState(token);
  const [transactionId, setTransactionId] = useState(id);
  const [belvoURL, setBelvoURL] = useState(null);
  const [currentStep, setCurrentStep] = useState(STEP_PRESENTATION);
  const [captureBehavior, setCaptureBehavior] = useState(null);
  const [nextStep, setNextStep] = useState(STEP_PREPARE);

  const LDClient = useLDClient();

  const { trackAPIError } = usePayEventsTrack();
  const featureFlags = useFlags();

  const throwDesktopError = useCallback(() => {
    setDesktopError(true);
  }, []);

  useEffect(() => {
    // Listener responsible to receive the transactionId and token from the iframe and set it in the context, 
    // triggering the useEffect that gets the transaction details right below.
    const listener = (event) => {
      const { data: message } = event;

      if (message?.type === SDK__INJECT_TRANSACTION_ID) {
        console.log('IDPay: inject transactionId message', message);
        setTransactionId(message?.transactionId);
        setTransactionToken(message?.token);
      }
    };

    if (isIframe()) {
      window.addEventListener(
        "message",
        listener,
        false,
      );
    }

    return () => {
      window.removeEventListener('message', listener);
    }
  }, []);

  useEffect(() => {
    if (!transactionId) return;

    (async () => {
      setLoading(true);

      try {
        const {
          company,
          concluded,
          captureDate,
          expirationDate,
          summary,
          config,
          redirectUrl,
          captureConcluded,
          identity,
          analyticsId,
          belvoURL,
          step,
          captureBehavior,
        } = await getTransactionDetails(transactionId, transactionToken, trackAPIError, featureFlags);

        const mode = isIframe() ? 'iframe' : 'common';

        LDClient.identify({
          kind: 'user',
          anonymous: true,
          mode,
          company,
          transactionValue: summary?.value,
        });

        window.Sentry?.setUser?.({ id: analyticsId, company });

        window.Sentry?.setTag?.('transactionId', transactionId);
        window.Sentry?.setTag?.('company', company);
        window.Sentry?.setTag?.('mode', mode);
        window.Sentry?.setTag?.('template', config?.type);

        window.hj?.('identify', analyticsId, {
          template: config?.type,
          company,
          mode,
          transactionId
        });

        const currentSteptoStep = CURRENT_STEP_TO_STEP[step];

        if (currentSteptoStep !== STEP_VALIDATION_FRICTIONLESS && currentSteptoStep !== STEP_CONFIRM_OWNER_DATA) {
          setCurrentStep(currentSteptoStep);
        }

        setCompany(company);
        setConcluded(concluded);
        setCaptureDate(captureDate);
        setExpirationDate(expirationDate);
        setCaptureConcluded(captureConcluded);
        setIdentity(identity);
        setTemplate(config); // with the template setted, the TemplateInitializer is reloaded but now with the new template
        setOrderSummary(summary);
        setRedirectUrl(redirectUrl);
        setAnalyticsId(analyticsId);
        setBelvoURL(belvoURL);
        setCaptureBehavior(captureBehavior);
        setNextStep(currentSteptoStep);
      } catch (error) {
        setError(error);

        setTemplate({ type: TEMPLATE__ECOMMERCE_CONTEXTFUL });

        switch (error?.code) {
          case ERROR__TOKEN_EXPIRED:
            setCurrentStep(STEP_EXPIRED);
            break;
          case ERROR__TOKEN_INVALID:
          case ERROR__UNAUTHORIZED:
            setCurrentStep(STEP_INCORRECT_LINK);
            break;
          default:
            setCurrentStep(STEP_ERROR);
        }
      }

      setInitialized(true);
      setLoading(false); // a useEffect from the TemplateInitializer component is triggered when this state is changed
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  const value = useMemo(
    () => ({
      company,
      concluded,
      captureConcluded,
      error,
      captureDate,
      expirationDate,
      setCaptureDate,
      identity,
      initialized,
      loading,
      orderSummary,
      redirectUrl,
      template,
      setTemplate,
      desktopError,
      throwDesktopError,
      utm,
      analyticsId,
      transactionId,
      transactionToken,
      belvoURL,
      setBelvoURL,
      currentStep,
      setCurrentStep,
      captureBehavior,
      nextStep,
      setNextStep,
    }),
    [
      company,
      concluded,
      error,
      captureConcluded,
      captureDate,
      expirationDate,
      initialized,
      loading,
      orderSummary,
      redirectUrl,
      template,
      desktopError,
      throwDesktopError,
      utm,
      analyticsId,
      identity,
      transactionId,
      transactionToken,
      belvoURL,
      setBelvoURL,
      currentStep,
      setCurrentStep,
      captureBehavior,
      nextStep,
      setNextStep,
    ]
  );

  return (
    <NotificationSettingsContext.Provider value={value}>
      {children}
    </NotificationSettingsContext.Provider>
  );
};

NotificationSettingsProvider.propTypes = {
  children: PropTypes.element,
};

export default NotificationSettingsProvider;
