import { APPLICATION_BORDER_COLOR } from '../../../../../defaults/colors';
import { APPLICATION_BORDER_WIDTH } from '../../../../../defaults/dimensions';
import styled from 'styled-components';

export const Wrapper = styled.div`
  border-top: ${APPLICATION_BORDER_WIDTH} solid ${APPLICATION_BORDER_COLOR};
  display: flex;
  gap: 32px;
  padding: 24px 0;
`
