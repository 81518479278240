import { BLUE_100 } from "libs/shared-ui/src/defaults/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  align-items: center;
  align-self: baseline;
  background: ${BLUE_100};
  border-radius: 4px;
  display: flex;
  justify-content: center;

  & svg {
    filter: invert(32%) sepia(55%) saturate(3889%) hue-rotate(204deg) brightness(95%) contrast(94%);
  }

  ${({ imageURL }) => imageURL && `
    background: url(${imageURL});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  `}

  ${({ width, height }) => `
    height: ${height}px;
    width: ${width}px;
  `}
`
