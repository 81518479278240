const validateQty = (quantity, errorMessage) =>
  () => ({
    validator(_, value) {
      if (value) {
        const valueWithoutUnderline = value.split('_').join('');

        if (quantity !== valueWithoutUnderline.length) {
          return Promise.reject(new Error(errorMessage));
        }
      }
      return Promise.resolve();
    }
  });

export default validateQty;
