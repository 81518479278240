import styled from "styled-components";
import { Dropdown } from 'antd';
import { NEUTRAL_GRAY_500, WHITE } from "../../defaults/colors";

export const Backdrop = styled.div`
  background: #0000002b;
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 0;
  pointer-events: none;
  top: 0;
  left: 0;
  transition: opacity .5s ease;
  z-index: 10;

  ${({ show }) => show && `
    opacity: 1;
  `}
`

export const StyledDropdown = styled(Dropdown)`
`

export const DropdownTitle = styled.div`
  background: ${WHITE};
  border-radius: 10px 10px 0 0;
  color: ${NEUTRAL_GRAY_500};
  font-size: 14px;
  margin-bottom: -20px;
  position: absolute;
  pointer-events: none;
  width: 100%;
  z-index: 1;
`

export const MenuWrapper = styled.div`
  background: ${WHITE};
  border-radius: 0 0 10px 10px;
  margin-top: 56px;
`
