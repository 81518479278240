import PropTypes from 'prop-types';
import UploadImage from './upload-image';
import UploadDoc from './upload-doc';
import { DEFAULT_UPLOAD_TYPE, UPLOAD_TYPE_DOC, UPLOAD_TYPE_IMAGE } from '../defaults/upload-types';

const getUploadTypesHandler = props => ({
  [UPLOAD_TYPE_IMAGE]: <UploadImage {...props} />,
  [UPLOAD_TYPE_DOC]: <UploadDoc {...props} />,
});

const Upload = ({ type, ...props }) => {
  const handler = getUploadTypesHandler(props);
  return handler[type] ?? null;
};

Upload.defaultProps = {
  type: DEFAULT_UPLOAD_TYPE,
}

Upload.propTypes = {
  type: PropTypes.oneOf([UPLOAD_TYPE_IMAGE, UPLOAD_TYPE_DOC]),
}

export default Upload
