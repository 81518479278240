import PropTypes from 'prop-types';
import { CenteredLogo, LeftColumn, RightColumn, Bar, Wrapper } from './styles';
import UserMenu from './user-menu';

const Topbar = ({ topbarSettings }) => (
  <Wrapper>
    <CenteredLogo />
    <Bar>
      <LeftColumn>
        { topbarSettings.leftContent }
      </LeftColumn>
      <RightColumn>
        { topbarSettings.rightContent }
        <UserMenu usePkce={topbarSettings.usePkce}/>
      </RightColumn>
    </Bar>
  </Wrapper>
);

Topbar.defaultProps = {
  topbarSettings: {}
}

Topbar.propTypes = {
  topbarSettings: PropTypes.shape({
    leftContent: PropTypes.element,
    rightContent: PropTypes.element,
    usePkce: PropTypes.bool,
  })
}

export default Topbar;
