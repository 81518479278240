import PropTypes from 'prop-types';
import ClickableIcon from './clickable-icon';
import IconArrowDown from './IconArrowDown';
import IconArrowLeft from './IconArrowLeft';
import IconArrowRight from './IconArrowRight';
import IconCalendar from "./IconCalendar";
import IconCard from "./IconCard";
import IconChargeback from "./IconChargeback";
import IconChargebackAnalyzing from './IconChargebackAnalyzing';
import IconChargebackApproved from './IconChargebackApproved';
import IconChargebackRefused from './IconChargebackRefused';
import IconChargebackWaiting from './IconChargebackWaiting';
import IconCheck from './IconCheck';
import IconCheckCircle from './IconCheckCircle';
import IconXCircle from './IconXCircle';
import IconCirclePlus from './IconCirclePlus';
import IconClip from './IconClip';
import IconLogout from './IconLogout';
import IconCloudDownload from './IconCloudDownload';
import IconDownload from './IconDownload';
import IconFile from './IconFile';
import IconFiles from './IconFiles';
import IconFilePlus from './IconFilePlus';
import IconFileUpload from './IconFileUpload';
import IconFilter from './IconFilter';
import IconGear from "./IconGear";
import IconHome from "./IconHome";
import IconImage from "./IconImage";
import IconLoading from './IconLoading';
import IconOptions from './IconOptions';
import IconPen from './IconPen';
import IconPlus from './IconPlus';
import IconRemove from './IconRemove';
import IconThickCheck from './IconThickCheck';
import IconTime from './IconTime';
import IconTrash from './IconTrash';
import IconSend from './IconSend';
import IconShare from './IconShare';
import IconSearch from './IconSearch';
import IconUser from './IconUser';
import IconWarning from './IconWarning';
import IconInfo from './IconInfo';
import IconInfosCheck from './IconInfosCheck';
import IconChevronLeft from './IconChevronLeft';
import IconChevronRight from './IconChevronRight';
import IconChevronUp from './IconChevronUp';
import IconChevronDown from './IconChevronDown';
import IconDesktop from './IconDesktop';
import IconLock from './IconLock';
import IconMobile from './IconMobile';
import IconCopy from './IconCopy';
import IconBrazil from './IconBrazil';
import IconKaz from './IconKaz';
import IconUSA from './IconUSA';
import IconMex from './IconMex';
import IconCamera from './IconCamera';
import IconMapPin from './IconMapPin';
import IconCheckFrictionless from './IconCheckFrictionless';
import IconChatHelp from './IconChatHelp';

const iconHandlers = {
  'desktop': IconDesktop,
  'lock': IconLock,
  'mobile': IconMobile,
  'arrow-down': IconArrowDown,
  'arrow-left': IconArrowLeft,
  'arrow-right': IconArrowRight,
  'calendar': IconCalendar,
  'card': IconCard,
  'check': IconCheck,
  'check-circle': IconCheckCircle,
  'x-circle': IconXCircle,
  'chargeback': IconChargeback,
  'chargeback-analyzing': IconChargebackAnalyzing,
  'chargeback-approved': IconChargebackApproved,
  'chargeback-refused': IconChargebackRefused,
  'chargeback-waiting': IconChargebackWaiting,
  'circle-plus': IconCirclePlus,
  'clip': IconClip,
  'cloud-download': IconCloudDownload,
  'download': IconDownload,
  'file': IconFile,
  'files': IconFiles,
  'file-plus': IconFilePlus,
  'file-upload': IconFileUpload,
  'filter': IconFilter,
  'home': IconHome,
  'image': IconImage,
  'gear': IconGear,
  'remove': IconRemove,
  'options': IconOptions,
  'pen': IconPen,
  'plus': IconPlus,
  'loading': IconLoading,
  'thick-check': IconThickCheck,
  'time': IconTime,
  'trash': IconTrash,
  'user': IconUser,
  'search': IconSearch,
  'send': IconSend,
  'share': IconShare,
  'warning': IconWarning,
  'info': IconInfo,
  'infos-check': IconInfosCheck,
  'chevron-left': IconChevronLeft,
  'chevron-right': IconChevronRight,
  'copy': IconCopy,
  'chevron-up': IconChevronUp,
  'chevron-down': IconChevronDown,
  'mex': IconMex,
  'brazil': IconBrazil,
  'kaz': IconKaz,
  'usa': IconUSA,
  'camera': IconCamera,
  'mapPin': IconMapPin,
  'check-frictionless': IconCheckFrictionless,
  'chat-help': IconChatHelp,
  'logout': IconLogout,
}

const Icon = ({ type, onClick, ...props }) => {

  if (onClick) {
    return <ClickableIcon type={type} onClick={onClick} {...props} />
  }

  const IconComponent = iconHandlers[type];
  return IconComponent && <IconComponent {...props} />
}

Icon.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string.isRequired,
}

export default Icon;
