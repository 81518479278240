import { Table } from "@frontend/shared-ui";
import styled from "styled-components";

export const StyledTable = styled(Table)`
  tr.ant-table-row:hover td{
    background: transparent;
  }
  &.is-clickable {
    td {
      cursor: pointer;
    }
  }
`;
