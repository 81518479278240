import { Suspense } from 'react';
import PropTypes from 'prop-types';
import Loader from './loader';

const ModalLazyLoader = ({ condition, component: Component }) =>
  condition ? (
    <Suspense fallback={<Loader />}>
      <Component />
    </Suspense>
  )
  : null;

ModalLazyLoader.propTypes = {
  condition: PropTypes.bool.isRequired,
  component: PropTypes.any.isRequired
};

export default ModalLazyLoader;
