import {
  CARD_CONSULT__APPROVED,
  CARD_CONSULT__UNSURE,
  CARD_CONSULT__WAITING,
} from '@frontend/shared-defaults';
import {
  NEUTRAL_GRAY_100,
  PRIMARY_400,
  NEUTRAL_GRAY_200,
  SUCCESS_500,
  WHITE,
  WARNING_300,
} from './colors';

export const getCardConsultStatus = (getTranslation) => ({
  [CARD_CONSULT__APPROVED]: {
    label: getTranslation('card-consult.approved'),
    filterLabel: getTranslation('card-consult.approved'),
    colors: {
      label: WHITE,
      indicator: WHITE,
      labelBackground: SUCCESS_500,
    },
  },
  [CARD_CONSULT__UNSURE]: {
    label: getTranslation('card-consult.unsure'),
    filterLabel: getTranslation('card-consult.unsure'),
    colors: {
      label: WHITE,
      indicator: WARNING_300,
      labelBackground: WARNING_300,
    },
  },
  [CARD_CONSULT__WAITING]: {
    label: getTranslation('card-consult.waiting'),
    filterLabel: getTranslation('card-consult.waiting'),
    colors: {
      label: PRIMARY_400,
      indicator: NEUTRAL_GRAY_200,
      labelBackground: NEUTRAL_GRAY_100,
    },
  },
});
