import PropTypes from 'prop-types';
import { Column, Icon } from "../../"
import { Wrapper } from './styles';
import { useCallback } from 'react';

const AttachedDocument = ({ label, url }) => {

  const download = useCallback(() => {
    const link = document.createElement("a");
    link.download = label;
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, [label, url]);

  return (
    <Wrapper>
      <Column>
        <Icon
          data-testid="label-btn-download-file"
          onClick={download}
          type="file" />
        {label}
      </Column>
      <Column>
        <Icon
          data-testid="btn-download-file"
          onClick={download}
          type="download" />
      </Column>
    </Wrapper>
  )
};

AttachedDocument.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default AttachedDocument;
