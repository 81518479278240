const IconMex = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#F0F0F0" />
      <path d="M23.9994 11.9997C23.9994 7.24218 21.2307 3.13143 17.2168 1.19043V22.8089C21.2307 20.868 23.9994 16.7572 23.9994 11.9997Z" fill="#D80027" />
      <path d="M0 11.9997C0 16.7572 2.76867 20.868 6.78262 22.809V1.19043C2.76867 3.13143 0 7.24218 0 11.9997Z" fill="#6DA544" />
      <path d="M8.87012 11.9992C8.87012 13.7281 10.2717 15.1297 12.0006 15.1297C13.7295 15.1297 15.131 13.7281 15.131 11.9992V10.9557H8.87012V11.9992Z" fill="#6DA544" />
      <path d="M16.1731 9.91336H13.0426C13.0426 9.33708 12.5754 8.86987 11.9991 8.86987C11.4229 8.86987 10.9557 9.33708 10.9557 9.91336H7.8252C7.8252 10.4897 8.32718 10.9568 8.90342 10.9568H8.86868C8.86868 11.5332 9.33584 12.0003 9.91217 12.0003C9.91217 12.5766 10.3793 13.0438 10.9557 13.0438H13.0426C13.619 13.0438 14.0861 12.5766 14.0861 12.0003C14.6624 12.0003 15.1296 11.5332 15.1296 10.9568H15.0948C15.6711 10.9568 16.1731 10.4897 16.1731 9.91336Z" fill="#FF9811" />
    </g>
    <defs>
      <clipPath id="clip0_1046_3741">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconMex;
