import PropTypes from 'prop-types';
import { StyledButton } from "./styles";

const SidebarButton = ({ children, onClick, selected, ...props }) => (
  <StyledButton onClick={onClick} selected={selected} {...props}>
    {children}
  </StyledButton>
);

SidebarButton.propTypes = {
  children: PropTypes.element,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
}

export default SidebarButton;
