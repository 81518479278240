import { useCallback, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { createContext } from 'use-context-selector';

export const FiltersContext = createContext({});

const FiltersProvider = ({ children }) => {

  const [filters, setFilters] = useState({});

  const addFilter = useCallback((name, defaultValue = []) => {
    if (filters[name]) return;

    setFilters({
      ...filters,
      [name]: defaultValue
    })
  }, [filters, setFilters]);

  const setFilterValue = useCallback((name, value) => {
    if (filters[name] !== value) {
      setFilters({
        ...filters,
        [name]: value
      })
    }
  }, [filters, setFilters]);

  const value = useMemo(() => ({
    addFilter,
    setFilterValue,
    filters,
  }), [addFilter, filters, setFilterValue]);

  return (
    <FiltersContext.Provider value={value}>
      {children}
    </FiltersContext.Provider>
  )
}

FiltersProvider.propTypes = {
  children: PropTypes.element,
};

export default FiltersProvider;
