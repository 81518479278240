export const FILE_EXTENSION_PDF = '.pdf';
export const FILE_EXTENSION_PNG = '.png';

const FILE_TYPES = {
  [FILE_EXTENSION_PDF]: {
    type: 'application/pdf'
  },
  [FILE_EXTENSION_PNG]: {
    type: 'image/png'
  }
};

export default FILE_TYPES;
