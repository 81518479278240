import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { createContext } from 'use-context-selector';
import moment from 'moment';
import { FILTER_DATE_30, FILTER_DATE_7, FILTER_DATE_PERIOD, FILTER_DATE_TODAY } from '@frontend/shared-defaults';

export const FilterByDateContext = createContext({});

const defaultSelectedDatePeriod = [null, null];
const defaultSelectedFilter = FILTER_DATE_30;

const FilterByDateProvider = ({ children }) => {

  const filterDatePeriodPickerRef = useRef();

  const [selectedDatePeriod, setSelectedDatePeriod] = useState(defaultSelectedDatePeriod);
  const [selectedFilter, setSelectedFilter] = useState(defaultSelectedFilter);

  const [selectedStartDatePeriod, selectedEndDatePeriod] = selectedDatePeriod;

  const resetSelectedDatePeriod = useCallback(() => {
    filterDatePeriodPickerRef.current.reset();
    setSelectedDatePeriod(defaultSelectedDatePeriod);
  }, [setSelectedDatePeriod]);

  const customFilterHandlers = useMemo(() => ({
    [FILTER_DATE_PERIOD]: () => {
      filterDatePeriodPickerRef.current.open();
    },
    [FILTER_DATE_7]: () => {
      const today = moment().endOf('date');
      const nDaysAgo = moment().subtract(7, 'd').startOf('date');

      setSelectedDatePeriod([nDaysAgo, today]);
    },
    [FILTER_DATE_30]: () => {
      const today = moment().endOf('date');
      const nDaysAgo = moment().subtract(29, 'd').startOf('day');

      setSelectedDatePeriod([nDaysAgo, today]);
    },
    [FILTER_DATE_TODAY]: () => {
      const today = moment().endOf('date');
      const nHoursAgo = moment().startOf('date');

      setSelectedDatePeriod([nHoursAgo, today]);
    },
    default: resetSelectedDatePeriod
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [
    filterDatePeriodPickerRef.current,
    resetSelectedDatePeriod,
    setSelectedDatePeriod,
  ]);

  const handleChangeDateFilter = useCallback(dateFilter => {
    if (customFilterHandlers[dateFilter]) {
      customFilterHandlers[dateFilter]();
    }
    else {
      customFilterHandlers.default();
    }

    if (dateFilter !== FILTER_DATE_PERIOD) {
      setSelectedFilter(dateFilter);
    }
  }, [customFilterHandlers, setSelectedFilter]);

  const resetFilter = useCallback(() => {
    handleChangeDateFilter(defaultSelectedFilter);
  }, [handleChangeDateFilter]);

  const filterDatePeriodLabel = useMemo(() =>
    selectedFilter === FILTER_DATE_PERIOD &&
    selectedStartDatePeriod && selectedEndDatePeriod
    ? `${selectedStartDatePeriod?.format('ll')} a ${selectedEndDatePeriod?.format('ll')}`
    : '',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStartDatePeriod, selectedEndDatePeriod]
  );

  useEffect(() => {
    handleChangeDateFilter(selectedFilter);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(() => ({
    handleChangeDateFilter,
    filterDatePeriodLabel,
    selectedDatePeriod,
    setSelectedDatePeriod: (...args) => {
      setSelectedFilter(FILTER_DATE_PERIOD);
      setSelectedDatePeriod(...args);
    },
    filterDatePeriodPickerRef,
    selectedFilter,
    resetFilter,
  }), [filterDatePeriodLabel, handleChangeDateFilter, resetFilter, selectedDatePeriod, selectedFilter])

  return (
    <FilterByDateContext.Provider value={value}>
      {children}
    </FilterByDateContext.Provider>
  )
}

FilterByDateProvider.propTypes = {
  children: PropTypes.element,
};

export default FilterByDateProvider;
