import AnimatedNumber from 'animated-number-react';
import PropTypes from 'prop-types';

const AnimatedInteger = ({ value }) =>
  <AnimatedNumber value={value} formatValue={Math.trunc} />

AnimatedInteger.propTypes = {
  value: PropTypes.number,
}

export default AnimatedInteger;