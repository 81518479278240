import { useContextSelector } from 'use-context-selector';
import { FilterByDateContext } from "../../context/filter-by-date/index"

const useFilterByDate = () => {
  const handleChangeDateFilter = useContextSelector(FilterByDateContext, context => context.handleChangeDateFilter);
  const filterDatePeriodLabel = useContextSelector(FilterByDateContext, context => context.filterDatePeriodLabel);
  const selectedDatePeriod = useContextSelector(FilterByDateContext, context => context.selectedDatePeriod);
  const setSelectedDatePeriod = useContextSelector(FilterByDateContext, context => context.setSelectedDatePeriod);
  const filterDatePeriodPickerRef = useContextSelector(FilterByDateContext, context => context.filterDatePeriodPickerRef);
  const selectedFilter = useContextSelector(FilterByDateContext, context => context.selectedFilter);
  const resetFilter = useContextSelector(FilterByDateContext, context => context.resetFilter);

  return {
    handleChangeDateFilter,
    filterDatePeriodLabel,
    selectedDatePeriod,
    setSelectedDatePeriod,
    filterDatePeriodPickerRef,
    selectedFilter,
    resetFilter,
  }
}

export default useFilterByDate;