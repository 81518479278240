import { NEUTRAL_GRAY_500 } from 'libs/shared-ui/src/defaults/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  color: ${NEUTRAL_GRAY_500};
  display: flex;
  justify-content: space-between;
  min-width: 240px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 12px 16px;

  & > div:first-child {
    align-items: center;
    display: flex;
    gap: 8px;
  }

  ${({ iconColor }) => iconColor && `
    & svg {
      filter: ${iconColor};
    }
  `}
`;

export const ColorIndicator = styled.div`
  border-radius: 50%;
  width: 12px;
  height: 12px;

  ${({ color }) => color && `
    background-color: ${color};
  `}
`;

export const IconWrapper = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
`;

export const HeaderItem = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
`;

