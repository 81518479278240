export const getByCookie = (key) => {
  const cookies = document.cookie.split(';');
  const cookie = cookies.find(cookie => {
    const cookieValues = cookie.split("=");
    return cookieValues[0].trim() === key;
  });
    
  return cookie
    ? cookie.split('=')[1]
    : null;
}
