const IconFiles = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M14 2H10C8.89543 2 8 2.89543 8 4V16C8 17.1046 8.89543 18 10 18H18C19.1046 18 20 17.1046 20 16V8M14 2L20 8M14 2V8H20"
      stroke="#1172EB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 5H6C4.89543 5 4 5.89543 4 7V20C4 21.1046 4.89543 22 6 22H16C17.1046 22 18 21.1046 18 20V18"
      stroke="#1172EB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconFiles;
