import styled from "styled-components";
import { BLUE_400, NEUTRAL_GRAY_200, NEUTRAL_GRAY_300, WARNING_400 } from "libs/shared-ui/src/defaults/colors";

export const Wrapper = styled.div`
  align-items: center;
  border: 1px dashed ${NEUTRAL_GRAY_300};
  border-radius: 4px;
  display: flex;
  gap: 12px;
  min-height: 60px;
  padding: 10px 14px;

  ${({ disabled }) => disabled && `
    pointer-events: none;
    opacity: .5;
  `}
`

export const StatusArea = styled.div`
  align-items: center;
  align-self: baseline;
  background: ${NEUTRAL_GRAY_200};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  height: 40px;
  width: 40px;

  ${({ color }) => color && `
    background: ${color};

    & svg {
      filter: brightness(255);
    }
  `}
`

export const FoundErrors = styled.div`
  font-size: 14px;
  padding: 10px;

  ul {
    color: ${WARNING_400};
    padding-left: 20px;
    margin: 8px 0 0 0;
  }
`

export const Link = styled.p`
  color: ${BLUE_400};
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  text-decoration: underline;
  user-select: none;
`

export const FileDisplay = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`
