export const ROUTE_LOGOUT = '/logout';
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_TRANSACTIONS = '/transactions';
export const ROUTE_REPORT = '/report';
export const ROUTE_REPORTS = '/reports';
export const ROUTE_PROBATIVE = '/probative';

export const ROUTE_TRANSACTION_DETAIL_BASE = '/transactions';
export const ROUTE_TRANSACTION_DETAIL_PLACEHOLDER = `${ROUTE_TRANSACTION_DETAIL_BASE}/:transactionId`;

export const DEFAULT_ROUTE = '/';
export const DEFAULT_HOME_ROUTE = ROUTE_TRANSACTIONS;
