import { NEUTRAL_GRAY_200, NEUTRAL_GRAY_400 } from "libs/shared-ui/src/defaults/colors";
import styled from "styled-components";

export const Wrapper = styled.footer`
  border-top: 1px solid ${NEUTRAL_GRAY_200};
  color: ${NEUTRAL_GRAY_400};
  display: flex;
  font-size: 12px;
  font-weight: 400;
  padding: 9px 32px;

  & a {
    text-decoration: underline;
  }

  & > div {
    flex: 1;
  }
`;
