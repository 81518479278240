import * as Sentry from '@sentry/react';

const getRelease = () => {
  try {
    const version = require(process.env.NX_PUBLIC_PATH_APP + '/src/assets/scripts/.version').default;
    const productName = process.env.NX_PUBLIC_PRODUCT_NAME.toLowerCase().replaceAll(' ', '-');

    return productName ? `${productName}@${version}` : version;
  }
  catch (e) {
    return null;
  }
}

export const initSentry = (argsRelease) => {
  Sentry.init({
    dsn: process.env.NX_PUBLIC_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 0.01,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.1,
    environment: process.env.NODE_ENV,
    release: argsRelease ?? getRelease(),
  });
};

export const initSentryWithData = (sentryData) => {
  const {
    release: argsRelease,
    tracesSampleRate = 0.01,
    replaysSessionSampleRate = 0,
    replaysOnErrorSampleRate = 0.1,
    ...options
  } = sentryData || {};

  Sentry.init({
    dsn: process.env.NX_PUBLIC_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: process.env.NODE_ENV,
    tracesSampleRate,
    replaysOnErrorSampleRate,
    replaysSessionSampleRate,
    ...options,
    release: argsRelease ?? getRelease(),
  });
};

export const captureException = (error) => {
  Sentry.captureException(error);
}

