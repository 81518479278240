export const REPORT_STATUS__PROCESSING = 'processing';
export const REPORT_STATUS__SUCCESS = 'success';
export const REPORT_STATUS__ERROR = 'error';
export const REPORT_STATUS__EXPIRED = 'expired';

export const REPORT_STATUS = {
  [REPORT_STATUS__PROCESSING]: {
    label: 'Processando',
    filterLabel: 'Processando',
  },
  [REPORT_STATUS__SUCCESS]: {
    label: 'Gerado',
    filterLabel: 'Gerado',
  },
  [REPORT_STATUS__ERROR]: {
    label: 'Erro',
    filterLabel: 'Erro',
  },
  [REPORT_STATUS__EXPIRED]: {
    label: 'Expirado',
    filterLabel: 'Expirado',
  },
}
