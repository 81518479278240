import { BLUE_100, NEUTRAL_GRAY_100 } from '../../../defaults/colors';
import styled from 'styled-components';

export const StyledButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 48px;
  outline: none;
  text-align: center;
  transition: .2s background ease;
  width: 48px;

  ${({ selected }) =>
    selected
    ? `
      background: ${BLUE_100};

      svg {
        filter: invert(48%) sepia(67%) saturate(5683%) hue-rotate(197deg) brightness(96%) contrast(92%);
      }
    `
    : `
      &:hover {
        background: ${NEUTRAL_GRAY_100};
      }
    `
  }
`
