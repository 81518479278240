const IconInfosCheck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M21 18.8824C21 20.0519 20.1046 21 19 21H5C3.89543 21 3 20.0519 3 18.8824V5.11765C3 3.9481 3.89543 3 5 3H19C20.1046 3 21 3.9481 21 5.11765V8.55882"
      stroke="#332307"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 14L19 16L23 12"
      stroke="#332307"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 15H11.5"
      stroke="#332307"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 11H17"
      stroke="#332307"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 7H17"
      stroke="#332307"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 7H12"
      stroke="#332307"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconInfosCheck;
