const IconMapPin = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1019_25774)">
      <path d="M14 7.16669C14 11.8334 8 15.8334 8 15.8334C8 15.8334 2 11.8334 2 7.16669C2 5.57539 2.63214 4.04926 3.75736 2.92405C4.88258 1.79883 6.4087 1.16669 8 1.16669C9.5913 1.16669 11.1174 1.79883 12.2426 2.92405C13.3679 4.04926 14 5.57539 14 7.16669Z" stroke="#323232" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 9.16669C9.10457 9.16669 10 8.27126 10 7.16669C10 6.06212 9.10457 5.16669 8 5.16669C6.89543 5.16669 6 6.06212 6 7.16669C6 8.27126 6.89543 9.16669 8 9.16669Z" stroke="#323232" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1019_25774">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconMapPin;
