import {
  getChargebackStatus,
  CHARGEBACK_STATUS__ANALYZING,
  CHARGEBACK_STATUS__WAITING,
  CHARGEBACK_STATUS__REFUSED,
  CHARGEBACK_STATUS__APPROVED,
} from '@frontend/shared-defaults';
import { DANGER_300, GREEN_300, NEUTRAL_GRAY_400, WARNING_300 } from './colors';

export const getChargebackStatusWithColor = (t) => ({
  ...getChargebackStatus(t),
  [CHARGEBACK_STATUS__WAITING]: {
    ...getChargebackStatus(t)[CHARGEBACK_STATUS__WAITING],
    icon: 'chargeback-waiting',
    color: NEUTRAL_GRAY_400,
  },
  [CHARGEBACK_STATUS__ANALYZING]: {
    ...getChargebackStatus(t)[CHARGEBACK_STATUS__ANALYZING],
    icon: 'chargeback-analyzing',
    color: WARNING_300,
  },
  [CHARGEBACK_STATUS__APPROVED]: {
    ...getChargebackStatus(t)[CHARGEBACK_STATUS__APPROVED],
    icon: 'chargeback-approved',
    color: GREEN_300,
  },
  [CHARGEBACK_STATUS__REFUSED]: {
    ...getChargebackStatus(t)[CHARGEBACK_STATUS__REFUSED],
    icon: 'chargeback-refused',
    color: DANGER_300,
  },
});

export const getFilterChargeback = (t) => {
  const chargebackStatusWithColor = getChargebackStatusWithColor(t);

  return Object.keys(chargebackStatusWithColor)
    .map(key => ({
      label: chargebackStatusWithColor[key].label,
      value: key,
      icon: chargebackStatusWithColor[key].icon,
      iconColor: chargebackStatusWithColor[key].colors?.iconColor,
    }));
}