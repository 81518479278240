import PropTypes from 'prop-types';
import { Column, PageContentHeader } from "../../";
import NoData from "../no-data";
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { message } from 'antd';

const NotFound = ({ disabledRoutes, defaultRoute }) => {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (disabledRoutes[location.pathname]) {
      message.warn('A funcionalidade que você estava acessando foi desativada temporariamente, e por isso precisamos redirecionar você para a página inicial.', 5);
      navigate(defaultRoute);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContentHeader style={{ alignItems: 'center', height: '90%', flex: 1, justifyContent: 'center' }}>
      <Column>
        <NoData description={"Essa página não existe"} title={'Erro 404'}/>
      </Column>
    </PageContentHeader>
  );
}

NotFound.defaultProps = {
  disabledRoutes: {},
}

NotFound.propTypes = {
  defaultRoute: PropTypes.string,
  disabledRoutes: PropTypes.any,
}

export default NotFound;
