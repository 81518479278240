import PropTypes from 'prop-types';
import Avatar from 'libs/shared-ui/src/lib/avatar';
import { Wrapper } from "./styles";

const Organization = ({ label, logo, onClick, showAvatar }) => (
  <Wrapper onClick={onClick}>
    <div>
      {
        showAvatar &&
        <Avatar
          text={label}
          size={40}
          src={logo} />
      }
      <span>
        {label}
        <p>Organização</p>
      </span>
    </div>
  </Wrapper>
);

Organization.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  showAvatar: PropTypes.bool,
};

export default Organization;
