import PropTypes from 'prop-types';
import { Column, Icon } from "@frontend/shared-ui";
import { UPLOAD_STATUS, UPLOAD_STATUS_FAIL, UPLOAD_STATUS_LOADING, UPLOAD_STATUS_WAITING, UPLOAD_STATUS_WARNING } from "../../defaults/status";
import { useUpload } from "../../hook";
import ProgressBar from "../progress-bar";
import { FileDisplay, Link, Wrapper } from "./styles";
import Rules from './rules';
import ImagePreview from './image-preview';
import { useEffect } from 'react';
import { message } from 'antd';

const UploadImage = ({ disabled, label, failLabel, rules }) => {

  const { callSelectFile, errors, file, fileId, send, setErrors: setUploadErrors, setStatus: setUploadStatus, status, reset } = useUpload();

  useEffect(() => {
    if (!file) return;
    if (!rules?.size) return send();

    const imageURL = URL.createObjectURL(file);

    const image = new Image();
    image.src = imageURL;
    image.onload = function () {
      if (this.width !== rules.size.width || this.height !== rules.size.height) {
        message.warn(`A imagem é de tamanho diferente do solicitado (${rules.size.width}x${rules.size.height}px)`);
        reset();
      }
      else {
        send();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, reset, send, setUploadErrors, setUploadStatus]);

  if (status === UPLOAD_STATUS_WAITING) {
    return (
      <Wrapper disabled={disabled}>
        <ImagePreview size={rules.size} />
        {
          file
          ? (
            <FileDisplay>
              <span>{file.name}</span>
              <Icon type="trash" onClick={reset}/>
            </FileDisplay>
          )
          : (
            <Column style={{ flexDirection: 'column' }}>
              <Link onClick={callSelectFile}>{label}</Link>
              <Rules {...rules} />
            </Column>
          )
        }
      </Wrapper>
    );
  }

  if (fileId) {
    return (
      <Wrapper disabled={disabled}>
        <ImagePreview size={rules.size} />
        <FileDisplay>
          <span>{file.name}</span>
          <Icon type="trash" onClick={reset}/>
        </FileDisplay>
      </Wrapper>
    );
  }

  if (status === UPLOAD_STATUS_LOADING) {
    return (
      <Wrapper disabled={disabled}>
        <ImagePreview size={rules.size} />
        <ProgressBar color={UPLOAD_STATUS[status].color} />
      </Wrapper>
    );
  }

  if (status === UPLOAD_STATUS_WARNING) {
    return (
      <Wrapper disabled={disabled}>
        <ImagePreview size={rules.size} />
        <FileDisplay>
          {errors.join(', ')}
          <Icon type="trash" onClick={reset}/>
        </FileDisplay>
      </Wrapper>
    );
  }

  if (status === UPLOAD_STATUS_FAIL) {
    return (
      <Wrapper disabled={disabled}>
        <ImagePreview size={rules.size} />
        {failLabel}
      </Wrapper>
    );
  }
};

UploadImage.defaultProps = {
  label: 'Clique aqui para selecionar a imagem',
  failLabel: 'Não foi possível subir a imagem',
  successLabel: 'Imagem subida com sucesso',
  rules: {}
}

UploadImage.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  failLabel: PropTypes.string,
  successLabel: PropTypes.string,
  rules: PropTypes.object,
}

export default UploadImage;
