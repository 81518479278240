import PropTypes from 'prop-types';
import { Wrapper } from "./styles";

const Skeleton = ({ children, show, ...props }) =>
  <Wrapper show={show} {...props}>{children}</Wrapper>;

Skeleton.propTypes = {
  children: PropTypes.element,
  show: PropTypes.bool,
};

export default Skeleton;
