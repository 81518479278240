import { useCallback, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { createContext } from 'use-context-selector';

export const TransactionSearchContext = createContext({});

export const DEFAULT_SEARCH_VALUE = '';

const TransactionSearchProvider = ({ children }) => {

  const [lastSearch, setLastSearch] = useState(null);
  const [search, setSearch] = useState(DEFAULT_SEARCH_VALUE);
  const [searchTrigger, setSearchTrigger] = useState(null);

  const reset = useCallback(() => {
    setSearch(DEFAULT_SEARCH_VALUE);
  }, [setSearch]);

  const value = useMemo(() => ({
    search,
    searchTrigger,
    lastSearch,
    reset,
    setSearch,
    setSearchTrigger,
    setLastSearch,
  }), [lastSearch, reset, search, searchTrigger])

  return (
    <TransactionSearchContext.Provider value={value}>
      {children}
    </TransactionSearchContext.Provider>
  )
}

TransactionSearchProvider.propTypes = {
  children: PropTypes.element,
};

export default TransactionSearchProvider;
