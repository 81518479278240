import styled from 'styled-components';
import { NEUTRAL_GRAY_400 } from '../../defaults/colors';

export const Wrapper = styled.span`
  font-size: 12px;
  line-height: 18px;
  color: ${NEUTRAL_GRAY_400};
  opacity: 0;
  transition: .2s opacity ease;

  ${({ hasData }) => hasData && `
    opacity: 1;
  `}
`;