import MaskedInput from 'antd-mask-input';
import styled from 'styled-components';
import { NEUTRAL_GRAY_400 } from '../../defaults/colors';

export const StyledMaskedInput = styled(MaskedInput)`
  border-radius: 8px;
  font-size: 16px;
  line-height: 16px;

  & .ant-input-prefix {
    font-size: 14px;
    line-height: 14px;
    color: ${NEUTRAL_GRAY_400};
  }

  .ant-input {
    line-height: 24px;
  }

  &.ant-input-affix-wrapper > input.ant-input {
    padding-left: 3px;
  }

  &[aria-invalid="true"] {
    border: 1px solid red;
  }

  &[aria-invalid="true"]:focus {
    box-shadow: 0 0 0 2px rgb(255 0 0 / 20%);
  }

  .ant-input ::placeholder {
    color: ${NEUTRAL_GRAY_400};
    font-size: 16px;
  }
`
