import styled from 'styled-components';
import { NEUTRAL_GRAY_200, NEUTRAL_GRAY_300 } from '../../defaults/colors';

export const Wrapper = styled.img`
  background: ${NEUTRAL_GRAY_200};
  border-radius: 50%;

  ${({ size }) => `
    height: ${size}px;
    width: ${size}px;
  `}
`;

export const LetterWrapper = styled.div`
  align-items: center;
  background: ${NEUTRAL_GRAY_300};
  border-radius: 50%;
  cursor: unset;
  color: white;
  display: flex;
  justify-content: center;
  text-align: center;
  user-select: none;

  ${({ size }) => `
    font-size: ${size/2.4}px;
    height: ${size}px;
    width: ${size}px;
  `}
`;
