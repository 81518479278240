export const BLACK = '#111';
export const WHITE = '#FFF';

/* Gray scale */
export const NEUTRAL_GRAY_100 = '#F9F9F9';
export const NEUTRAL_GRAY_200 = '#EEE';
export const NEUTRAL_GRAY_300 = '#C6C6C6';
export const NEUTRAL_GRAY_400 = '#767676';
export const NEUTRAL_GRAY_500 = '#4F4F4F';
export const NEUTRAL_GRAY_600 = '#131516';
export const NEUTRAL_GRAY_700 = '#323232';

/* Blue scale */
export const BLUE_100 = '#ECF5FF';
export const BLUE_300 = '#00A5FF';
export const BLUE_400 = '#1172EB';
export const BLUE_500 = '#0652B0';

/* Green scale */
export const GREEN_300 = '#32D583';
export const GREEN_400 = '#0F8755';

/* Red scale */
export const RED_400 = '#EB0042';
export const RED_500 = '#8D0B2F';

/* Danger scale */
export const DANGER_100 = '#FDEBF0';
export const DANGER_200 = '#F799B3';
export const DANGER_300 = '#F04A55';
export const DANGER_400 = '#EB0042';
export const DANGER_600 = '#BC0035';
export const DANGER_700 = '#8D0028';

/* Yellow scale */
export const YELLOW_400 = '#C35516';

/* Warning scale */
export const WARNING_100 = '#FFF5E2';
export const WARNING_300 = '#FDB022';
export const WARNING_400 = '#C35516';
export const WARNING_600 = '#CA8D1B';
export const WARNING_700 = '#986A14';

/* Primary scale */
export const PRIMARY_100 = '#E7F1FD';
export const PRIMARY_300 = '#8FBDF6';
export const PRIMARY_400 = '#5FA0F2';
export const PRIMARY_500 = '#1172EB';
export const PRIMARY_600 = '#0E5BBC';
export const PRIMARY_700 = '#0A448D';

/* Success scale */
export const SUCCESS_100 = '#E9F8F1';
export const SUCCESS_200 = '#BDE9D4';
export const SUCCESS_300 = '#92DBB8';
export const SUCCESS_400 = '#6CCEA0';
export const SUCCESS_500 = '#24B671';
export const SUCCESS_600 = '#1D925A';
export const SUCCESS_700 = '#166D44';

/* -- Useful -- */
export const APPLICATION_BORDER_COLOR = NEUTRAL_GRAY_200;
