export const debounce = (fn, time = 500) => {
  let timeout;

  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    const prm = new Promise((resolve, reject) => {
      timeout = setTimeout(async () => {
        try {
          resolve(await fn(...args));
        }
        catch (e) {
          reject(e);
        }
      }, time);
    })

    return prm;
  }
}

export default debounce;
