export const FILTER_DATE_TODAY = 'today';
export const FILTER_DATE_7 = '7';
export const FILTER_DATE_30 = '30';
export const FILTER_DATE_PERIOD = 'select_period';

export const getDateFilters = (t) => ({
  [FILTER_DATE_TODAY]: { label: t('Today'), days: 1 },
  [FILTER_DATE_7]: { label: t('transactions.dateDays', { days: 7 }), days: 7 },
  [FILTER_DATE_30]: { label: t('transactions.dateDays', { days: 30 }), days: 30 },
  [FILTER_DATE_PERIOD]: { icon: 'calendar', label: t('filter.selectPeriod') },
});

export const getIterableDateFilters = (t) => {
  const dateFilters = getDateFilters(t);
  return Object.keys(dateFilters)
    .map(key => ({ ...dateFilters[key], value: key }));
}