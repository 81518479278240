export const TRANSACTION_STATUS__ABSENT_HOLDER = 'absent-holder';
export const TRANSACTION_STATUS__APPROVED = 'approved';
export const TRANSACTION_STATUS__DISAPPROVED = 'disapproved';
export const TRANSACTION_STATUS__CHARGEBACK = 'chargeback';
export const TRANSACTION_STATUS__CANCELED = 'canceled';
export const TRANSACTION_STATUS__INCONCLUSIVE = 'inconclusive';
export const TRANSACTION_STATUS__FAST_INCONCLUSIVE = 'fast-inconclusive';
export const TRANSACTION_STATUS__PROCESSING = 'processing';
export const TRANSACTION_STATUS__SHARED = 'shared';
export const TRANSACTION_STATUS__WAITING = 'waiting';
export const TRANSACTION_STATUS__SKIPED = 'skiped';
export const TRANSACTION_STATUS__UNKNOW_SHARE = 'unknown-share';
export const TRANSACTION_STATUS__EXPIRED = 'expired';
export const TRANSACTION_STATUS__RESULT_YES = 'yes';
export const TRANSACTION_STATUS__RESULT_INCONCLUSIVE = 'inconclusive';
