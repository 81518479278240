import PropTypes from 'prop-types';
import { StyledButton } from './styles';

const Button = ({ children, disabled, onClick, outline, primary, transparent, iconSize, svgStyle, ...props }) => (
  <StyledButton
    onClick={onClick}
    disabled={disabled}
    iconSize={iconSize}
    outline={outline}
    primary={primary}
    transparent={transparent}
    svgStyle={svgStyle}
    {...props}>
      {children}
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.element,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  iconSize: PropTypes.number,
  outline: PropTypes.bool,
  primary: PropTypes.bool,
  transparent: PropTypes.bool,
  svgStyle: PropTypes.string,
};

export default Button;
