import { FILTER_TYPE } from "../../../../../shared-defaults/src";

export const COMPANIES_KIND = {
  'ecommerce': 'E-commerce',
  'wallet': 'Wallet',
  'payment-link': 'Link de pagamento',
};

export const COMPANY_INTEGRATION = {
  "messaging-manual": 'Mensageria | Envio em lote | Manual',
  "messaging-automated": 'Mensageria |  Envio instantâneo | Automatizado',
  "messaging-api": 'Mensageria | Envio instantâneo | Via API',
  "messaging-inhouse": 'Mensageria | Envio próprio',
  "integrated-redirect": 'Integrado | Redirect',
  "integrated-embedded": 'Integrado | Embedado',
  "hybrid": 'Híbrido'
};

export const groupCompaniesByKind = (companiesList) => {
  if (!companiesList.length) return [];

  const companies = []

  companiesList.forEach(company => {
    if (!companies.find(current => current.type === FILTER_TYPE.HEADER && current.value === company.kind)) {
      companies.push({
        type: FILTER_TYPE.HEADER,
        label: <strong>{COMPANIES_KIND[company.kind]}</strong>,
        value: company.kind,
      });
    }

    companies.push({
      header: company.kind,
      label: company.name,
      value: company.id,
    });

  });

  return companies;
}