import { createGlobalStyle } from "styled-components";
import { BLUE_100, BLUE_400, BLUE_500, NEUTRAL_GRAY_200, NEUTRAL_GRAY_400 } from "../../defaults/colors";

export const RangePickerGlobalStyle = createGlobalStyle`
  .ant-picker.ant-picker-range {
    visibility: hidden;
    width: 0;
    margin: 0;
    padding: 0;
    border: 0;
    pointer-events: none;
  }

  .ant-picker-range-wrapper.ant-picker-date-range-wrapper {
    margin-top: -40px;
  }

  .ant-picker-dropdown-range .ant-picker-panel-container {
    border-radius: 8px;
  }

  .ant-picker-dropdown-range .ant-picker-header {
    align-items: center;
    border: none;
    height: 70px;
    margin-bottom: -20px;
    padding: 0 17px;
  }

  .ant-picker-dropdown-range .ant-picker-panels {
    width: 657px;
    padding: 0 10px;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell .ant-picker-cell-inner {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-picker-dropdown-range .ant-picker-panel:nth-child(2)::before {
    content: '';
    display: block;
    height: calc(100% - 88px);
    width: 1px;
    background-color: ${NEUTRAL_GRAY_200};
    position: absolute;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-header > button {
    color: ${NEUTRAL_GRAY_400};
  }

  .ant-picker-dropdown-range .ant-picker-panel {
    flex: 1;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-radius: 50%;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-content th, .ant-picker-content td {
    font-size: 14px;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
  .ant-picker-dropdown-range .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  .ant-picker-dropdown-range .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background-color: ${BLUE_100};
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell::before {
    height: 40px;
    width: 42px;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range {
    color: ${BLUE_500};
    font-weight: 500;
    font-size: 14px;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after {
    border-radius: 50% 0 0 50%;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-range-start-single.ant-picker-cell-range-hover-start::after,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-range-end + .ant-picker-cell.ant-picker-cell-disabled::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-disabled:first-child::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-range-hover-edge-start::after {
    border-radius: 50% 0 0 50%;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range):first-child::after,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-end + .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range:first-child::before, {
    border-radius: 50% 0 0 50%;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range):has(+ *:not(.ant-picker-cell-in-view))::after,
  .ant-picker-cell-disabled:has(+ .ant-picker-cell-range-start)::before {
    border-radius: 0 50% 50% 0;
    width: 40px;
    right: 0;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
    border-radius: 50%;
    right: 0;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range:last-child::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-disabled:last-child::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-disabled:has(+ .ant-picker-cell-range-start-single)::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range:has(+ *:not(.ant-picker-cell-in-view))::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-end:has(+ .ant-picker-cell-start)::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-range-end::before,


  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-end:first-child::before {
    border-radius: 0 50% 50% 0;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
    background: initial;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    top: 30px;
    left: 18px;
    background: ${BLUE_400};
    border: none;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: ${BLUE_100};
    color: ${BLUE_500};
    font-weight: 500;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view::after {
    border: none !important;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start::after,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):last-child::after,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:last-child::after,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-range-start:has(+ .ant-picker-cell-start)::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-disabled:first-child:has(+ .ant-picker-cell-range-start)::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-start::before {
    border-radius: 50%;
    width: 42px;
    height: 40px;
    left: 0;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-range-end + .ant-picker-cell.ant-picker-cell-disabled:last-child::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-selected:last-child:not(.ant-picker-cell-in-range + .ant-picker-cell-selected)::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-range-start:last-child::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-start:has(+ .ant-picker-cell-range-start-single)::before {
    border-radius: 50%;
    width: 40px;
    left: 0;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range:first-child::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-disabled:first-child,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range:first-child::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-end + .ant-picker-cell-start::before {
    border-radius: 50% 0 0 50%;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    height: 40px;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-end + .ant-picker-cell-start:last-child::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-end:first-child:has(+ .ant-picker-cell-start)::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-end:first-child::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-range-end:first-child::before,
  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-cell-range-end + .ant-picker-cell-end:has(+ .ant-picker-cell-start)::before {
    border-radius: 50%;
  }

  .ant-picker-dropdown-range .ant-picker-date-panel .ant-picker-content,
  .ant-picker-dropdown-range .ant-picker-decade-panel,
  .ant-picker-dropdown-range .ant-picker-year-panel,
  .ant-picker-dropdown-range .ant-picker-quarter-panel,
  .ant-picker-dropdown-range .ant-picker-month-panel,
  .ant-picker-dropdown-range .ant-picker-week-panel,
  .ant-picker-dropdown-range .ant-picker-date-panel,
  .ant-picker-dropdown-range .ant-picker-time-panel {
    padding: 0;
    width: 100%;
  }
`;