import { useContextSelector } from 'use-context-selector';
import { TransactionContext } from '../../context/transaction';

const useTransaction = () => {

  const transaction = useContextSelector(TransactionContext, context => context.transaction);
  const setTransaction = useContextSelector(TransactionContext, context => context.setTransaction);
  const loading = useContextSelector(TransactionContext, context => context.loading);
  const error = useContextSelector(TransactionContext, context => context.error);
  const refreshTransaction = useContextSelector(TransactionContext, context => context.refreshTransaction);

  return {
    error,
    loading,
    transaction,
    refreshTransaction,
    setTransaction,
  }
}

export default useTransaction;
