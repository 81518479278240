import { HttpError } from '../../lib/errors';
import Error5xx from '../../lib/screens/error-5xx';
import Error403 from '../../lib/screens/error-403';
import Error400 from '../../lib/screens/error-400';
import Error401Modal from '../../lib/components/error-401-modal';
import HttpStatusCodes from 'http-status-codes';
import { captureException } from '@frontend/feature-monitor';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';

/**
 * Returns the appropriate error component based on the HTTP status code.
 * @param {number} statusCode - The HTTP status code of the error.
 * @param {Error} error - The error object.
 * @returns {React.Component} - The corresponding React component for the error.
 */
function getErrorHttpComponent(statusCode, error) {
  if (statusCode >= 500 && statusCode <= 599) {
    captureException(error);
  }
  switch (statusCode) {
    case HttpStatusCodes.UNAUTHORIZED:
      return <Error401Modal open={true} />
    case HttpStatusCodes.FORBIDDEN:
      return <Error403 />
    case HttpStatusCodes.BAD_REQUEST:
      return <Error400 />
    default:
      return <Error5xx />;
  }
}

/**
 * Renders an error component based on the type and status of the error encountered.
 * This function is typically used as a fallback render in error boundaries.
 * @param {Object} props - Contains the error object.
 * @returns {React.Component} - The error component to render.
 */
function FallbackRender({ error }) {
  if (error?.status && error instanceof HttpError) {
    const statusCode = error.status;
    const errorComponent = getErrorHttpComponent(statusCode, error);
    return errorComponent;
  }

  // For non-HttpError or when no status is present, log the error and display a generic error page
  captureException(error)

  return <Error5xx />;
}

const ErrorBoundaryWrapper = ({ children, enableErrorBoundary}) => {
  if (enableErrorBoundary) {
    return (
      <ErrorBoundary FallbackComponent={FallbackRender}>
        {children}
      </ErrorBoundary>
    );
  }

  return children;
};

ErrorBoundaryWrapper.propTypes = {
  children: PropTypes.element,
  enableErrorBoundary: PropTypes.bool,
};

export default ErrorBoundaryWrapper;
