export const BLACK = '#111';

/* White scale */
export const WHITE = '#FFF';
export const WHITE_SMOKE = '#f4f4f4';

/* Gray scale */
export const NEUTRAL_GRAY_100 = '#F9F9F9';
export const NEUTRAL_GRAY_200 = '#EEE';
export const NEUTRAL_GRAY_300 = '#C6C6C6';
export const NEUTRAL_GRAY_400 = '#767676';
export const NEUTRAL_GRAY_500 = '#4F4F4F';
export const NEUTRAL_GRAY_600 = '#131516';
export const NEUTRAL_GRAY_900 = '#161616';

/* Blue scale */
export const BLUE_100 = '#E7F1FD';
export const BLUE_400 = '#1172EB';
