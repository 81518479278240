import HttpStatusCodes from 'http-status-codes';

export class HttpError extends Error {
  constructor(status, statusText, message, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HttpError);
    }

    this.name = 'HttpError';
    this.status = status;
    this.statusText = statusText;
    this.message = message || statusText;

    this.toString = function () {
      return `${this.status} ${this.statusText}: ${this.message}`;
    };

  }

  static internalServerError(message = 'Internal Server Error') {
    return new HttpError(500, 'Internal Server Error', message);
  }

  static badRequest(message = 'Bad Request') {
    return new HttpError(400, 'Bad Request', message);
  }

  static unauthorized(message = 'Unauthorized') {
    return new HttpError(401, 'Unauthorized', message);
  }

  static forbidden(message = 'Forbidden') {
    return new HttpError(403, 'Forbidden', message);
  }

  static handleStatusCode = async (response) => {
    if (!response.ok) {
      const { status } = response;
      const errorMessage = await response.text();
      throw new HttpError(status, HttpStatusCodes.getStatusText(status), errorMessage);
    }
    return response

  }

}
