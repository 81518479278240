const allowedChars = ['_', '.', ',', '-'];

const validateJustNumbers = (errorMessage = 'Digite apenas números') =>
  () => ({
    validator(_, value) {
      if (value) {

        let cleanValue = value;

        allowedChars.forEach(
          char => { cleanValue = cleanValue.split(char).join('') }
        )

        if (Number.isNaN(+cleanValue)) {
          const parsedValue = cleanValue.replace(',', '.');

          if (Number.isNaN(+parsedValue)) {
            return Promise.reject(new Error(errorMessage));
          }
        }
      }
      return Promise.resolve();
    }
  });

export default validateJustNumbers;
