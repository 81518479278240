import {
  TRANSACTION_STATUS__ABSENT_HOLDER,
  TRANSACTION_STATUS__APPROVED,
  TRANSACTION_STATUS__CANCELED,
  TRANSACTION_STATUS__DISAPPROVED,
  TRANSACTION_STATUS__INCONCLUSIVE,
  TRANSACTION_STATUS__WAITING,
  TRANSACTION_STATUS__SKIPED,
  TRANSACTION_STATUS__UNKNOW_SHARE,
  TRANSACTION_STATUS__EXPIRED,
  TRANSACTION_STATUS__FAST_INCONCLUSIVE,
  TRANSACTION_STATUS__PROCESSING,
  TRANSACTION_STATUS__SHARED,
  FILTER_TYPE
} from '@frontend/shared-defaults';
import {
  NEUTRAL_GRAY_700,
  DANGER_600,
  PRIMARY_100,
  NEUTRAL_GRAY_100,
  PRIMARY_300,
  SUCCESS_100,
  WARNING_100,
  SUCCESS_500,
  WARNING_300,
  SUCCESS_400,
  DANGER_100,
  DANGER_200,
  DANGER_400,
  WARNING_600,
  NEUTRAL_GRAY_300,
  NEUTRAL_GRAY_400,
  NEUTRAL_GRAY_500,
  PRIMARY_400,
  WHITE,
  NEUTRAL_GRAY_200,
  SUCCESS_200,
  SUCCESS_300,
  SUCCESS_600
} from '../../../../shared-ui/src/defaults/colors';

export const getTransactionStatus = (getTranslation) => ({
  processStatusHeader: {
    type: FILTER_TYPE.HEADER,
    filterLabel: getTranslation('transaction.status.processHeader.filterLabel'),
  },
  [TRANSACTION_STATUS__WAITING]: {
    label: getTranslation('transaction.status.waiting'),
    filterLabel: getTranslation('transaction.status.waiting'),
    colors: {
      label: PRIMARY_400,
      indicator: NEUTRAL_GRAY_200,
      labelBackground: NEUTRAL_GRAY_100,
    },
  },
  [TRANSACTION_STATUS__PROCESSING]: {
    label: getTranslation('transaction.status.processing'),
    filterLabel: getTranslation('transaction.status.processing'),
    colors: {
      label: NEUTRAL_GRAY_100,
      indicator: NEUTRAL_GRAY_300,
      labelBackground: NEUTRAL_GRAY_400,
    },
  },
  [TRANSACTION_STATUS__CANCELED]: {
    label: getTranslation('transaction.status.canceled.label'),
    filterLabel: getTranslation('transaction.status.canceled.filterLabel'),
    colors: {
      label: DANGER_200,
      indicator: DANGER_200,
      labelBackground: DANGER_100,
    },
  },
  [TRANSACTION_STATUS__EXPIRED]: {
    label: getTranslation('transaction.status.expired.label'),
    filterLabel: getTranslation('transaction.status.expired.filterLabel'),
    colors: {
      label: NEUTRAL_GRAY_100,
      indicator: NEUTRAL_GRAY_700,
      labelBackground: NEUTRAL_GRAY_500,
    },
  },
  responseStatusHeader: {
    type: FILTER_TYPE.HEADER,
    filterLabel: getTranslation('transaction.status.responseHeader.filterLabel'),
  },
  [TRANSACTION_STATUS__APPROVED]: {
    label: getTranslation('transaction.status.approved.label'),
    filterLabel: getTranslation('transaction.status.approved.filterLabel'),
    colors: {
      label: SUCCESS_500,
      indicator: SUCCESS_400,
      labelBackground: SUCCESS_100,
    },
  },
  [TRANSACTION_STATUS__INCONCLUSIVE]: {
    label: getTranslation('transaction.status.inconclusive.label'),
    filterLabel: getTranslation('transaction.status.inconclusive.filterLabel'),
    colors: {
      label: WHITE,
      indicator: WARNING_300,
      labelBackground: WARNING_300,
    },
  },
  [TRANSACTION_STATUS__FAST_INCONCLUSIVE]: {
    label: getTranslation('transaction.status.fastInconclusive.label'),
    filterLabel: getTranslation('transaction.status.fastInconclusive.filterLabel'),
    colors: {
      label: WARNING_300,
      indicator: WARNING_600,
      labelBackground: WARNING_100,
    },
  },
  [TRANSACTION_STATUS__DISAPPROVED]: {
    label: getTranslation('transaction.status.disapproved.label'),
    filterLabel: getTranslation('transaction.status.disapproved.filterLabel'),
    colors: {
      label: WHITE,
      indicator: DANGER_400,
      labelBackground: DANGER_400,
    },
  },
  userActionsStatusHeader: {
    type: FILTER_TYPE.HEADER,
    filterLabel: getTranslation('transaction.status.userActionsHeader.filterLabel'),
  },
  [TRANSACTION_STATUS__SHARED]: {
    label: getTranslation('transaction.status.shared.label'),
    filterLabel: getTranslation('transaction.status.shared.filterLabel'),
    colors: {
      label: SUCCESS_600,
      indicator: SUCCESS_300,
      labelBackground: SUCCESS_200,
    },
  },
  [TRANSACTION_STATUS__SKIPED]: {
    label: getTranslation('transaction.status.skiped'),
    filterLabel: getTranslation('transaction.status.skiped'),
    colors: {
      label: PRIMARY_400,
      indicator: PRIMARY_300,
      labelBackground: PRIMARY_100,
    },
  },
  [TRANSACTION_STATUS__UNKNOW_SHARE]: {
    label: getTranslation('transaction.status.unknownShare.label'),
    filterLabel: getTranslation('transaction.status.unknownShare.filterLabel'),
    colors: {
      label: DANGER_600,
      indicator: DANGER_100,
      labelBackground: DANGER_100,
    },
  },
  [TRANSACTION_STATUS__ABSENT_HOLDER]: {
    label: getTranslation('transaction.status.absentHolder.label'),
    filterLabel: getTranslation('transaction.status.absentHolder.filterLabel'),
    colors: {
      label: WHITE,
      indicator: WARNING_600,
      labelBackground: WARNING_600,
    },
  },
});

export const getFilterStatus = (getTranslation) => {
  const transactionStatus = getTransactionStatus(getTranslation);
  let header = null;
  
  return Object.keys(transactionStatus)
    .map(key => {
      const type = transactionStatus[key]?.type;

      const status = {
        label: transactionStatus[key]?.filterLabel,
        value: key,
        color: transactionStatus[key]?.colors?.indicator,
        type,
      };

      if (type === FILTER_TYPE.HEADER) {
        header = key;
      } else {
        status.header = header;
      }

      return status;
    });
}
