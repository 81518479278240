import { useCallback, useEffect } from 'react';
import { useContextSelector } from 'use-context-selector';
import { FiltersContext } from "../../context/filters/index"

const useFilter = (name, defaultValue) => {

  const addFilter = useContextSelector(FiltersContext, context => context.addFilter);
  const setFilterValue = useContextSelector(FiltersContext, context => context.setFilterValue);
  const filters = useContextSelector(FiltersContext, context => context.filters);

  const set = useCallback(
    value => setFilterValue(name, value),
    [name, setFilterValue]
  );

  useEffect(() => {
    addFilter(name, defaultValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    set,
    [name]: filters[name]
  }
}

export default useFilter;
