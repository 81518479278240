import { Input } from 'antd';
import styled from 'styled-components';
import { NEUTRAL_GRAY_400 } from '../../defaults/colors';

export const StyledInput = styled(Input)`
  border-radius: 8px !important;
  font-size: 16px;
  line-height: 16px;
  height: 48px;

  & .ant-input-prefix {
    font-size: 14px;
    line-height: 14px;
    color: ${NEUTRAL_GRAY_400};
  }

  .ant-input {
    line-height: 24px;
  }

  &.ant-input-affix-wrapper > input.ant-input {
    padding-left: 3px;
  }

  .ant-input ::placeholder {
    color: ${NEUTRAL_GRAY_400};
    font-size: 16px;
  }
`

export const StyledInputGroup = styled(StyledInput.Group)`
  display: inline-flex;
  width: auto;

  & input {
    display: inline-block;
    width: auto;
  }

  & > :last-child {
    border-radius: 0 8px 8px 0 !important;
  }

  & > :first-child {
    border-radius: 8px 0 0 8px !important;
  }
`;

export const StyledTextArea = styled(StyledInput.TextArea)`
  border-radius: 8px !important;
  font-size: 16px !important;
  line-height: 16px;
  padding: 10px 14px !important;

  ::placeholder {
    color: ${NEUTRAL_GRAY_400} !important;
    font-size: 16px;
  }
`
