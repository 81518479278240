import { useCallback, useEffect, useState } from "react";
import { ButtonHelp, StyledTooltip, BackgroundHelp } from "./styles"
import Icon from "../../lib/icon";
import PropTypes from 'prop-types';

const HelpButton = ({ content, onOpenHelp, closeHelp }) => {
  const [openHelp, setOpenHelp] = useState(false);

  const handleOpen = useCallback(() => {
    if (onOpenHelp) {
      onOpenHelp(!openHelp);
    }
    setOpenHelp(!openHelp);
  }, [onOpenHelp, openHelp]);

  useEffect(() => {
    if (openHelp && closeHelp) {
      handleOpen()
    }
  }, [closeHelp, handleOpen, openHelp]);

  return <>
    <BackgroundHelp visible={openHelp} />
    <ButtonHelp>
      <StyledTooltip title={content} placement="topRight" color={"white"} trigger='click' open={openHelp} >
        <p onClick={handleOpen}>
          <Icon type='chat-help' />
        </p>
      </StyledTooltip>
    </ButtonHelp>
  </>
}

HelpButton.propTypes = {
  content: PropTypes.object.isRequired,
  onOpenHelp: PropTypes.func,
  closeHelp: PropTypes.bool
};

export default HelpButton;
