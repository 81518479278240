export const VALIDATION__LIVENESS = 'liveness';
export const VALIDATION__AUTHENTICATION = 'authentication';
export const VALIDATION__CARD_VALIDATION = 'cardValidation';

export const VALIDATIONS = {
  [VALIDATION__LIVENESS]: {
    label: 'Comprovação de vida',
  },
  [VALIDATION__AUTHENTICATION]: {
    label: 'Autenticação de identidade',
  },
  [VALIDATION__CARD_VALIDATION]: {
    label: 'Validação do cartão junto ao banco emissor',
  },
}
