import styled from 'styled-components';
import { NEUTRAL_GRAY_500 } from '../../defaults/colors';

export const StyledFieldset = styled.fieldset`
  margin: 16px 0;

  &:first-child {
    margin: 0 0 16px 0;
  }

  p {
    color: ${NEUTRAL_GRAY_500};
    margin-bottom: 20px;
  }
`;
