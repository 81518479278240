import { BLUE_100, BLUE_400 } from "libs/shared-ui/src/defaults/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  background: ${BLUE_100};
  border-radius: 4px;
  flex: 1;
  height: 8px;
`

export const Bar = styled.div`
  background: ${BLUE_400};
  border-radius: 4px;
  height: 100%;
  width: 0%;
  transition: width .1s ease;

  ${({ color }) => color && `
    background: ${color};
  `}

  ${({ percentage }) => percentage && `
    width: ${percentage}%;
  `}
`
