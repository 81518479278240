import styled from 'styled-components';
import { APPLICATION_BORDER_COLOR, NEUTRAL_GRAY_200, NEUTRAL_GRAY_500, WHITE } from '../../defaults/colors';
import { APPLICATION_BORDER_WIDTH, SIDEBAR_WIDTH } from '../../defaults/dimensions';
import { Tooltip } from 'antd';

export const StyledTooltip = styled(Tooltip).attrs({
  overlayInnerStyle: {
    borderRadius: '8px',
    color: 'white',
    padding: '0px',
    maxWidth: '100%',
    marginLeft: '-30px',
  },
  overlayStyle: {
    minWidth: '250px',
    padding: '0px',
  }
})``;

export const CompanyKind = styled.div`
  align-items: center;
  color: ${NEUTRAL_GRAY_500};
  display: flex;
  justify-content: space-between;
  min-width: 240px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 12px 8px 12px 16px;
`;

export const Logout = styled(CompanyKind)`
  border-top: 2px solid ${NEUTRAL_GRAY_200};
`;

export const Wrapper = styled.nav`
  align-items: center;
  background: ${WHITE};
  border-right: ${APPLICATION_BORDER_WIDTH} solid ${APPLICATION_BORDER_COLOR};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  width: ${SIDEBAR_WIDTH};

  & > :first-child {
    margin-bottom: 10px;
  }
`;

export const CompanyList = styled.div`
  & > :first-child {
    border-radius: 8px 8px 0 0;
  }

  & > :last-child {
    border-radius: 0 0 8px 8px;
  }
`;