import ErrorTemplate from "../../components/error-template";
import { Link } from "@acesso-io/genoma-ui";
import { logout } from 'libs/shared-context/src/lib/defaults/auth-control';
import { useTranslation } from '@frontend/feature-i18n';

const Error403 = () => {
  const { getTranslation, getTranslationAsString } = useTranslation();

  return (
    <ErrorTemplate
      errorCode='403'
      title={getTranslationAsString('AccessDenied', {}, 'Acesso recusado')}
    >
      <span>
        {getTranslation(
          "NoPermissionToAccessPage",
          null,
          {
            span: <span />,
            Link: <Link />,
            LinkClick: <Link onClick={logout} />,
          },
          "Parece que você não tem a permissão necessária para acessar essa página,<br /> <span> retorne para a </span> <Link href='/'> tela inicial</Link> <span> ou tente </span> <LinkClick onClick={logout}> fazer login novamente</LinkClick>."
        )}
      </span>
    </ErrorTemplate>
  );
};

export default Error403;



