import { styled } from "styled-components";
import { NEUTRAL_GRAY_300, PRIMARY_100, PRIMARY_400, PRIMARY_500 } from "libs/shared-ui/src/defaults/colors";

export const Wrapper = styled.div`
  align-items: center;
  border: 1px dashed ${NEUTRAL_GRAY_300};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  gap: 12px;
  padding: 12px;

  ${({ disabled }) => disabled && `
    opacity: 0.6;
    cursor: auto;
    user-select: none;
    pointer-events: none;
  `}
`;

export const IconWrapper = styled.div`
  align-items: center;
  background-color: ${PRIMARY_100};
  border-radius: 4px;
  color: ${PRIMARY_400};
  display: flex;
  justify-content: center;
  height: 44px;
  padding: 10px;
  width: 44px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Link = styled.span`
  color: ${PRIMARY_500};
  text-decoration: underline;
`
