import PropTypes from 'prop-types';
import Icon from '../';
import { StyledButton } from './styles';

const ClickableIcon = ({ type, onClick, ...props }) => (
  <StyledButton onClick={onClick}>
    <Icon type={type} {...props} />
  </StyledButton>
);

ClickableIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
}

export default ClickableIcon;
