import { BLUE_400, GREEN_300, RED_400, WARNING_300 } from "libs/shared-ui/src/defaults/colors";

export const UPLOAD_STATUS_WAITING = 'waiting';
export const UPLOAD_STATUS_LOADING = 'loading';
export const UPLOAD_STATUS_SUCCESS = 'success';
export const UPLOAD_STATUS_WARNING = 'warning';
export const UPLOAD_STATUS_FAIL = 'fail';

export const UPLOAD_STATUS = {
  [UPLOAD_STATUS_WAITING]: {
    color: BLUE_400,
  },
  [UPLOAD_STATUS_LOADING]: {
    color: BLUE_400,
  },
  [UPLOAD_STATUS_SUCCESS]: {
    color: GREEN_300,
  },
  [UPLOAD_STATUS_WARNING]: {
    color: WARNING_300,
  },
  [UPLOAD_STATUS_FAIL]: {
    color: RED_400,
  },
}
