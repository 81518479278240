import PropTypes from 'prop-types';
import { Wrapper } from "./styles";
import ErrorImage from '../../../assets/images/ErrorImage.svg'

const ErrorTemplate = ({ errorCode, title, children }) => {
  return (
    <Wrapper>
      <img data-testid="error-image" src={ErrorImage} alt={errorCode}></img>
      <p data-testid="error-title" className="error-title">{title}</p>
      <div data-testid="error-message">
        {children}
      </div>
    </Wrapper>
  );
};

ErrorTemplate.propTypes = {
  errorCode: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.element
};

export default ErrorTemplate;
