import { NEUTRAL_GRAY_500 } from 'libs/shared-ui/src/defaults/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  color: ${NEUTRAL_GRAY_500};
  display: flex;
  justify-content: space-between;
  min-width: 240px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 12px 16px;
  cursor: pointer;

  & > div:first-child {
    align-items: center;
    display: flex;
    gap: 16px;
  }
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
`;
