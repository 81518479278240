import PropTypes from 'prop-types';
import { Wrapper } from './styles';

const LastUpdate = ({ date }) =>
  <Wrapper hasData={!!date}>
    {date ? `Atualizado em ${date}` : '-'}
  </Wrapper>

LastUpdate.propTypes = {
  date: PropTypes.any,
}

export default LastUpdate;