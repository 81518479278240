import { Checkbox } from 'antd';
import styled from 'styled-components';
import { BLUE_400, WHITE } from '../../defaults/colors';

export const StyledCheckbox = styled(Checkbox)`

  & .ant-checkbox-inner {
    border-radius: 4px;
    border: 1px solid ${BLUE_400};
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${WHITE};
  }

  & .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 1px solid ${BLUE_400};
    border-top: 0;
    border-left: 0;
    margin-top: -1px;
  }

  & .ant-checkbox:hover::after, .ant-checkbox-wrapper:hover .ant-checkbox::after {
    border-radius: 4px;
  }

  & .ant-checkbox-checked::after {
    border-radius: 4px;
  }

`;
