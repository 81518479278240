import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 60vh;
  justify-content: center;
  text-align: center;

  p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 8px;

    &.error-title {
      font-weight: 700;
      font-size: 20px;
    }
  }

  span {
    font-size: 16px;
    line-height: 24px;
  }
`
