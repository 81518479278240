import styled from "styled-components";

export const DomainWrapper = styled.div`

  & input {
    font-size: 16px;
  }

  & .ant-input {
    border-radius: 8px 0 0 8px;
    height: 48px;
  }

  & .ant-input:not(:focus):not(:hover):not(:last-child) {
    border-right: 0;
  }

  & .ant-input-group-addon {
    background: none;
    border-radius: 0 8px 8px 0;
  }

`;

export const InvisibleButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  opacity: 1;
  transition: .25s opacity ease;

  & svg {
    height: 16px;
  }

  &:hover {
    opacity: 0.75;
  }
`;