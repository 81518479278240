import { useContextSelector } from 'use-context-selector';
import { ProfilesContext } from "../../context/profiles"
import { PROFILE_TYPE_ORGANIZATION } from '../../context/defaults/profile-types';

const useProfiles = () => {

  const profiles = useContextSelector(ProfilesContext, context => context.profiles);
  const selectedProfile = useContextSelector(ProfilesContext, context => context.selectedProfile);
  const selectedProfileType = useContextSelector(ProfilesContext, context => context.selectedProfileType);
  const setSelectedProfile = useContextSelector(ProfilesContext, context => context.setSelectedProfile);
  const setSelectedProfileType = useContextSelector(ProfilesContext, context => context.setSelectedProfileType);

  const hasPermissionInCompany = (permission, companyId) => {
    if (!profiles?.roles) return false;

    return profiles.roles[companyId || selectedProfile?.id]?.includes(permission);
  }

  const hasPermissionInSomeCompany = permission => {
    if (!profiles?.roles) return false;

    return [...new Set(Object.values(profiles.roles).flat())].includes(permission);
  }

  const hasPermission = permission => (
    selectedProfileType === PROFILE_TYPE_ORGANIZATION
      ? hasPermissionInSomeCompany(permission)
      : hasPermissionInCompany(permission, selectedProfile?.id)
  );

  return {
    hasPermission,
    hasPermissionInCompany,
    hasPermissionInSomeCompany,
    profiles,
    selectedProfile,
    setSelectedProfile,
    selectedProfileType,
    setSelectedProfileType,
  }
}

export default useProfiles;
