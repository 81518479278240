const validateJustNumberAndText = (errorMessage = 'Digite apenas números, letras, hífens ou underline') =>
  () => ({
    validator(_, value) {
      const regex = /^[a-zA-Z0-9_-]*$/;
      return value && !regex.test(value)
        ? Promise.reject(new Error(errorMessage))
        : Promise.resolve();
    }
  });

export default validateJustNumberAndText;
