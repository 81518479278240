import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import {
  asyncWithLDProvider,
  withLDProvider,
} from 'launchdarkly-react-client-sdk';
import App from './app/public-app';
import LAUNCH_DARKLY_CONFIG from './launch-darkly-config';
import { initI18n } from '@frontend/feature-i18n';
import { initHotjar, initGTM } from '@frontend/feature-monitor';
import { resourcesI18n } from '@frontend/pay/shared-defaults';

initGTM({
  dataLayerName: 'dataLayerIdPay',
});
initI18n(resourcesI18n);
initHotjar();

const routesWithoutBlockingFeatureFlags = [];

const rootDiv = document.getElementById('root-unico-idpay-ecommerce');

export const optimizedRender = (root) => {
  const AppWithProvider = withLDProvider(LAUNCH_DARKLY_CONFIG)(App);

  root.render(
    <StrictMode>
      <BrowserRouter>
        <AppWithProvider />
      </BrowserRouter>
    </StrictMode>
  );
};

export const renderWaitingFeatureFlags = async (root) => {
  (async () => {
    const LDProvider = await asyncWithLDProvider(LAUNCH_DARKLY_CONFIG);

    const content = <BrowserRouter >
      <LDProvider>
        <App />
      </LDProvider>
    </BrowserRouter>;

    const component = process.env.NX_PUBLIC_ENV === 'DEV'
      ? <StrictMode>{content}</StrictMode>
      : content;

    root.render(component);
  })();
};

export const renderApp = (rootDivElement, _settings) => {
  let root;

  if (rootDivElement) {
    root = ReactDOM.createRoot(rootDivElement);
  } else {
    root = ReactDOM.createRoot(rootDiv);
  }

  if (routesWithoutBlockingFeatureFlags.includes(window.location.pathname)) {
    optimizedRender(root);
  } else {
    renderWaitingFeatureFlags(root);
  }
};

if (rootDiv) {
  renderApp();
}

window.renderUnicoIDPayEcommerceApp = renderApp;
