import styled from 'styled-components';
import { APPLICATION_BORDER_COLOR } from '../../defaults/colors';
import { APPLICATION_BORDER_WIDTH, SIDEBAR_WIDTH } from '../../defaults/dimensions';
import Column from '../column';
import Logo from '../logo';

export const CenteredLogo = styled(Logo)`
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 45px - ${SIDEBAR_WIDTH}/2);
`
  
export const LeftColumn = styled(Column)`
  gap: 10px;
`

export const RightColumn = styled(Column)`
  align-items: center;
  gap: 15px;

  & > :last-child {
    margin-left: 26px;
  }
`

export const Bar = styled.header`
  align-items: center;
  border-bottom: ${APPLICATION_BORDER_WIDTH} solid ${APPLICATION_BORDER_COLOR};
  display: flex;
  min-height: 64px;
  justify-content: space-between;
  padding: 12px 16px;
`

export const Wrapper = styled.div`
  position: relative;
`