import PropTypes from 'prop-types';
import { TRANSACTION_STATUS__APPROVED, TRANSACTION_STATUS__CHARGEBACK, TRANSACTION_STATUS__DISAPPROVED, TRANSACTION_STATUS__INCONCLUSIVE } from 'libs/shared-defaults/src/index';
import { Icon } from '../../';
import { Wrapper } from "./styles";

const statusIconsHandler = {
  [TRANSACTION_STATUS__APPROVED]: 'thick-check',
  [TRANSACTION_STATUS__CHARGEBACK]: 'warning',
  [TRANSACTION_STATUS__INCONCLUSIVE]: 'warning',
  [TRANSACTION_STATUS__DISAPPROVED]: 'warning',
}

const StatusIndicator = ({ status }) => (
  <Wrapper color={status?.colors.labelBackground}>
    {
      !!status &&
      <Icon type={statusIconsHandler[status?.label]} />
    }
  </Wrapper>
);

StatusIndicator.propTypes = {
  status: PropTypes.object,
}

export default StatusIndicator;
