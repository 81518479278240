import PropTypes from 'prop-types';
import { Wrapper } from './styles';

const TransactionStatus = ({ status }) => (
  <Wrapper
    labelBackgroundColor={status?.colors.labelBackground}
    labelColor={status?.colors.label}
  >
    {status.label}
  </Wrapper>
);

TransactionStatus.propTypes = {
  status: PropTypes.object.isRequired,
};

export default TransactionStatus;
