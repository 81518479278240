import { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { createContext } from 'use-context-selector';
import { getCustomRedirectIdentityLoginUrl } from "libs/shared-defaults/src/lib/identity";
import { getAccessTokenFromStorage, setAccessTokenInStorage } from "../../defaults/auth-control";
import { getHashParams } from "@frontend/shared-utility";

export const AuthContext = createContext({});

const AuthProvider = ({ children, eventTrack }) => {

  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const tokenFromParams = getHashParams(window.location.href).access_token;
    const tokenFromStorage = getAccessTokenFromStorage();
    const token = tokenFromParams || tokenFromStorage;

    if (!token) {
      window.location.href = getCustomRedirectIdentityLoginUrl(window.location.href);
      return;
    }

    if (tokenFromParams && eventTrack) {
      eventTrack();
    }

    setAccessToken(token);
    setAccessTokenInStorage(token);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(() => ({
    accessToken,
    setAccessToken,
  }), [accessToken]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.element,
  eventTrack: PropTypes.func
};

export default AuthProvider;
