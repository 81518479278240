import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { createContext } from 'use-context-selector';
import { useParams } from "react-router-dom";
import { getTransactionDetails } from "../../integrations/transactions";

export const TransactionContext = createContext({});

const TransactionProvider = ({ children }) => {
  const { transactionId } = useParams();

  const [transaction, setTransaction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const refreshTransaction = useCallback(async (transactionId) => {
    if (!loading) {
      setLoading(true);

      try {
        const transaction = await getTransactionDetails(transactionId);
        setTransaction(transaction);
      }
      catch (e) {
        setError(e);
      }
      finally {
        setLoading(false);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (!transactionId) return;
    
    refreshTransaction(transactionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(() => ({
    transaction,
    setTransaction,
    error,
    refreshTransaction,
    loading
  }), [error, loading, refreshTransaction, transaction]);

  return (
    <TransactionContext.Provider value={value}>
      {children}
    </TransactionContext.Provider>
  )
}

TransactionProvider.propTypes = {
  children: PropTypes.element,
};

export default TransactionProvider;
