import PropTypes from 'prop-types';
import { LetterWrapper, Wrapper } from './styles';

const Avatar = ({ text, size, src }) =>
  src
  ? <Wrapper alt="foto" size={size} src={src} />
  : <LetterWrapper size={size}>{text?.charAt(0)}</LetterWrapper>

Avatar.defaultProps = {
  size: 46,
}

Avatar.propTypes = {
  text: PropTypes.string,
  size: PropTypes.number,
  src: PropTypes.string,
}

export default Avatar;
