import { useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { createContext } from 'use-context-selector';

export const CompanySettingsContext = createContext({});

const CompanySettingsProvider = ({ children }) => {

  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState(null);
  const [settingsTrigger, setSettingsTrigger] = useState(null);

  const value = useMemo(() => ({
    loading,
    setLoading,
    settings,
    setSettings,
    settingsTrigger,
    setSettingsTrigger,
  }), [loading, settings, settingsTrigger]);

  return (
    <CompanySettingsContext.Provider value={value}>
      {children}
    </CompanySettingsContext.Provider>
  )
}

CompanySettingsProvider.propTypes = {
  children: PropTypes.element
};

export default CompanySettingsProvider;
