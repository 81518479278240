import { VALIDATIONS } from "@frontend/shared-defaults";
import { Icon } from "../../";
import { useTransaction } from "@frontend/shared-context";
import { Wrapper } from "./styles";

const Validations = () => {

  const { transaction } = useTransaction();
  const { validations } = transaction;

  if (!validations) return null;

  const passedValidations =
    Object.keys(validations)
      .filter(validation => validations[validation])

  if (!passedValidations?.length) return null;

  return (
    <Wrapper>
      <h2>Validações</h2>
      {
        passedValidations
          .map(validation =>
            <p key={validation}>
              <Icon type="check" />
              <span>{VALIDATIONS[validation].label}</span>
            </p>
          )
      }
    </Wrapper>
  );
};

export default Validations;
