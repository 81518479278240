import PropTypes from 'prop-types';
import Column from "../../../column";
import { Wrapper } from "./styles";
import { useTranslation } from '@frontend/feature-i18n';

const Footer = ({ footerSettings }) => {
  const { getTranslation } = useTranslation();

  return <Wrapper>
    <Column>
      <a href="https://unico.io/wp-content/uploads/2023/08/Aviso-de-privacidade_set_2023.pdf" target="_blank" rel="noopener noreferrer">{getTranslation('footer.privacyPolicy')}</a>
    </Column>
    <Column style={{ justifyContent: 'center' }}>© {new Date().getFullYear()} Unico. {getTranslation('footer.allRightsReserved')}.</Column>
    <Column style={{ justifyContent: 'end' }}>{footerSettings.rightContent}</Column>
  </Wrapper>
};

Footer.defaultProps = {
  footerSettings: {}
}

Footer.propTypes = {
  footerSettings: PropTypes.shape({
    rightContent: PropTypes.element,
  })
}

export default Footer;
