/* eslint-disable react/jsx-no-useless-fragment */
import { StyledTooltip } from "./styles";

const Tooltip = ({ children, ...props }) =>
  <StyledTooltip {...props}>
    <>
      {children}
    </>
  </StyledTooltip>;

export default Tooltip;
