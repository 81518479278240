const IconKaz = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#338AF3" />
      <path d="M18.783 12.1301H5.21777C5.21777 13.0666 6.03345 13.8258 6.96987 13.8258H6.91343C6.91343 14.7623 7.67257 15.5214 8.60909 15.5214C8.60909 16.458 9.36823 17.2171 10.3047 17.2171H13.6961C14.6326 17.2171 15.3917 16.458 15.3917 15.5214C16.3282 15.5214 17.0874 14.7623 17.0874 13.8258H17.0309C17.9673 13.8258 18.783 13.0666 18.783 12.1301Z" fill="#FFDA44" />
      <path d="M16.696 9.91351C16.696 12.5068 14.5937 14.6092 12.0003 14.6092C9.40698 14.6092 7.30469 12.5068 7.30469 9.91351" fill="#338AF3" />
      <path d="M15.5864 9.91238L14.1208 10.6018L14.9012 12.0212L13.3098 11.7167L13.1082 13.3243L11.9997 12.1419L10.8912 13.3243L10.6896 11.7167L9.09821 12.0211L9.87859 10.6017L8.41309 9.91238L9.87863 9.22304L9.09821 7.80371L10.6896 8.10812L10.8913 6.50049L11.9997 7.68291L13.1082 6.50049L13.3098 8.10812L14.9013 7.80371L14.1209 9.22308L15.5864 9.91238Z" fill="#FFDA44" />
    </g>
    <defs>
      <clipPath id="clip0_1046_3648">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconKaz;
