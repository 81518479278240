import Icon from '../../icon';
import { Wrapper } from './styles';

const Loader = () => (
  <Wrapper>
    <Icon type="loading" />
  </Wrapper>
);

export default Loader;
