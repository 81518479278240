import PropTypes from 'prop-types';
import { Wrapper } from "./styles";

const PageContentHeader = ({ children, style }) => (
  <Wrapper style={style}>{children}</Wrapper>
);

PageContentHeader.propTypes = {
  children: PropTypes.element,
  style: PropTypes.any
};

export default PageContentHeader;
