import { NEUTRAL_GRAY_200 } from "../../../defaults/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  align-items: center;
  background: ${NEUTRAL_GRAY_200};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 36px;
  width: 36px;
`
