import { Wrapper } from './styles';
import Dropdown from '../../../external/dropdown';
import Menu from '../../../external/menu';
import Icon from "../../icon";
import MenuItem from 'libs/shared-ui/src/external/menu/menu-item';
import { logout, logoutPkce } from 'libs/shared-context/src/lib/defaults/auth-control';
import PropTypes from 'prop-types';

const UserMenu = ({ usePkce }) => {
  const handleLogout = usePkce
    ? logoutPkce
    : logout;

  const menu =
    <Menu
      items={[
        {
          key: 'disconnect',
          label:
            <MenuItem
              label="Desconectar"
              onClick={handleLogout} />,
        },
      ]}
    />;

  return (
    <Dropdown
      showBackdrop
      overlay={menu}>
      <Wrapper>
        <Icon type="user" />
      </Wrapper>
    </Dropdown>
  )
}

UserMenu.propTypes = {
  usePkce: PropTypes.bool,
};

export default UserMenu;
