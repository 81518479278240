import { Select } from "antd";
import styled from "styled-components";
import { NEUTRAL_GRAY_400 } from "../../defaults/colors";

export const StyledSelect = styled(Select)`
  & .ant-select-item {
    font-size: 16px !important;
  }

  &.ant-select.ant-select-in-form-item .ant-select-selector {
    border-radius: 8px;
    height: 44px;
    padding: 0 14px;
  }

  &.ant-select.ant-select-in-form-item .ant-select-selector .ant-select-selection-placeholder {
    color: ${NEUTRAL_GRAY_400};
    font-size: 16px;
    height: 44px;
    line-height: 44px;
  }

  & .ant-select-selector .ant-select-selection-item {
    line-height: 44px;
  }

  & .ant-select-arrow {
    right: 20px;
  }

  & .ant-select-arrow .anticon > svg {
    color: ${NEUTRAL_GRAY_400};
    transform: scaleX(1.3);
  }
`;
