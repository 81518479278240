import TagManager from 'react-gtm-module';

const getTagManagerArgs = () => ({
  gtmId: process.env.NX_PUBLIC_GTM_ID
});

export const initGTM = (options = {}) => {
  const tagManagerArgs = {
    ...getTagManagerArgs(),
    ...options,
  };

  if (tagManagerArgs.gtmId && typeof tagManagerArgs.gtmId === 'string' && tagManagerArgs.gtmId.trim() !== '') {
    TagManager.initialize(tagManagerArgs);
  }
};

export const setDataLayer = (data, dataLayerName = 'dataLayer') => {
  const tagManagerArgs = getTagManagerArgs();

  if (tagManagerArgs.gtmId && typeof tagManagerArgs.gtmId === 'string' && tagManagerArgs.gtmId.trim() !== '') {
    TagManager.dataLayer({ dataLayer: data, dataLayerName });
  }
};