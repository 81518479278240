export const timeoutPromise = (promise, timeOutMilliseconds = 0, resultIfTimeout = null, eventTrackIfTimeout = () => {/**/ }) => {
  if (timeOutMilliseconds === 0) {
    return promise;
  }

  const promiseLimit = new Promise((resolve) => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      eventTrackIfTimeout(timeOutMilliseconds);
      resolve(resultIfTimeout);
    }, timeOutMilliseconds);
  });

  return Promise.race([promise, promiseLimit]);
}