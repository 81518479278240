export const getTranslationPasskey = () => ({
    pt: {
        "login": {
            "mobileTitle": "Login instantâneo",
            "desktopTitle": "Usar login instantâneo",
            "desktopSubtitle": "Leia o QR code para fazer login",
            "pendingMessage": "Fazendo login...",
            "pendingButton": "Cancelar",
            "doneMessage": ""
        },
        "login-partial": {
            "mobileTitle": "Login instantâneo",
            "desktopTitle": "Leia este QR code com o celular para fazer login",
            "desktopSubtitle": "",
            "pendingMessage": "Fazendo login...",
            "pendingButton": "Cancelar",
            "doneMessage": ""
        },
        "register": {
            "mobileTitle": "Cadastrar-se sem senha",
            "desktopTitle": "Cadastre-se no celular",
            "desktopSubtitle": "Pule a senha e leia este QR code com o celular",
            "pendingMessage": "Termine o cadastro no celular",
            "pendingButton": "Cancelar",
            "doneMessage": ""
        },
        "register-partial": {
            "mobileTitle": "Cadastrar-se sem senha",
            "desktopTitle": "Leia este QR code com o celular para fazer login",
            "desktopSubtitle": "",
            "pendingMessage": "Carregando...",
            "pendingButton": "Cancelar",
            "doneMessage": "Pular Senha ativado.<br>Terminando o cadastro..."
        },
        "linkButton": {
            "button": "Ativar Pular Senha"
        },
        "errors": {
            "userAlreadyExists": "Conta já existente",
            "userNotFound": "Conta não encontrada",
            "requiresBiometricInput": "Entrada biométrica necessária",
            "accountNotFound": "Conta não encontrada",
            "wrongCodeLimitReached": "Limite de tentativas de verificação de código atingido",
            "general": "Ocorreu um erro. Tente novamente mais tarde.",
            "sendCodeLimitReached": "Limite de tentativas de verificação de código atingido"
        },
        "sb": {
            "message": "Pular senha"
        },
        "ownid-auth-button": {
            "message": "Continuar"
        },
        "common": {
            "or": "ou",
            "buttonAriaLabel": "Autenticação Biométrica",
            "ownidAuthEnabled": "Autenticação biométrica ativada",
            "ownidAuthDisabled": "Autenticação biométrica desativada",
            "processing": "Processando",
            "close": "Fechar"
        },
        "magicLink": {
            "link": "Não tem o celular?",
            "title": "Faça login com o link mágico",
            "pendingMessage": "Estamos enviando um e-mail",
            "pendingButton": "Cancelar",
            "message": "Vamos enviar um link para fazer login automaticamente",
            "emailPlaceholder": "E-mail",
            "button": "Enviar link mágico",
            "back": "Voltar",
            "error": "Conta não existente",
            "emailNotValid": "Insira um e-mail válido"
        },
        "magicLinkDone": {
            "title": "Verifique o e-mail",
            "message": "Enviamos um link para {email} que fará o login automaticamente",
            "mailButton": {
                "gmail": "Abrir no Gmail"
            }
        },
        "otp-form": {
            "a11y": {
                "label": "Número OTP {index} de {length}"
            }
        },
        "tooltip": {
            "desktop": "Faça login com seu método\n de desbloqueio de dispositivo",
            "macOS": "Faça login com Touch ID",
            "ios": "Faça login com Face ID",
            "android": "Faça login com\nimpressão digital",
            "noBiometrics": "Faça login com\ncódigo de uso único",
            "recover": {
                "desktop": "Recupere instantaneamente com seu\nmétodo de desbloqueio do dispositivo",
                "macOS": "Recuperar instantaneamente com\nTouch ID",
                "ios": "Recuperar instantaneamente com\nFace ID",
                "android": "Recuperar instantaneamente com\nImpressão Digital",
                "noBiometrics": "Recuperar instantaneamente\ncom um código único"
            },
            "notValidLoginId": "Insira um e-mail válido",
            "phoneNumber": {
                "notValidLoginId": "Insira um número de celular válido"
            },
            "userName": {
                "notValidLoginId": "Insira um Nome de Usuário válido"
            },
            "qr": {
                "login": "Faça login com seu celular",
                "register": "Cadastre-se com seu celular"
            }
        },
        "expandableTooltip": {
            "general": {
                "close": "Fechar",
                "more-info": "Mais informações"
            },
            "qr": {
                "login": {
                    "title": "Faça login mais rapidamente sem senha",
                    "details": {
                        "title": "Faça login mais rapidamente sem senha",
                        "description": "Faça login com segurança usando o método de desbloqueio do dispositivo (quando disponível) ou escaneie um QR code. Os dados biométricos nunca saem do seu dispositivo."
                    }
                },
                "register": {
                    "title": "Cadastre-se mais rapidamente sem senha",
                    "details": {
                        "title": "Cadastre-se mais rapidamente sem senha",
                        "description": "Recupere com segurança usando o método de desbloqueio do dispositivo (quando disponível) ou escaneie um QR code. Os dados biométricos nunca saem do seu dispositivo."
                    }
                },
                "recover": {
                    "title": "Faça login sem senha",
                    "details": {
                        "title": "Recupere mais rapidamente sem senha",
                        "description": "Recupere com segurança usando o método de desbloqueio do dispositivo (quando disponível) ou escaneie um QR code. Os dados biométricos nunca saem do seu dispositivo."
                    }
                }
            },
            "login": {
                "title": "Faça login com Touch ID",
                "details": {
                    "title": "Simplifique o login",
                    "description": "Use Touch ID, Face ID ou PIN para fazer login em qualquer dispositivo. É mais rápido e seguro. Os dados biométricos nunca saem do seu dispositivo."
                },
                "windows": {
                    "title": "Faça login com o método\nde desbloqueio do dispositivo",
                    "details": {
                        "description": "Use o método de desbloqueio do dispositivo (impressão digital, PIN etc.) para fazer login em qualquer dispositivo. É mais rápido e seguro. Os dados biométricos nunca saem do seu dispositivo."
                    }
                },
                "md": {
                    "title": "Faça login com\nimpressão digital",
                    "details": {
                        "description": "Use a impressão digital ou o PIN para fazer login em qualquer dispositivo. É mais rápido e seguro. Os dados biométricos nunca saem do seu dispositivo."
                    }
                },
                "ios": {
                    "title": "Faça login com Face ID",
                    "details": {
                        "description": "Use Face ID, Touch ID ou PIN para fazer login em qualquer dispositivo. É mais rápido e mais seguro. Os dados biométricos nunca saem do seu dispositivo."
                    }
                },
                "no-biometrics": {
                    "title": "Faça login com\num código de uso único",
                    "details": {
                        "description": "Use um código de uso único para fazer login em qualquer dispositivo. É mais rápido e mais seguro."
                    }
                }
            },
            "register": {
                "title": "Cadastre-se com Touch ID",
                "details": {
                    "title": "Simplifique o cadastro",
                    "description": "Use Touch ID, Face ID ou PIN para entrar nesta conta. É mais rápido e seguro. Os dados biométricos nunca saem do seu dispositivo."
                },
                "windows": {
                    "title": "Cadastre-se com o método de\ndesbloqueio do dispositivo",
                    "details": {
                        "description": "Use o método de desbloqueio do dispositivo (impressão digital, PIN etc.) para entrar nesta conta. É mais rápido e seguro. Os dados biométricos nunca saem do seu dispositivo."
                    }
                },
                "md": {
                    "title": "Cadastre-se com impressão digital",
                    "details": {
                        "description": "Use a impressão digital ou PIN para entrar nesta conta. É mais rápido e seguro. Os dados biométricos nunca saem do seu dispositivo."
                    }
                },
                "ios": {
                    "title": "Cadastre-se com Face ID",
                    "details": {
                        "description": "Use Face ID, Touch ID ou PIN para entrar nesta conta. É mais rápido e seguro. Os dados biométricos nunca saem do seu dispositivo."
                    }
                },
                "no-biometrics": {
                    "title": "Cadastre-se com um\ncódigo de uso único",
                    "details": {
                        "description": "Use um código único para entrar nesta conta. É mais rápido e seguro."
                    }
                }
            },
            "recover": {
                "title": "Faça login com Touch ID",
                "details": {
                    "title": "Recuperar a conta",
                    "description": "Use Touch ID, Face ID ou PIN para recuperar a conta em qualquer dispositivo. É mais rápido e seguro. Os dados biométricos nunca saem do seu dispositivo."
                },
                "windows": {
                    "title": "Faça login sem senha\ncom o desbloqueio do dispositivo",
                    "details": {
                        "description": "Use o método de desbloqueio do dispositivo (impressão digital, PIN etc.) para recuperar a conta em qualquer dispositivo. É mais rápido e seguro. Os dados biométricos nunca saem do seu dispositivo."
                    }
                },
                "md": {
                    "title": "Faça login com impressão digital\n da conta",
                    "details": {
                        "description": "Use a impressão digital ou o PIN para fazer login na conta em qualquer dispositivo. É mais rápido e seguro. Os dados biométricos nunca saem do seu dispositivo."
                    }
                },
                "ios": {
                    "title": "Faça login com Face ID",
                    "details": {
                        "description": "Use Face ID, Touch ID ou PIN para fazer login na conta em qualquer dispositivo. É mais rápido e seguro. Os dados biométricos nunca saem do seu dispositivo."
                    }
                },
                "no-biometrics": {
                    "title": "Recupere a conta com um\ncódigo de uso único",
                    "details": {
                        "description": "Use um código de uso único para recuperar a conta em qualquer dispositivo. É mais rápido e mais seguro."
                    }
                }
            }
        },
        "modal": {
            "general": {
                "close": "Cancelar",
                "restart": "Não é você?"
            },
            "new-device": {
                "header": "Fazer Login",
                "title": "Você está usando um computador diferente",
                "description": "Continuar para registrar este dispositivo",
                "cta": "Continuar"
            },
            "login-id-verification": {
                "header": "Usar Código Único",
                "loading": "Verificando",
                "success": "Verificado com sucesso",
                "title": "Insira o código",
                "description": "Um e-mail com um código de {codeLength} dígitos acaba de ser enviado para {loginId}",
                "resend": "Reenviar",
                "resendDescription": "Não recebeu o e-mail?",
                "a11y": {
                    "header": "Faça login com o código único enviado para o e-mail",
                    "sms": {
                        "header": "Verifique o celular"
                    }
                },
                "sms": {
                    "header": "Verifique o celular",
                    "description": "Um SMS com um código de {codeLength} dígitos acaba de ser enviado para {loginId}",
                    "resendDescription": "Não recebeu o SMS?"
                }
            },
            "login-id-collect": {
                "header": "Faça login ou cadastre-se",
                "title": "Insira o e-mail para continuar",
                "placeholder": "E-mail",
                "cta": "Continuar",
                "phoneNumber": {
                    "title": "Insira o número de celular para continuar",
                    "placeholder": "Número de celular"
                },
                "userName": {
                    "title": "Insira o ID para continuar",
                    "placeholder": "Número de identificação"
                }
            },
            "recognized-user": {
                "header": "Fazer login em {siteName}",
                "title": "Use Touch ID para fazer login como\n{loginId}",
                "cta": "Continuar",
                "windows": {
                    "header": "Olá, {loginId}",
                    "title": "Use impressão digital, reconhecimento facial ou PIN para um login mais rápido e seguro"
                }
            },
            "unknown-user": {
                "header": "Fazer login em {siteName}",
                "title": "Use o Touch ID para um login mais rápido e seguro em {siteName}",
                "cta": "Continuar",
                "windows": {
                    "header": "Fazer login em {siteName}",
                    "title": "Use impressão digital, reconhecimento facial ou PIN para um login mais rápido e seguro"
                }
            }
        },
        "prompt": {
            "returning-user": {
                "ios": {
                    "a11y": {
                        "title": "Fazer login como {loginId}",
                        "cta": "Continuar",
                        "close": "Fechar"
                    },
                    "title": "Fazer Login",
                    "description": "Face ID",
                    "cta": "Usar {loginId}"
                },
                "md": {
                    "a11y": {
                        "close": "Cancelar"
                    },
                    "title": "Fazer login como {loginId}",
                    "description": "Use a impressão digital para fazer login",
                    "cta": "Continuar",
                    "close": "Fechar"
                },
                "no-biometrics": {
                    "title": "Fazer Login",
                    "description": "",
                    "cta": "Usar {loginId}"
                },
                "macOS": {
                    "title": "Fazer login em {siteOrigin} com Touch ID",
                    "description": "Usar {loginId}",
                    "cta": "Continuar",
                    "close": "Cancelar"
                },
                "iPad": {
                    "title": "Fazer login em {siteOrigin} com Face ID",
                    "description": "Usar {loginId}",
                    "cta": "Continuar",
                    "close": "Cancelar"
                },
                "windows": {
                    "title": "Fazer login em {siteOrigin} como {loginId} com impressão digital, facial ou PIN",
                    "description": "",
                    "cta": "OK",
                    "close": "Cancelar"
                }
            },
            "always-connected": {
                "ios": {
                    "a11y": {
                        "title": "Fazer login com Face ID",
                        "cta": "Continuar",
                        "close": "Fechar"
                    },
                    "title": "Fazer Login",
                    "description": "Face ID",
                    "cta": "Fazer login com Face ID",
                    "inputPlaceholder": "Insira o e-mail"
                },
                "md": {
                    "a11y": {
                        "close": "Cancelar"
                    },
                    "title": "Fazer login com Impressão Digital",
                    "description": "Use a impressão digital para fazer login",
                    "cta": "Continuar",
                    "close": "Fechar",
                    "inputPlaceholder": "Insira o e-mail"
                },
                "no-biometrics": {
                    "title": "Fazer Login",
                    "description": "",
                    "cta": "Usar Código Único"
                },
                "macOS": {
                    "title": "Fazer login em {siteOrigin} com Touch ID",
                    "description": "",
                    "cta": "Continuar",
                    "close": "Cancelar",
                    "inputPlaceholder": "Insira o e-mail",
                    "phoneNumber": {
                        "inputPlaceholder": "Insira o número de celular"
                    },
                    "userName": {
                        "inputPlaceholder": "Insira o ID"
                    }
                },
                "iPad": {
                    "title": "Fazer login em {siteOrigin} com Face ID",
                    "description": "",
                    "cta": "Continuar",
                    "close": "Cancelar",
                    "inputPlaceholder": "Insira o e-mail",
                    "phoneNumber": {
                        "inputPlaceholder": "Insira o número de celular"
                    },
                    "userName": {
                        "inputPlaceholder": "Insira o ID"
                    }
                },
                "windows": {
                    "title": "Fazer login em {siteOrigin} com impressão digital, facial ou PIN",
                    "description": "",
                    "cta": "OK",
                    "close": "Cancelar",
                    "inputPlaceholder": "Insira o e-mail",
                    "phoneNumber": {
                        "inputPlaceholder": "Insira o número de celular"
                    },
                    "userName": {
                        "inputPlaceholder": "Insira o ID"
                    }
                }
            },
            "account-recover": {
                "title": "Recuperação de conta",
                "description": "Acesse com segurança sem redefinir sua senha",
                "cta": "Usar Face ID",
                "skip": "Redefinir senha",
                "md": {
                    "cta": "Continuar"
                }
            }
        },
        "slider": {
            "general": {
                "close": "Fechar",
                "md": {
                    "close": "Cancelar"
                }
            },
            "login-id-fallback": {
                "title": "Usar Código Único",
                "summary": "Um e-mail com um código de {codeLength} dígitos acaba de ser enviado para {loginId}",
                "description": "Insira o código",
                "cta": "Verificar",
                "resend": "Reenviar e-mail",
                "sms": {
                    "title": "Verifique sua identidade",
                    "summary": "Enviamos um código de {codeLength} dígitos para {loginId}",
                    "resend": "Reenviar SMS",
                    "md": {
                        "title": "Faça login com Impressão digital"
                    }
                }
            },
            "login-id-verification": {
                "title": "Verifique o e-mail",
                "summary": "Acabamos de enviar um código de {codeLength} dígitos para {loginId}",
                "description": "Insira o código",
                "cta": "Verificar",
                "resend": "Reenviar e-mail",
                "sms": {
                    "title": "Verifique sua identidade",
                    "summary": "Enviamos um código de {codeLength} dígitos para {loginId}",
                    "resend": "Reenviar SMS",
                    "md": {
                        "title": "Faça login com Impressão digital"
                    }
                }
            },
            "profile-collect": {
                "title": "Complete o seu Perfil",
                "cta": "Continuar"
            },
            "new-device": {
                "title": "Você está usando um celular diferente",
                "description": "Continuar para registrar este dispositivo",
                "cta": "Continuar"
            },
            "login-id-collect": {
                "title": "Faça login com Face ID",
                "description": "Insira o e-mail",
                "cta": "Continuar",
                "no-biometrics": {
                    "title": "Insira o e-mail",
                    "description": "para receber um código único"
                },
                "phoneNumber": {
                    "description": "Insira o número de celular",
                    "no-biometrics": {
                        "title": "Insira o número de celular",
                        "description": "para receber um código único"
                    },
                    "md": {
                        "title": "Faça login com Impressão digital"
                    }
                },
                "userName": {
                    "description": "Insira o ID",
                    "no-biometrics": {
                        "title": "Insira o ID",
                        "description": "para receber um código único"
                    },
                    "md": {
                        "title": "Faça login com Impressão digital"
                    }
                },
                "email": {
                    "no-biometrics": {
                        "title": "Insira o e-mail",
                        "description": "para receber um código único"
                    },
                    "md": {
                        "title": "Faça login com Impressão digital"
                    }
                }
            }
        },
        "enrollCredential": {
            "title": "Simplifique o login",
            "description": "Habilitar login sem senha?",
            "cta": "Usar Face ID",
            "skip": "Não, obrigado",
            "md": {
                "cta": "Continuar"
            }
        },
        "flow": {
            "header": "",
            "cta": "Continuar",
            "skip": "Agora não",
            "change": "Mudar",
            "close": "Fechar",
            "errors": {
                "general": {
                    "title": "Não é possível registrá-lo neste momento",
                    "description": "Não foi possível registrá-lo devido a um problema técnico da nossa parte. Por favor, tente acessar novamente."
                },
                "userNotFound": {
                    "title": "Você não possui uma conta",
                    "description": "[Consulte nossa documentação para iniciar esta jornada]"
                },
                "accountNotFound": {
                    "title": "Você não possui uma conta",
                    "description": "[Consulte nossa documentação para iniciar esta jornada]"
                },
                "wrongCodeLimitReached": {
                    "title": "Você atingiu o limite de tentativas",
                    "description": "Aguarde 5 minutos e tente novamente ou use um e-mail diferente"
                },
                "accountIsBlocked": {
                    "title": "Sua conta está bloqueada",
                    "description": "Por favor, tente mais tarde"
                },
                "sendCodeLimitReached": {
                    "title": "O limite de tentativas de envio de código foi atingido",
                    "description": "Por favor, tente mais tarde"
                }
            },
            "collectLoginId": {
                "title": "Insira o seu e-mail",
                "description": "Faça login na sua conta. Se não tiver uma, criaremos uma para você",
                "placeholder": "nome@exemplo.com",
                "error": "Insira um e-mail válido",
                "username": {
                    "title": "Insira o seu nome de usuário",
                    "placeholder": "Nome de usuário",
                    "error": "Insira um nome de usuário válido",
                    "label": "Nome de usuário"
                },
                "phoneNumber": {
                    "title": "Insira o seu número de telefone",
                    "placeholder": "Número de telefone",
                    "error": "Insira um número de telefone válido",
                    "label": "Número de telefone"
                },
                "label": "E-mail"
            },
            "enrollPasskeys": {
                "title": "Ative o Touch ID para fazer login",
                "description": "Torne seu login mais rápido. Os dados biométricos nunca saem do seu dispositivo",
                "note": "Está usando um dispositivo público ou compartilhado?",
                "alternativeCTA": "Continuar para minha conta",
                "windows": {
                    "title": "Ative o Windows Hello para fazer login",
                    "description": "Usar o Windows Hello para fazer login com rosto, impressão digital ou PIN"
                },
                "ios": {
                    "title": "Ative o Face ID para fazer login",
                    "note": ""
                },
                "md": {
                    "title": "Simplifique seu login\ncom chaves de acesso (passkeys)",
                    "description": "Usar impressão digital, rosto ou bloqueio de tela para\nfazer login neste dispositivo",
                    "note": ""
                }
            },
            "fidoCreate": {
                "title": "Ative o Touch ID para fazer login",
                "description": "Torne seu login mais rápido. Os dados biométricos nunca saem do seu dispositivo",
                "note": "Está usando um dispositivo público ou compartilhado?",
                "alternativeCTA": "Talvez mais tarde",
                "windows": {
                    "title": "Ative o Windows Hello para fazer login",
                    "description": "Usar o Windows Hello para fazer login com rosto, impressão digital ou PIN"
                },
                "ios": {
                    "title": "Ative o Face ID para fazer login",
                    "note": ""
                },
                "md": {
                    "title": "Simplifique seu login\ncom chaves de acesso (passkeys)",
                    "description": "Usar impressão digital, rosto ou bloqueio de tela para\nfazer login neste dispositivo",
                    "note": ""
                }
            },
            "fidoLogin": {
                "title": "Fazendo login com Touch ID...",
                "windows": {
                    "title": "Fazendo login com Windows Hello..."
                },
                "ios": {
                    "title": "Fazendo login com Face ID"
                },
                "md": {
                    "title": "Fazendo login..."
                }
            },
            "qr": {
                "title": "Escaneie um QR Code para fazer login",
                "note": "Escaneie este QR Code com seu dispositivo móvel pessoal",
                "loading": "Fazendo login...",
                "magicLink": "Faça login com um link de uso único",
                "fidoAuth": "Faça login com Touch ID",
                "cancel": "Cancelar",
                "windows": {
                    "fidoAuth": "Faça login com o Windows Hello"
                }
            },
            "magicLink": {
                "title": "Verifique seu e-mail",
                "description": "Enviamos um e-mail para você. Ele contém um link que permite fazer login sem precisar digitar uma senha."
            },
            "otp": {
                "title": "Verifique o código no seu e-mail",
                "description": "Insira o código para confirmar que é você",
                "note": "Não encontra seu código? Verifique a pasta de spam",
                "resend": "Reenviar código",
                "gmail": "Abrir o Gmail",
                "sms": {
                    "title": "Verifique o código no seu telefone",
                    "note": "Não encontra seu código?"
                }
            },
            "webappScreen": {
                "title": "Fazendo login com Touch ID",
                "description": "Usar o Touch ID para fazer login neste dispositivo",
                "alternativeCTA": "Tentar novamente",
                "ios": {
                    "title": "Fazendo login com Face ID",
                    "description": "Usar o Face ID para fazer login neste dispositivo"
                },
                "md": {
                    "title": "Fazendo login...",
                    "description": "Usar impressão digital, rosto ou bloqueio de tela para fazer login neste dispositivo"
                }
            },
            "returningUser": {
                "header": "",
                "title": "Bem-vindo(a) de volta",
                "description": "Use o Touch ID para continuar para a sua conta",
                "otherOptions": "Outras opções de login",
                "windows": {
                    "description": "Use o Windows Hello para continuar para a sua conta"
                },
                "ios": {
                    "description": "Use o Face ID para continuar para a sua conta"
                },
                "md": {
                    "description": "Torne seu login mais rápido. Os dados biométricos nunca saem do seu dispositivo"
                }
            },
            "collectProfile": {
                "title": "Complete seu perfil",
                "description": "Estamos muito felizes por ter você conosco!"
            },
            "authSelection": {
                "title": "Escolha seu método de login",
                "fidoAuth": "Faça login com o Face ID",
                "passwordAuth": "Faça login com Senha",
                "md": {
                    "fidoAuth": "Faça login com Impressão Digital"
                },
                "windows": {
                    "fidoAuth": "Faça login com o Windows Hello"
                },
                "macOS": {
                    "fidoAuth": "Faça login com o Touch ID"
                },
                "verification": {
                    "email": "Recupere sua conta com um código de uso único",
                    "phone": "Recupere sua conta com um código de uso único"
                },
                "deferred": {
                    "signin": "Faça login com uma chave de acesso (passkey)"
                }
            },
            "passwordCollection": {
                "title": "Digite sua senha",
                "otp": "Use um código de uso único",
                "qr": "Escaneie o QR code",
                "reset": "Esqueceu a senha?",
                "placeholder": "Senha",
                "errors": {
                    "required": "Por favor, digite uma senha",
                    "invalid": "Usuário ou senha incorretos. Tente novamente ou clique em 'esqueceu a senha'"
                }
            },
            "registerRedirect": {
                "title": "Junte-se a {siteName}",
                "description": "Desbloqueie ofertas e recompensas exclusivas.\nLeva apenas um minuto",
                "cta": "Configurar minha conta",
                "reset": "Voltar"
            },
            "social": {
                "cta": "Continuar com {provider}"
            }
        },
        "standaloneButton": {
            "qr": {
                "login": {
                    "title": "Faça login mais rapidamente sem senha"
                },
                "register": {
                    "title": "Cadastre-se mais rapidamente sem senha"
                },
                "recover": {
                    "title": "Faça login sem senha"
                }
            },
            "login": {
                "title": "Faça login com Touch ID",
                "windows": {
                    "title": "Faça login com o método\nde desbloqueio do dispositivo"
                },
                "md": {
                    "title": "Faça login com\nimpressão digital"
                },
                "ios": {
                    "title": "Faça login com Face ID"
                },
                "no-biometrics": {
                    "title": "Faça login com\num código de uso único"
                }
            },
            "register": {
                "title": "Cadastre-se com Touch ID",
                "windows": {
                    "title": "Cadastre-se com o método de\ndesbloqueio do dispositivo"
                },
                "md": {
                    "title": "Cadastre-se com impressão digital"
                },
                "ios": {
                    "title": "Cadastre-se com Face ID"
                },
                "no-biometrics": {
                    "title": "Cadastre-se com um\ncódigo de uso único"
                }
            },
            "recover": {
                "title": "Faça login com Touch ID",
                "windows": {
                    "title": "Faça login sem senha\ncom o desbloqueio do dispositivo"
                },
                "md": {
                    "title": "Faça login com impressão digital\n da conta"
                },
                "ios": {
                    "title": "Faça login com Face ID"
                },
                "no-biometrics": {
                    "title": "Recupere a conta com um\ncódigo de uso único"
                }
            }
        }
    }
});