const isObj = (value) => typeof value === 'object' && value !== null && !Array.isArray(value);

const obfuscateValue = (value) => {
  if(!value) {
    return value;
  }

  const valueString = value.toString();
  const valueSize = valueString.length;
  const obfuscateCaracter = '*';

  if (valueSize === 1) {
    return obfuscateCaracter;
  }
  const firstLetter = valueString.substring(0, 1);
  const lastLetter = valueString.substring(valueSize - 1);
  const otherLettersSize = valueSize - 2;
  const otherLetters = otherLettersSize > 20 ? `(${otherLettersSize})` : obfuscateCaracter.repeat(otherLettersSize);
  return `${firstLetter}${otherLetters}${lastLetter}`;
}

const obfuscateObj = (obj) => {
  const obfuscatedJson = {};

  Object.keys(obj).forEach(key => {
    const value = obj[key];
    obfuscatedJson[key] = value ? obfuscate(value) : value;
  });

  return obfuscatedJson;
}

export const obfuscate = (value) => {
  switch (true) {
    case isObj(value):
      return obfuscateObj(value);

    case Array.isArray(value):
      return value.map(obfuscate);

    case typeof value === 'function':
      return 'function';

    default:
      return obfuscateValue(value)
  }
}

