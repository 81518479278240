import PropTypes from 'prop-types';
import FILE_TYPES from "libs/feature-upload/src/defaults/file-types";
import { Wrapper } from './styles';

const Rules = ({ size, format }) => {

  let parsedRules = [];

  if (size) {
    parsedRules.push(`Tamanho ${size.width}x${size.height}px`);
  }

  if (format) {
    parsedRules.push(`Formato ${format.toUpperCase()}`);
  }

  return <Wrapper>{parsedRules.join(' | ')}</Wrapper>;
}

Rules.propTypes = {
  size: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  format: PropTypes.oneOf(Object.keys(FILE_TYPES)),
}

export default Rules;
