const validateRepeatedCharacter = (errorMessage = 'Todos os dígitos não podem ser iguais') =>
  () => ({
    validator(_, value) {
      if (value) {
        const uniqueChars = new Set(value);
        if (uniqueChars.size === 1) {
          return Promise.reject(new Error(errorMessage));
        }

        return Promise.resolve();
      }
    }
  });

export default validateRepeatedCharacter;
