import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef, useState } from "react";
import { createContext } from 'use-context-selector';
import { UPLOAD_STATUS_LOADING, UPLOAD_STATUS_WAITING, UPLOAD_STATUS_WARNING } from "../defaults/status";

export const UploadContext = createContext({});

export const UploadProvider = ({ headers, serviceEndpoint, accept, children }) => {

  const inputRef = useRef();
  const formRef = useRef();

  const [errors, setErrors] = useState([]);
  const [file, setFile] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [status, setStatus] = useState(UPLOAD_STATUS_WAITING);
  const [progress, setProgress] = useState(0);

  const handleInputFileChange = useCallback(evt => {
    const [inputFile] = evt.target.files;
    setFile(inputFile);
  }, [setFile]);

  const callSelectFile = useCallback(() => {
    inputRef.current.click();
  }, [inputRef]);

  const reset = useCallback(() => {
    setErrors([]);
    setProgress(0);
    setFile(null);
    setFileId(null);
    setStatus(UPLOAD_STATUS_WAITING);
    formRef.current?.reset?.();
  }, [setFile, setProgress]);

  const send = useCallback(() => {
    setStatus(UPLOAD_STATUS_LOADING);

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.upload.onprogress = function(evt) {
        if (evt.lengthComputable) {
          const percentageCompleted = parseInt((evt.loaded / evt.total) * 100);
          setProgress(percentageCompleted);
        }
      };

      xhr.upload.onerror = function() {
        setProgress(100);
        setStatus(UPLOAD_STATUS_WARNING);

        reject(new Error());
      };

      xhr.onload = function() {
        setProgress(100);

        if (this.status === 200 || this.status === 201) {
          const { id: fileId } = JSON.parse(xhr.responseText);

          setFileId(fileId);
          resolve(fileId);
        }
        else {
          setStatus(UPLOAD_STATUS_WARNING);
          reject(new Error());
        }
      };

      const formData = new FormData();
      formData.append("file", file);

      xhr.open("PUT", serviceEndpoint);

      Object.keys(headers).forEach(
        header => xhr.setRequestHeader(header, headers[header])
      );

      xhr.send(formData);
    });
  }, [file, headers, serviceEndpoint]);

  const value = useMemo(() => ({
    errors,
    file,
    fileId,
    progress,
    status,
    callSelectFile,
    reset,
    send,
    setErrors,
    setStatus,
  }), [callSelectFile, errors, file, fileId, progress, reset, send, status]);

  return (
    <UploadContext.Provider value={value}>
      <form ref={formRef}>
        <input
          data-testid="file-input"
          ref={inputRef}
          type="file"
          files={file ? [file] : []}
          accept={accept}
          hidden
          onChange={handleInputFileChange}/>
      </form>
      {children}
    </UploadContext.Provider>
  )
}

UploadProvider.defaultProps = {
  accept: '*'
}

UploadProvider.propTypes = {
  children: PropTypes.element,
  accept: PropTypes.string,
  headers: PropTypes.object,
  serviceEndpoint: PropTypes.string,
}
