/* E-commerce */
export const TEMPLATE__ECOMMERCE_CONTEXTFUL = 'CREDIT_CONTEXTFUL';
export const TEMPLATE__ECOMMERCE_INTEGRATED = 'CREDIT_INTEGRATED';
export const TEMPLATE__ECOMMERCE_IFRAME_ONE_PAGE = 'CREDIT_IFRAME_ONE_PAGE';

/* Frictionless */
export const TEMPLATE__ECOMMERCE_FRICTIONLESS_INTEGRATED = 'CREDIT_FRICTIONLESS_INTEGRATED';
export const TEMPLATE__ECOMMERCE_FRICTIONLESS_CONTEXTFUL = 'CREDIT_FRICTIONLESS_CONTEXTFUL';

/* Wallet */
export const TEMPLATE__WALLET_INTEGRATED = 'WALLET_INTEGRATED';
export const TEMPLATE__WALLET_CONTEXTFUL = 'WALLET_CONTEXTFUL';
