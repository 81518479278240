import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { message } from "antd";
import { validateRequired } from "@frontend/shared-input-validators";
import { Button, Form, Icon, Input, Select} from "../../"
import { FILE_EXTENSION_PNG, Upload, UploadProvider, useUpload, UPLOAD_TYPE_IMAGE, FILE_TYPES } from '@frontend/feature-upload';
import { getAccessTokenFromStorage, useCompanySettings, updateCompanySettings } from "@frontend/shared-context";
import { AllowedDomainsHeading, BtnAddDomain, ButtonsArea, DataDisplay, LogoDisplay, LogoDisplayWrapper, LongDataDisplay, Wrapper } from "./styles";
import { COMPANIES_KIND, COMPANY_INTEGRATION } from "../../../../pay/shared-defaults/src/defaults/companies";
import AllowedDomains from "./allowed-domains";
import { useTranslation } from "@frontend/feature-i18n";

const MAX_ALLOWED_DOMAINS = 5;

export const Enhanceable = () => {

  const [editing, setEditing] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [currentAllowedDomainsCount, setCurrentAllowedDomainsCount] = useState(0);

  const [aboutForm] = Form.useForm();
  const { loading: loadingSettings, settings, pullSettingsTrigger } = useCompanySettings();
  const { fileId } = useUpload();

  const allowedDomainsRef = useRef();

  const { getTranslation } = useTranslation();

  useEffect(() => {
    aboutForm.setFieldsValue({
      company: settings?.company?.name,
      description: settings?.company?.description,
      kind: settings?.company?.kind,
      integrationModel: settings?.company?.integrationModel
    })
  }, [aboutForm, settings]);

  const canAddMoreAllowedDomains = useMemo(() => 
    currentAllowedDomainsCount < MAX_ALLOWED_DOMAINS  
  , [currentAllowedDomainsCount]);

  const handleCancel = () => {
    setEditing(false);
  }

  const handleStartEditing = () => {
    setEditing(true);
  }

  const handleSave = useCallback(async () => {
    setLoadingSave(true);

    let allowedDomains;
    let formValues;

    try {
      allowedDomains = await allowedDomainsRef.current?.validateFields();
      formValues = await aboutForm.validateFields();
    }
    catch (e) {
      setLoadingSave(false);
      return;
    }

    try {
      const { kind, description, integrationModel } = formValues;

      await updateCompanySettings(
        settings?.company?.id,
        {
          allowedDomains,
          kind,
          description,
          integrationModel,
          fileId: fileId ?? settings?.template?.logoId
        }
      );

      pullSettingsTrigger();

      message.success('As alterações foram salvas com sucesso!');
      setEditing(false);
    }
    catch (e) {
      message.warn('Não foi possível salvar as alterações. Tente novamente mais tarde.');
    }

    setLoadingSave(false);
  }, [aboutForm, fileId, settings, pullSettingsTrigger]);

  if (editing) {
    return (
      <Wrapper loadingData={loadingSettings}>
        <Form form={aboutForm} layout="vertical" requiredMark={false}>
          <Form.Item name="company" label="Nome da empresa">
            <DataDisplay>{settings?.company?.name}</DataDisplay>
          </Form.Item>
          <Form.Item name="kind" label="Tipo de uso do cliente" rules={[validateRequired()]}>
            <Select disabled={loadingSave} style={{ width: '320px' }}>
              { Object.keys(COMPANIES_KIND).map((kind) => <Select.Option key={kind}>{COMPANIES_KIND[kind]}</Select.Option>) }
            </Select>
          </Form.Item>
          <Form.Item name="integrationModel" label="Modelo de integração">
            <Select disabled={loadingSave} placeholder="Como essa empresa usa o produto?">
              { Object.keys(COMPANY_INTEGRATION).map((integration) => <Select.Option key={integration}>{COMPANY_INTEGRATION[integration]}</Select.Option>) }
            </Select>
          </Form.Item>
          <Form.Item name="description" label="Descritivo da empresa">
            <Input.TextArea disabled={loadingSave} placeholder="Insira uma descrição..." />
          </Form.Item>
          <Upload
            disabled={loadingSave}
            label="Clique aqui para alterar a logo"
            failLabel="Não foi possível alterar a logo"
            rules={{
              size: {
                width: 56,
                height: 56,
              },
              format: FILE_EXTENSION_PNG,
            }}
            successLabel="Logo enviada com sucesso"
            type={UPLOAD_TYPE_IMAGE} />
        </Form>
        <AllowedDomainsHeading>
          <h2>{getTranslation('settings.allowedDomains')}</h2>
          {
            canAddMoreAllowedDomains
            ? <BtnAddDomain disabled={loadingSave} onClick={() => allowedDomainsRef.current?.addDomain()}><Icon type="plus" /> {getTranslation('settings.add')}</BtnAddDomain>
            : <BtnAddDomain disabled>{getTranslation('settings.maxAllowedDomainsExceeded')}</BtnAddDomain>
          }
        </AllowedDomainsHeading>
        <AllowedDomains
          disabled={loadingSave}
          domains={settings?.company?.allowedDomains}
          updateCount={setCurrentAllowedDomainsCount}
          ref={allowedDomainsRef} />
        <ButtonsArea>
          <Button disabled={loadingSave} outline onClick={handleCancel}>Cancelar</Button>
          <Button disabled={loadingSave} iconSize={24} onClick={handleSave}>
            {loadingSave && <Icon type="loading" />}
            Salvar alterações
          </Button>
        </ButtonsArea>
      </Wrapper>
    )
  }

  return (
    <Wrapper loadingData={loadingSettings}>
      <Form form={aboutForm} layout="vertical" requiredMark={false}>
        <Form.Item name="company" label="Nome da empresa">
          <DataDisplay>{settings?.company?.name}</DataDisplay>
        </Form.Item>
        <Form.Item name="kind" label="Tipo de uso do cliente" rules={[validateRequired()]}>
          <DataDisplay>{COMPANIES_KIND[settings?.company?.kind]}</DataDisplay>
        </Form.Item>
        <Form.Item name="integrationModel" label="Modelo de integração">
          <DataDisplay>{COMPANY_INTEGRATION[settings?.company?.integrationModel] || "Sem modelo definido"}</DataDisplay>
        </Form.Item>
        <Form.Item name="description" label="Descritivo da empresa">
          <LongDataDisplay style={{ minHeight: '50px' }}>{settings?.company?.description}</LongDataDisplay>
        </Form.Item>
        <LogoDisplayWrapper>
          {
            settings?.company?.logo
            ? (
              <>
                <LogoDisplay imageURL={settings?.company?.logo} />
                <p>Para selecionar outra logo, é necessário editar o cadastro.</p>
              </>
            ) : (
              <>
                <LogoDisplay>
                  <Icon type={loadingSettings ? "loading" : "image"} />
                </LogoDisplay>
                <p>Cadastre uma logo agora, editando o cadastro</p>
              </>
            )
          }
        </LogoDisplayWrapper>
        <AllowedDomainsHeading>
          <h2>{getTranslation('settings.allowedDomains')}</h2>
        </AllowedDomainsHeading>
        <AllowedDomains
          disabled
          domains={settings?.company?.allowedDomains}
          updateCount={setCurrentAllowedDomainsCount}
          ref={allowedDomainsRef} />
        <ButtonsArea>
          <Button
            disabled={loadingSettings}
            outline
            iconSize={16}
            onClick={handleStartEditing}>
              {
                loadingSettings
                ? <Icon type="loading"/>
                : <Icon type="pen"/>
              }
              Editar cadastro
          </Button>
        </ButtonsArea>
      </Form>
    </Wrapper>
  )
};

const About = () => (
  <UploadProvider
    accept={FILE_TYPES[FILE_EXTENSION_PNG].type}
    serviceEndpoint={`${process.env.NX_PUBLIC_FILESERVER_ENDPOINT}/api/v1/upload`}
    headers={{
      'Authorization': `Bearer ${getAccessTokenFromStorage()}`,
    }}>
    <Enhanceable />
  </UploadProvider>
);

export default About;
