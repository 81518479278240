import { useContextSelector } from 'use-context-selector';
import { AuthPkceContext } from "../../context/auth-pkce"

const useAuthPkce = () => {

  const accessToken = useContextSelector(AuthPkceContext, context => context.accessToken);
  const setAccessToken = useContextSelector(AuthPkceContext, context => context.setAccessToken);

  return {
    accessGranted: Boolean(accessToken),
    accessToken,
    setAccessToken,
  }
}

export default useAuthPkce;
