import { Dialog, FeatureIcon } from '@acesso-io/genoma-ui';
import AccessTime from '@mui/icons-material/AccessTime';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { authorize, logout } from '@frontend/shared-context';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from '@frontend/feature-i18n';

const FIVE_SECONDS_TIMEOUT = 5000;

export const Error401Modal = ({ open }) => {
  const { getTranslationAsString } = useTranslation();
  const { enableIdentity401Authorize } = useFlags()

  useEffect(() => {
    if (open) {
      const authorizeTimeout = setTimeout(() => {
        if (enableIdentity401Authorize) {
          authorize();
        } else {
          logout()
        }
      }, FIVE_SECONDS_TIMEOUT);
      return () => {
        clearTimeout(authorizeTimeout);
      };
    }
  }, [enableIdentity401Authorize, open]);

  return (
    <Dialog open={open} size="small">
      <Dialog.Title typography="h6">
        <FeatureIcon
          color="primary"
          themecolor="light"
          type="circle"
          size="md"
        ><AccessTime /></FeatureIcon>
        {getTranslationAsString('PleaseWait', {}, 'Aguarde alguns instantes...')}
      </Dialog.Title>
      <Dialog.Content>
        <Dialog.ContentText typography="body1">
          {getTranslationAsString('SystemValidatingAccess', {}, 'Nosso sistema está validando o seu acesso, talvez seja necessário fazer login novamente.')}
        </Dialog.ContentText>
      </Dialog.Content>
    </Dialog>
  );
};

Error401Modal.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default Error401Modal;
