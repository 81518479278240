import { logout, logoutPkce } from '@frontend/shared-context';
import { emitterEvents } from '../event-emitter/events';

import eventEmitter from '../event-emitter';

const STATUS_CODE_UNAUTHORIZED = 401;

export const fetchIntercept = async (resource, config = {}, enableIdentityAuthorize = false, useAuthPkce = false) => {
  const response = await fetch(resource, config);
  if (response.status === STATUS_CODE_UNAUTHORIZED) {
    if (enableIdentityAuthorize) {
      eventEmitter.emit(emitterEvents.IDENTITY_AUTHORIZE_MODAL_OPEN_EVENT, true)
    } else {
      useAuthPkce
        ? logoutPkce()
        : logout()
    }
  }

  return response;
}

export default fetchIntercept;
