import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Checkbox, Icon } from '@frontend/shared-ui';
import { FILTER_TYPE } from '@frontend/shared-defaults';
import { ColorIndicator, IconWrapper, Wrapper, HeaderItem } from "./styles";

const FilterItem = ({ label, color, icon, iconColor, value, onSelectionChange, type, isSelected = false }) => {

  const [selected, setSelected] = useState(isSelected);

  const changeSelection = () => {
    const newState = !selected;

    setSelected(newState);
    onSelectionChange(value, newState, type);
  }

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  if (type === FILTER_TYPE.HEADER) {
    return <HeaderItem onClick={changeSelection}>
      <span>{label}</span>
      <Checkbox checked={selected} />
    </HeaderItem>;
  }

  return (
    <Wrapper iconColor={iconColor} onClick={changeSelection}>
      <div>
        <Checkbox checked={selected} />
        {label}
      </div>
      {icon && <IconWrapper><Icon type={icon} /></IconWrapper>}
      {color && <ColorIndicator color={color} />}
    </Wrapper>
  )
}

FilterItem.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  value: PropTypes.any.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  isSelected: PropTypes.bool,
};

export default FilterItem;
