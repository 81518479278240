import ErrorTemplate from "../../components/error-template";
import { useTranslation } from '@frontend/feature-i18n';

export const Error400 = () => {
  const { getTranslationAsString } = useTranslation();

  return (
    <ErrorTemplate
      errorCode='400'
      title={getTranslationAsString('PageDidNotWorkCorrectly', {}, 'A página não funcionou corretamente')}
    >
      <p>
      {getTranslationAsString('InvalidRequestCheckData', {}, 'Requisição inválida, verifique os dados e tente novamente.')}
      </p>
    </ErrorTemplate>
  );
};

export default Error400;



