import PropTypes from 'prop-types';
import { Fragment } from 'react';
import Icon from '../../lib/icon';
import { RadioButton, RadioGroup } from "./styles";

const RadioButtonGroup = ({ items, onChange, value }) => {

  const onClickHandle = onClick => evt => {
    evt.target.blur();

    if (onClick) {
      onClick(evt);
    }
  }

  return (
    <RadioGroup onChange={evt => onChange(evt.target.value)} value={value}>
      {
        items?.map(
          ({ extra, icon, value, onClick, label, disabled }) =>
            <Fragment key={value}>
              <RadioButton disabled={disabled} icon={icon} value={value} onClick={onClickHandle(onClick)}>
                {
                  icon && <Icon type={icon} />
                }
                <span>{label}</span>
              </RadioButton>
              {extra}
            </Fragment>
        )
      }
    </RadioGroup>
  );
}

RadioButtonGroup.defaultProps = {
  items: [],
  onChange: () => { console.log('onChange not implemented') }
}

RadioButtonGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any,
      onClick: PropTypes.func,
      value: PropTypes.string.isRequired,
      extra: PropTypes.element,
    }).isRequired
  ),
  onChange: PropTypes.func
}

export default RadioButtonGroup;
