import { getHashParams } from "../get-hash-params";

export const removeParamUrl = (url, param) => {
  const valueParam = getHashParams(url)[param];
  const paramWithValue = `${param}=${valueParam}`;

  const cleanUrl = url
    .replace(`${paramWithValue}`, '')
    .replace(`?&`, '?')
    .replace(`&&`, '&');

  return cleanUrl.endsWith("&") || cleanUrl.endsWith("?")
    ? cleanUrl.slice(0, -1) 
    : cleanUrl;
}