import { useEffect, useMemo } from 'react';
import { useNotificationSettings } from '../../screens/sms/hooks/useNotificationSettings';
import * as eventsName from '../../defaults/events_track';
import { hasPermission, obfuscate } from '@frontend/shared-utility';
import version from "../../../assets/scripts/.version.js";
import sdkVersions from "../../../assets/scripts/.sdk-versions.js";
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk';
import { isIframe } from '../../screens/sms/utility/is-frame';
import { isMobile, newIsMobile } from '../../screens/sms/utility/is-mobile';
import { useEventsTrack } from '@frontend/feature-monitor';
import { getSDKVersion } from '../../screens/sms/utility/get-sdk-version';

const {
  SCOPE_TRACK__B2C,
  EVENT_TRACK__SHARE,
  EVENT_TRACK__START_SDK,
  EVENT_TRACK__ERROR_SDK,
  EVENT_TRACK__START_FLOW,
  EVENT_TRACK__OPEN_MODAL,
  EVENT_TRACK__FINALLY_FLOW,
  EVENT_TRACK__SHOW_FLOW,
  EVENT_TRACK__ERROR_FLOW,
  EVENT_TRACK__SUCCESS_SDK,
  EVENT_TRACK__SUCCESS_VALIDATE_BIO,
  EVENT_TRACK__PREPARE_INIT_SDK,
  EVENT_TRACK__PREPARE_FINALLY_SDK,
  EVENT_TRACK__PAGE_VIEWED,
  EVENT_TRACK__CLICK,
  EVENT_TRACK__NOT_RECOGNIZE,
  EVENT_TRACK__ASSUME_OWNERSHIP,
  EVENT_TRACK__CONFIRM_NOT_OWNER,
  EVENT_TRACK__OPEN_POPUP,
  EVENT_TRACK__CHANGE_LANGUAGE,
  EVENT_TRACK__INTEGRATION_ERROR,
  EVENT_TRACK__REDIRECT_TO_LINK,
  EVENT_TRACK__API_ERROR,
  EVENT_TRACK__REQUEST_PERMISSION,
  EVENT_TRACK__RESULT_REQUEST_PERMISSION,
  EVENT_TRACK__HANDLE_POLLING_REDIRECT,
  EVENT_TRACK__STOP_SESSION_CAMERA,
  EVENT_TRACK__ERROR_OPEN_SDK,
  EVENT_TRACK__REQUEST_COLLECTION_ID,
  EVENT_TRACK__RESULT_COLLECTION_ID,
  EVENT_TRACK__TIMEOUT_COLLECTION_ID,
  EVENT_TRACK__REQUEST_COLLECTION_RESULT,
  EVENT_TRACK__RESULT_COLLECTION_RESULT,
  EVENT_TRACK__TIMEOUT_COLLECTION_RESULT,
  EVENT_TRACK__ERROR_REQUEST_FRICTIONLESS_STATUS,
  EVENT_TRACK__REQUEST_FRICTIONLESS_STATUS,
  EVENT_TRACK__FRICTIONLESS_STATUS,
  EVENT_TRACK__FINALLY_TIMER_FRICTIONLESS,
  EVENT_TRACK__START_CHECK_PERMISSIONS_POPUP,
  EVENT_TRACK__NOT_OPEN_PERMISSIONS_POPUP,
  EVENT_TRACK__ERROR_PREPARE_SDK,
  EVENT_TRACK__BUILD_INIT_SDK,
  EVENT_TRACK__BUILD_FINALLY_SDK,
  EVENT_TRACK__SUCCESS_BELVO,
  EVENT_TRACK__EXIT_BELVO,
  EVENT_TRACK__ERROR_BELVO,
  EVENT_TRACK__REDIRECT_CALLBACK,
  EVENT_TRACK__RECEIVE_CALLBACK,
  EVENT_TRACK__OPEN_URL_BELVO,
  EVENT_TRACK__START_CHAT,
  EVENT_TRACK__START_LOADING_PAGE,
  EVENT_TRACK__ON_ERROR_LOGIN_PASSKEY,
  EVENT_TRACK__ON_ERROR_REGISTER_PASSKEY,
  EVENT_TRACK__CLICK_LOGIN_PASSKEY,
  EVENT_TRACK__CLICK_REGISTER_PASSKEY,
  EVENT_TRACK__ON_SUCCESS_LOGIN_PASSKEY,
  EVENT_TRACK__ON_SUCCESS_REGISTER_PASSKEY,
  EVENT_TRACK__NO_REGISTER_PASSKEY,
  EVENT_TRACK__SHOW_NOW_MORE_PASSKEY,
  EVENT_TRACK__BACK_NOW_MORE_PASSKEY,
  EVENT_TRACK__ERROR_REGISTER_PASSKEY,
  EVENT_TRACK__SUCCESS_REGISTER_PASSKEY,
  EVENT_TRACK__ERROR_VALIDATE_PASSKEY,
  EVENT_TRACK__SUCCESS_VALIDATE_PASSKEY,
} = eventsName;

export const usePayEventsTrack = (scope = SCOPE_TRACK__B2C) => {
  const { company, utm, template, transactionId } = useNotificationSettings();
  const LDclient = useLDClient();
  const featureFlags = useFlags();
  const { trackGTM } = useEventsTrack();

  const mobile = useMemo(() => isMobile(), []);
  const newMobileInfo = useMemo(() => newIsMobile(), []);
  const dataLayerName = 'dataLayerIdPay';
  const dataTrack = {
    template: template?.type,
    configurationSteps: template?.steps,
    channel: utm,
    company: company,
    deviceType: mobile ? 'mobile' : 'desktop',
    newDeviceType: newMobileInfo ? 'mobile' : 'desktop',
    deviceInformation: window.navigator.userAgent,
    cameraPermission: false,
    isIframe: isIframe(),
    sdkIntegrationVersion: getSDKVersion(),
    releaseVersion: version,
    transactionId,
    featureFlags,
  };

  useEffect(() => {
    if (window.dateTimeStartLoadingPage) {
      trackGTM(`${scope}_${EVENT_TRACK__START_LOADING_PAGE}`, {
        ...dataTrack,
        createdAt: window.dateTimeStartLoadingPage,
      }, dataLayerName);
      window.dateTimeStartLoadingPage = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkPermissions = async () => {
    dataTrack.cameraPermission = await hasPermission('camera');
  };

  const trackEvent = async (event, data = {}, sendErrorToSentry = false) => {
    await checkPermissions();

    const eventName = `${scope}_${event}`;
    const eventData = {
      ...dataTrack,
      ...data,
      createdAt: new Date().toISOString(),
    };

    if (featureFlags.enableSentryEvents) {
      // eslint-disable-next-line no-undef
      Sentry?.addBreadcrumb({
        category: 'application-events',
        message: eventName,
        data: event === EVENT_TRACK__START_FLOW ? eventData : data
      });
    }

    if (sendErrorToSentry) {
      // eslint-disable-next-line no-undef
      Sentry?.captureMessage(eventName, {
        level: 'error',
        extra: eventData,
      });
    }

    trackGTM(eventName, eventData, dataLayerName);

    LDclient.track(eventName);
  };

  const trackPageViewed = async (page) => {
    await checkPermissions();

    const eventName = `${scope}_${EVENT_TRACK__PAGE_VIEWED}_${page}`;
    const eventData = {
      ...dataTrack,
      page,
      createdAt: new Date().toISOString(),
    };

    trackGTM(eventName, eventData, dataLayerName);
    LDclient.track(eventName);
  };

  const trackStartFlow = async () => {
    await trackEvent(EVENT_TRACK__START_FLOW, true);
  };

  const trackClick = async (local, buttonName) =>
    await trackEvent(`${EVENT_TRACK__CLICK}_${buttonName}`, { local });

  const trackModalOpen = async (modalName) =>
    await trackEvent(`${EVENT_TRACK__OPEN_MODAL}_${modalName}`);

  const trackPopUpOpen = async (popUpName, message) =>
    await trackEvent(`${EVENT_TRACK__OPEN_POPUP}_${popUpName}`, { message });

  const trackChangeLanguage = async (language) =>
    await trackEvent(EVENT_TRACK__CHANGE_LANGUAGE, { language });

  const trackSDKError = async (codeError, messageError, sdkMessageError, version, origin) =>
    await trackEvent(EVENT_TRACK__ERROR_SDK, { codeError, messageError, sdkMessageError, sdkVersion: sdkVersions[version], origin }, true);

  const trackShare = async () => await trackEvent(EVENT_TRACK__SHARE);

  const trackFinallyFlow = async () =>
    await trackEvent(EVENT_TRACK__FINALLY_FLOW);

  const trackShowFlow = async () =>
    await trackEvent(EVENT_TRACK__SHOW_FLOW);

  const trackErrorFlow = async () =>
    await trackEvent(EVENT_TRACK__ERROR_FLOW, {}, true);

  const trackRedirect = async () =>
    await trackEvent(EVENT_TRACK__REDIRECT_TO_LINK);

  const trackPollingRedirect = async pollingTriggerMode =>
    await trackEvent(EVENT_TRACK__HANDLE_POLLING_REDIRECT, { pollingTriggerMode });

  const trackSDKStart = async (version) =>
    await trackEvent(EVENT_TRACK__START_SDK, { sdkVersion: sdkVersions[version] });

  const trackSDKSuccess = async (version) =>
    await trackEvent(EVENT_TRACK__SUCCESS_SDK, { sdkVersion: sdkVersions[version] });

  const trackSuccessValidateBio = async () => await trackEvent(EVENT_TRACK__SUCCESS_VALIDATE_BIO);

  const trackSDKPrepareInit = async (version) =>
    await trackEvent(EVENT_TRACK__PREPARE_INIT_SDK, { sdkVersion: sdkVersions[version] });

  const trackSDKPrepareFinally = async (version) =>
    await trackEvent(EVENT_TRACK__PREPARE_FINALLY_SDK, { sdkVersion: sdkVersions[version] });

  const trackAssumeOwnership = async () =>
    await trackEvent(EVENT_TRACK__ASSUME_OWNERSHIP);

  const trackExitBelvo = async (description) =>
    await trackEvent(EVENT_TRACK__EXIT_BELVO, { description });

  const trackSuccessBelvo = async () =>
    await trackEvent(EVENT_TRACK__SUCCESS_BELVO);

  const trackErrorBelvo = async (description) =>
    await trackEvent(EVENT_TRACK__ERROR_BELVO, { description }, true);

  const trackRedirectCallback = async (linkRedirect, origin) =>
    await trackEvent(EVENT_TRACK__REDIRECT_CALLBACK, { linkRedirect, origin });

  const trackReceiveCallback = async (origin) =>
    await trackEvent(EVENT_TRACK__RECEIVE_CALLBACK, { origin });

  const trackOpenUrlBelvo = async () =>
    await trackEvent(EVENT_TRACK__OPEN_URL_BELVO);

  const trackStopSessionCamera = async () =>
    await trackEvent(EVENT_TRACK__STOP_SESSION_CAMERA);

  const trackErrorOpenSDK = async () =>
    await trackEvent(EVENT_TRACK__ERROR_OPEN_SDK, {}, true);

  const trackConfirmNotOwner = async () =>
    await trackEvent(EVENT_TRACK__CONFIRM_NOT_OWNER);

  const trackNotRecognize = async () =>
    await trackEvent(EVENT_TRACK__NOT_RECOGNIZE);

  const trackIntegrationError = async (error) =>
    await trackEvent(EVENT_TRACK__INTEGRATION_ERROR, error, true);

  const trackAPIError = async (apiName, apiURL, response, requestbodySent) => {
    await trackEvent(EVENT_TRACK__API_ERROR, { apiName, apiURL, requestbodySent: obfuscate(requestbodySent), response }, true);
  }

  const trackRequestPermission = async (typeResquest) => {
    await trackEvent(EVENT_TRACK__REQUEST_PERMISSION, { typeResquest });
  }

  const trackResultRequestPermission = async (typeResquest, resultResquest) => {
    await trackEvent(EVENT_TRACK__RESULT_REQUEST_PERMISSION, { typeResquest, resultResquest });
  }

  const trackRequestCollectionID = async () => await trackEvent(EVENT_TRACK__REQUEST_COLLECTION_ID);

  const trackRequestCollectionResult = async () => await trackEvent(EVENT_TRACK__REQUEST_COLLECTION_RESULT);

  const trackErrorFrictionlessStatus = async (error) => await trackEvent(EVENT_TRACK__ERROR_REQUEST_FRICTIONLESS_STATUS, { error }, true);

  const trackResultCollectionID = async (collectionID) => await trackEvent(EVENT_TRACK__RESULT_COLLECTION_ID, { collectionID });

  const trackResultCollectionResult = async (collectionResult) => await trackEvent(EVENT_TRACK__RESULT_COLLECTION_RESULT, { collectionResult });

  const trackTimeoutCollectionID = async (timeout) => await trackEvent(EVENT_TRACK__TIMEOUT_COLLECTION_ID, { timeout });

  const trackTimeoutCollectionResult = async (timeout) => await trackEvent(EVENT_TRACK__TIMEOUT_COLLECTION_RESULT, { timeout });

  const trackRequestFrictionlessStatus = async () => await trackEvent(EVENT_TRACK__REQUEST_FRICTIONLESS_STATUS);

  const trackFrictionlessStatus = async (status) => await trackEvent(EVENT_TRACK__FRICTIONLESS_STATUS, { status });

  const trackFinallyTimerFrictionless = async () => await trackEvent(EVENT_TRACK__FINALLY_TIMER_FRICTIONLESS);

  const trackStartCheckPermissionsPopUp = async () => await trackEvent(EVENT_TRACK__START_CHECK_PERMISSIONS_POPUP);

  const trackNotOpenPermissionsPopUp = async () => await trackEvent(EVENT_TRACK__NOT_OPEN_PERMISSIONS_POPUP);

  const trackErrorPrepareSDK = async (error) => await trackEvent(EVENT_TRACK__ERROR_PREPARE_SDK, { error }, true);

  const trackSDKBuildInit = async () => await trackEvent(EVENT_TRACK__BUILD_INIT_SDK);

  const trackSDKBuildFinally = async () => await trackEvent(EVENT_TRACK__BUILD_FINALLY_SDK);

  const trackStartChat = async (description) => await trackEvent(EVENT_TRACK__START_CHAT, { description });

  const trackOnErrorLoginPasskey = async (error) => await trackEvent(EVENT_TRACK__ON_ERROR_LOGIN_PASSKEY, { description: error }, true);

  const trackOnErrorRegisterPasskey = async (error) => await trackEvent(EVENT_TRACK__ON_ERROR_REGISTER_PASSKEY, { description: error }, true);

  const trackClickLoginPasskey = async () => await trackEvent(EVENT_TRACK__CLICK_LOGIN_PASSKEY);

  const trackClickRegisterPasskey = async () => await trackEvent(EVENT_TRACK__CLICK_REGISTER_PASSKEY);

  const trackOnSuccessLoginPasskey = async () => await trackEvent(EVENT_TRACK__ON_SUCCESS_LOGIN_PASSKEY);

  const trackOnSuccessRegisterPasskey = async () => await trackEvent(EVENT_TRACK__ON_SUCCESS_REGISTER_PASSKEY);

  const trackNoRegisterPasskey = async () => await trackEvent(EVENT_TRACK__NO_REGISTER_PASSKEY);

  const trackShowKnowMorePasskey = async () => await trackEvent(EVENT_TRACK__SHOW_NOW_MORE_PASSKEY);

  const trackBackKnowMorePasskey = async () => await trackEvent(EVENT_TRACK__BACK_NOW_MORE_PASSKEY);

  const trackErrorRegisterPasskey = async (error) => await trackEvent(EVENT_TRACK__ERROR_REGISTER_PASSKEY, error, true);

  const trackSuccessRegisterPasskey = async () => await trackEvent(EVENT_TRACK__SUCCESS_REGISTER_PASSKEY);

  const trackErrorValidatePasskey = async (error) => await trackEvent(EVENT_TRACK__ERROR_VALIDATE_PASSKEY, error, true);

  const trackSuccessValidatePasskey = async () => await trackEvent(EVENT_TRACK__SUCCESS_VALIDATE_PASSKEY);

  return {
    trackEvent,
    trackShare,
    trackClick,
    trackSDKStart,
    trackSDKError,
    trackModalOpen,
    trackSDKSuccess,
    trackSuccessValidateBio,
    trackPageViewed,
    trackNotRecognize,
    trackAssumeOwnership,
    trackConfirmNotOwner,
    trackStartFlow,
    trackFinallyFlow,
    trackShowFlow,
    trackErrorFlow,
    trackPopUpOpen,
    trackChangeLanguage,
    trackIntegrationError,
    trackRedirect,
    trackPollingRedirect,
    trackSDKPrepareInit,
    trackSDKPrepareFinally,
    trackAPIError,
    trackRequestPermission,
    trackResultRequestPermission,
    trackStopSessionCamera,
    trackErrorOpenSDK,
    trackRequestCollectionID,
    trackResultCollectionID,
    trackTimeoutCollectionID,
    trackRequestCollectionResult,
    trackResultCollectionResult,
    trackTimeoutCollectionResult,
    trackRequestFrictionlessStatus,
    trackFrictionlessStatus,
    trackErrorFrictionlessStatus,
    trackFinallyTimerFrictionless,
    trackStartCheckPermissionsPopUp,
    trackNotOpenPermissionsPopUp,
    trackErrorPrepareSDK,
    trackSDKBuildInit,
    trackSDKBuildFinally,
    trackExitBelvo,
    trackSuccessBelvo,
    trackErrorBelvo,
    trackRedirectCallback,
    trackReceiveCallback,
    trackOpenUrlBelvo,
    trackStartChat,
    trackOnErrorLoginPasskey,
    trackOnErrorRegisterPasskey,
    trackOnSuccessLoginPasskey,
    trackOnSuccessRegisterPasskey,
    trackNoRegisterPasskey,
    trackClickLoginPasskey,
    trackClickRegisterPasskey,
    trackShowKnowMorePasskey,
    trackBackKnowMorePasskey,
    trackErrorRegisterPasskey,
    trackSuccessRegisterPasskey,
    trackErrorValidatePasskey,
    trackSuccessValidatePasskey,
    eventsName,
  };
};
