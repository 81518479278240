export const PERMISSION_BATCH_TRANSACTIONS = 'BatchTransactions';
export const PERMISSION_CREATE_TRANSACTION = 'CreateTransaction';
export const PERMISSION_GET_TRANSACTION = 'GetTransaction';
export const PERMISSION_LIST_TRANSACTIONS = 'ListTransactions';
export const PERMISSION_MANAGE_REPORTS = 'ManageReports';
export const PERMISSION_MANAGE_SETTINGS = 'ManageSettings';
export const PERMISSION_NOTIFY_TRANSACTION = 'NotifyTransaction';
export const PERMISSION_UPLOAD_FILE = 'UploadFile';
export const PERMISSION_GENERATE_PROBATIVE = 'GenerateProbative';
export const PERMISSION_LIST_TRANSACTIONS_ID = 'ListProcess';
export const PERMISSION_NOTIFY_CHARGEBACK = 'CreateChargeback';
