import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Form, Icon, Input, Tooltip } from '@frontend/shared-ui';
import { validateRequired } from '@frontend/shared-input-validators';
import { DomainWrapper, InvisibleButton } from './styles';
import { useTranslation } from "@frontend/feature-i18n";
import validateURL from "libs/shared-input-validators/src/lib/validate-url";

const AllowedDomains = ({ disabled, domains, updateCount = () => {/* */} }, ref) => {

  const [allowedDomainsForm] = Form.useForm();

  const [untouchedDomains, setUntouchedDomains] = useState(null);
  const [currentDomains, setCurrentDomains] = useState([]);

  const { getTranslation, getTranslationAsString } = useTranslation();

  const createDomain = useCallback((domain = '') => ({ domain, id: window.crypto.randomUUID() }), []);

  const addDomain = useCallback(() => {
    const domain = createDomain();
    setCurrentDomains(currentState => [...currentState, domain]);
    return domain;
  }, [createDomain]);

  const removeDomain = id => {
    setCurrentDomains(currentState => currentState.filter(domain => domain.id !== id));
  };

  const updateDomainValue = (id, value) => {
    setCurrentDomains(currentState =>
      currentState.map(
        domain =>
          domain.id === id
          ? { domain: value, id }
          : domain
      ));
  };

  const updateFieldsValues = useCallback(untouchedDomains => {
    allowedDomainsForm.setFieldsValue(
      untouchedDomains.reduce(
        (acc, currentDomain) => ({
          ...acc,
          [currentDomain.id]: currentDomain.domain
        }), {}
      )
    )
  }, [allowedDomainsForm]);

  const hasMoreThanOneDomain = useMemo(() => currentDomains.length > 1, [currentDomains]);

  const validate = useCallback(() =>
    allowedDomainsForm.validateFields()
      .then(fields =>
        Object.keys(fields)
          .filter(fieldKey => fields[fieldKey])
          .map(fieldKey => fields[fieldKey])
        )
  , [allowedDomainsForm]);

  useImperativeHandle(ref, () => {
    return {
      addDomain,
      count: currentDomains.length,
      validateFields: validate,
    };
  }, [addDomain, currentDomains, validate]);

  useEffect(() => {
    if (domains && domains !== untouchedDomains) {

      setUntouchedDomains(domains);

      const remappedDomains = domains.map(createDomain);

      setCurrentDomains(remappedDomains);
      updateFieldsValues(remappedDomains);
    }
  }, [createDomain, domains, untouchedDomains, updateFieldsValues]);

  useEffect(() => {
    updateCount(currentDomains?.length ?? 0);
  }, [currentDomains, updateCount]);

  return (
    <Form form={allowedDomainsForm} layout="vertical" requiredMark={false}>
      {
        currentDomains.map(
          (domain, index) => (
            <DomainWrapper key={domain.id}>
              <Form.Item
                name={domain.id}
                label={`${index + 1}. ${getTranslationAsString('settings.domainText')}`}
                rules={index > 0 ? [validateRequired(), validateURL()] : [validateURL()]}
              >
                <Input
                  addonAfter={
                    !disabled && hasMoreThanOneDomain &&
                    <Tooltip title={getTranslation('settings.deleteDomain')}>
                      <InvisibleButton data-testid="remove-domain" onClick={() => removeDomain(domain.id)}>
                        <Icon type="trash" />
                      </InvisibleButton>
                    </Tooltip>
                  }
                  disabled={disabled}
                  onChange={evt => updateDomainValue(domain.id, evt.target.value)}
                   />
              </Form.Item>
            </DomainWrapper>
          )
        )
      }
    </Form>
  )

};

AllowedDomains.propTypes = {
  disabled: PropTypes.bool,
  domains: PropTypes.arrayOf(PropTypes.string),
  updateCount: PropTypes.func,
}

export default forwardRef(AllowedDomains);