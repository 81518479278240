import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContextSelector } from 'use-context-selector';
import { TransactionSearchContext } from '../../context/transaction-search';

let searchTimeout;
const DEBOUNCE_TIME = 500;

const useTransactionSearch = (ROUTE_TRANSACTIONS) => {

  const reset = useContextSelector(TransactionSearchContext, context => context.reset);
  const search = useContextSelector(TransactionSearchContext, context => context.search);
  const setSearch = useContextSelector(TransactionSearchContext, context => context.setSearch);
  const searchTrigger = useContextSelector(TransactionSearchContext, context => context.searchTrigger);
  const setSearchTrigger = useContextSelector(TransactionSearchContext, context => context.setSearchTrigger);
  const lastSearch = useContextSelector(TransactionSearchContext, context => context.lastSearch);
  const setLastSearch = useContextSelector(TransactionSearchContext, context => context.setLastSearch);

  const navigate = useNavigate();

  const debouncedSet = useCallback(value => {
    navigate(ROUTE_TRANSACTIONS, { state: { search: value } });
  }, [ROUTE_TRANSACTIONS, navigate]);

  const pullSearchTrigger = useCallback(() => {
    setSearchTrigger(new Date());
  }, [setSearchTrigger]);

  const set = value => {
    setSearch(value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => debouncedSet(value), DEBOUNCE_TIME);
  }

  return {
    reset,
    search,
    set,
    setSearch,
    searchTrigger,
    pullSearchTrigger,
    lastSearch,
    setLastSearch,
  }
}

export default useTransactionSearch;
