import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { getIterableDateFilters, FILTER_DATE_PERIOD } from '@frontend/shared-defaults';
import RadioButtonGroup from "../../external/radio-button-group";
import RangePicker from "../../external/range-picker";
import { useTranslation } from '@frontend/feature-i18n';

const FilterByDate = ({
  disabled,
  handleChangeDateFilter,
  filterDatePeriodLabel,
  selectedDatePeriod,
  selectedFilter,
  setSelectedDatePeriod,
  filterDatePeriodPickerRef
}) => {

  const { getTranslation } = useTranslation();

  const getDatePeriodLabel = useCallback(filter =>
    selectedFilter === FILTER_DATE_PERIOD
    ? (filterDatePeriodLabel || filter.label)
    :  filter.label,
    [filterDatePeriodLabel, selectedFilter]  
  );

  return (
    <RadioButtonGroup
      items={
        getIterableDateFilters(getTranslation)
          .map(filter => ({ ...filter, onClick: () => handleChangeDateFilter(filter.value)}))
          .map(filter =>
            filter.value === FILTER_DATE_PERIOD
            ? {
              ...filter,
              disabled,
              label: getDatePeriodLabel(filter),
              extra:
                <RangePicker
                  dates={selectedDatePeriod}
                  onChange={([startDate, endDate]) => {
                    setSelectedDatePeriod([
                      startDate.startOf('day'),
                      endDate.endOf('day'),
                    ])
                  }}
                  ref={filterDatePeriodPickerRef} />
            }
            : { ...filter, disabled }
          )
      }
      value={selectedFilter}
      />
  );
}

FilterByDate.propTypes = {
  disabled: PropTypes.bool,
  handleChangeDateFilter: PropTypes.func.isRequired,
  filterDatePeriodLabel: PropTypes.string,
  selectedDatePeriod: PropTypes.array.isRequired,
  selectedFilter: PropTypes.string.isRequired,
  setSelectedDatePeriod: PropTypes.func.isRequired,
  filterDatePeriodPickerRef: PropTypes.any
}

export default FilterByDate;