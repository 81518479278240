import { Modal } from 'antd';
import styled from 'styled-components';
import { NEUTRAL_GRAY_400, NEUTRAL_GRAY_500 } from '../../defaults/colors';

export const StyledModal = styled(Modal)`

  .ant-modal-content {
    border-radius: 8px !important;
    overflow: hidden;
  }

  .ant-modal-title {
    color: ${NEUTRAL_GRAY_500};
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
  }

  .ant-modal-footer {
    border-top-color: transparent;
    display: flex;
    justify-content: end;
    gap: 12px;
    padding: 24px;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-form-item-label {
    color: ${NEUTRAL_GRAY_500};
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 6px;
  }

  input {
    color: ${NEUTRAL_GRAY_400};
    max-width: 320px;
    height: 44px;
  }

  .ant-input-affix-wrapper {
    color: ${NEUTRAL_GRAY_400};
    max-width: 320px;
    height: 44px;

    .ant-input {
      height: 32px;
    }
  }

  .ant-select {
    max-width: 320px;
  }

  .ant-form-item-explain-error {
    margin-bottom: 8px;
  }

`;
