import PropTypes from 'prop-types';
import { Wrapper } from "./styles";

const Column = ({ children, style, ...props }) => (
  <Wrapper style={style} {...props}>{ children }</Wrapper>
);

Column.propTypes = {
  children: PropTypes.element,
  style: PropTypes.any,
};

export default Column;
