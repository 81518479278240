import { useContextSelector } from 'use-context-selector';
import { NotificationSettingsContext } from '../../context/notification-settings';

export const useNotificationSettings = () => {
  const error = useContextSelector(
    NotificationSettingsContext,
    (context) => context.error
  );
  const company = useContextSelector(
    NotificationSettingsContext,
    (context) => context.company
  );
  const identity = useContextSelector(
    NotificationSettingsContext,
    (context) => context.identity
  );
  const setIdentity = useContextSelector(
    NotificationSettingsContext,
    (context) => context.setIdentity
  );
  const initialized = useContextSelector(
    NotificationSettingsContext,
    (context) => context.initialized
  );
  const name = useContextSelector(
    NotificationSettingsContext,
    (context) => context.name
  );
  const loading = useContextSelector(
    NotificationSettingsContext,
    (context) => context.loading
  );
  const orderSummary = useContextSelector(
    NotificationSettingsContext,
    (context) => context.orderSummary
  );
  const redirectUrl = useContextSelector(
    NotificationSettingsContext,
    (context) => context.redirectUrl
  );
  const template = useContextSelector(
    NotificationSettingsContext,
    (context) => context.template
  );
  const setTemplate = useContextSelector(
    NotificationSettingsContext,
    (context) => context.setTemplate
  );
  const desktopError = useContextSelector(
    NotificationSettingsContext,
    (context) => context.desktopError
  );
  const throwDesktopError = useContextSelector(
    NotificationSettingsContext,
    (context) => context.throwDesktopError
  );
  const utm = useContextSelector(
    NotificationSettingsContext,
    (context) => context.utm
  );
  const captureDate = useContextSelector(
    NotificationSettingsContext,
    (context) => context.captureDate
  );
  const expirationDate = useContextSelector(
    NotificationSettingsContext,
    (context) => context.expirationDate
  );
  const setCaptureDate = useContextSelector(
    NotificationSettingsContext,
    (context) => context.setCaptureDate
  );
  const analyticsId = useContextSelector(
    NotificationSettingsContext,
    (context) => context.analyticsId
  );
  const transactionId = useContextSelector(
    NotificationSettingsContext,
    (context) => context.transactionId
  );
  const transactionToken = useContextSelector(
    NotificationSettingsContext,
    (context) => context.transactionToken
  );
  const belvoURL = useContextSelector(
    NotificationSettingsContext,
    (context) => context.belvoURL
  );
  const setBelvoURL = useContextSelector(
    NotificationSettingsContext,
    (context) => context.setBelvoURL
  );
  const currentStep = useContextSelector(
    NotificationSettingsContext,
    (context) => context.currentStep
  );
  const setCurrentStep = useContextSelector(
    NotificationSettingsContext,
    (context) => context.setCurrentStep
  );
  const nextStep = useContextSelector(
    NotificationSettingsContext,
    (context) => context.nextStep
  );
  const setNextStep = useContextSelector(
    NotificationSettingsContext,
    (context) => context.setNextStep
  );
  const loadTransactionDetails = useContextSelector(
    NotificationSettingsContext,
    (context) => context.loadTransactionDetails
  );
  const publicID = useContextSelector(
    NotificationSettingsContext,
    (context) => context.publicID
  );
  const setPublicID = useContextSelector(
    NotificationSettingsContext,
    (context) => context.setPublicID
  );

  return {
    error,
    company,
    captureDate,
    setCaptureDate,
    identity,
    setIdentity,
    initialized,
    name,
    loading,
    orderSummary,
    redirectUrl,
    template,
    setTemplate,
    desktopError,
    throwDesktopError,
    utm,
    analyticsId,
    expirationDate,
    transactionId,
    transactionToken,
    belvoURL,
    setBelvoURL,
    currentStep,
    setCurrentStep,
    nextStep,
    setNextStep,
    loadTransactionDetails,
    publicID,
    setPublicID,
  };
};
