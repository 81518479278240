import { IDENTITY_LOGOUT, IDENTITY_AUTHORIZE } from '@frontend/shared-defaults';
import { logout as logoutToExpireTokenPkce } from '../../integrations/auth-pkce';

const getSSKeyAccessToken = () => {
  const productName = process.env.NX_PUBLIC_PRODUCT_NAME?.toLowerCase().replaceAll(' ', '_') || 'product';

  return `${productName}__access_token`;
}

export const getAccessTokenFromStorage = () =>
  sessionStorage.getItem(getSSKeyAccessToken());

export const removeAccessTokenInStorage = () =>
  sessionStorage.removeItem(getSSKeyAccessToken());

export const setAccessTokenInStorage = accessToken =>
  sessionStorage.setItem(getSSKeyAccessToken(), accessToken);

export const logout = () => {
  removeAccessTokenInStorage();
  window.location.assign(IDENTITY_LOGOUT);
}

export const logoutPkce = () => {
  const accessToken = getAccessTokenFromStorage();
  logoutToExpireTokenPkce(accessToken);
  logout();
}

export const authorize = () => {
  window.location.assign(IDENTITY_AUTHORIZE);
}


