import { BLUE_500 } from 'libs/shared-ui/src/defaults/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  background: rgb(255 255 255 / 35%);
  color: ${BLUE_500};
  display: flex;
  font-size: 30px;
  justify-content: center;
  left: 0;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 12000;
`;
