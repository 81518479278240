import styled from 'styled-components';
import { NEUTRAL_GRAY_100 } from '../../defaults/colors';

export const Wrapper = styled.div`
  display: inline-block;

  ${({ show }) => show && `
    background: ${NEUTRAL_GRAY_100};
    min-width: 50px;
    color: ${NEUTRAL_GRAY_100};
    pointer-events: none;
    user-select: none;
  `}
`;