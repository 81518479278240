import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${({ labelBackgroundColor }) => labelBackgroundColor};
  border-radius: 16px;
  color: ${({ labelColor }) => labelColor};
  display: inline-block;
  padding: 2px 8px 2px 22px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  position: relative;

  &::before {
    background: ${({ labelColor }) => labelColor};
    border-radius: 50%;
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    position: absolute;
    left: 9px;
    top: 8px;
  }
`