import { validateCPF as validate } from '@frontend/shared-utility';

const allowedChars = ['_', '.', ',', '-'];

const validateCPF = (errorMessage = 'CPF inválido') =>
  () => ({
    validator(_, value) {
      if (value) {
        let cleanValue = value;

        allowedChars.forEach(
          char => { cleanValue = cleanValue.split(char).join('') }
        )

        if (cleanValue.length < 11) {
          return Promise.resolve();
        }

        if (validate(cleanValue)) {
          return Promise.resolve();
        }

        return Promise.reject(new Error(errorMessage));
      }
      return Promise.resolve();
    }
  });

export default validateCPF;
