const Logo = props => (
  <svg {...props} width="92" height="20" viewBox="0 0 92 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.9013 2.50469V0.762943C25.9013 0.420424 25.6244 0.143494 25.2819 0.143494H22.8187C22.4761 0.143494 22.1992 0.420424 22.1992 0.762943V10.266C23.7588 7.48943 23.6422 4.33388 25.9013 2.50469Z" fill="#E72A5F" />
    <path d="M19.1165 0.136194H16.6533C16.3107 0.136194 16.0338 0.413124 16.0338 0.755643V10.0037C16.0338 13.4216 13.2354 16.1981 9.81017 16.169C6.41413 16.1326 3.71042 13.3195 3.71042 9.93077V0.748355C3.71042 0.405836 3.43349 0.128906 3.09097 0.128906H0.62775C0.285231 0.128906 0.00830078 0.405836 0.00830078 0.748355V9.89434C0.00830078 15.3455 4.38817 19.8347 9.83932 19.8492C15.2905 19.8638 19.7286 15.4329 19.7286 9.98907V0.748355C19.7359 0.413124 19.459 0.136194 19.1165 0.136194Z" fill="url(#paint0_linear_311_18941)" />
    <path d="M32.0594 0.136169C32.0521 0.136169 32.0375 0.136169 32.0302 0.136169C26.5791 0.150745 22.1992 4.63993 22.1992 10.0911V19.2371C22.1992 19.5796 22.4761 19.8565 22.8187 19.8565H25.2819C25.6244 19.8565 25.9013 19.5796 25.9013 19.2371V9.99634C25.9013 6.57844 28.6998 3.80185 32.125 3.831C35.521 3.86744 38.2247 6.68046 38.2247 10.0692V19.2371C38.2247 19.5796 38.5017 19.8565 38.8442 19.8565H41.3074C41.6499 19.8565 41.9268 19.5796 41.9268 19.2371V9.99634C41.9196 4.55976 37.496 0.136169 32.0594 0.136169Z" fill="url(#paint1_linear_311_18941)" />
    <path d="M81.6736 0.136169C76.2371 0.136169 71.8135 4.55976 71.8135 9.99634C71.8135 15.4329 76.2371 19.8565 81.6736 19.8565C87.1102 19.8565 91.5338 15.4329 91.5338 9.99634C91.5338 4.55976 87.1102 0.136169 81.6736 0.136169ZM81.6736 16.1617C78.2776 16.1617 75.5083 13.3997 75.5083 9.99634C75.5083 6.6003 78.2703 3.831 81.6736 3.831C85.0697 3.831 87.839 6.59301 87.839 9.99634C87.839 13.3924 85.0697 16.1617 81.6736 16.1617Z" fill="url(#paint2_linear_311_18941)" />
    <path d="M47.4723 0.136169H45.0091C44.6666 0.136169 44.3896 0.413099 44.3896 0.755618V19.2443C44.3896 19.5869 44.6666 19.8638 45.0091 19.8638H47.4723C47.8148 19.8638 48.0918 19.5869 48.0918 19.2443V0.748331C48.0845 0.4131 47.8075 0.136169 47.4723 0.136169Z" fill="url(#paint3_linear_311_18941)" />
    <path d="M68.9275 12.7948H65.9906C65.7574 12.7948 65.5461 12.9187 65.4295 13.1154C64.3582 14.9446 62.3687 16.1835 60.0949 16.1762C56.8082 16.169 54.0826 13.5454 53.9514 10.2587C53.813 6.75334 56.626 3.85286 60.1095 3.85286C62.3614 3.85286 64.3363 5.06989 65.4149 6.88451C65.5461 7.10314 65.7793 7.23432 66.0271 7.23432H68.9202C69.2482 7.23432 69.4741 6.90637 69.3575 6.60029C67.9291 2.73785 64.1396 0.0122748 59.7524 0.165315C54.5782 0.347506 50.4024 4.55976 50.2566 9.72669C50.0963 15.2872 54.5782 19.8711 60.1022 19.8711C64.3436 19.8711 67.9656 17.1819 69.3502 13.4142C69.4668 13.1154 69.2482 12.7948 68.9275 12.7948Z" fill="url(#paint4_linear_311_18941)" />
    <defs>
      <linearGradient id="paint0_linear_311_18941" x1="-0.142765" y1="9.9891" x2="91.506" y2="9.9891" gradientUnits="userSpaceOnUse">
        <stop stopColor="#439CD6" />
        <stop offset="0.5" stopColor="#A6529A" />
        <stop offset="1" stopColor="#E72B68" />
      </linearGradient>
      <linearGradient id="paint1_linear_311_18941" x1="-0.142688" y1="9.99634" x2="91.5061" y2="9.99634" gradientUnits="userSpaceOnUse">
        <stop stopColor="#439CD6" />
        <stop offset="0.5" stopColor="#A6529A" />
        <stop offset="1" stopColor="#E72B68" />
      </linearGradient>
      <linearGradient id="paint2_linear_311_18941" x1="-0.142621" y1="9.99634" x2="91.5061" y2="9.99634" gradientUnits="userSpaceOnUse">
        <stop stopColor="#439CD6" />
        <stop offset="0.5" stopColor="#A6529A" />
        <stop offset="1" stopColor="#E72B68" />
      </linearGradient>
      <linearGradient id="paint3_linear_311_18941" x1="-0.143093" y1="9.99998" x2="91.5056" y2="9.99998" gradientUnits="userSpaceOnUse">
        <stop stopColor="#439CD6" />
        <stop offset="0.5" stopColor="#A6529A" />
        <stop offset="1" stopColor="#E72B68" />
      </linearGradient>
      <linearGradient id="paint4_linear_311_18941" x1="-0.142677" y1="10.0151" x2="91.5061" y2="10.0151" gradientUnits="userSpaceOnUse">
        <stop stopColor="#439CD6" />
        <stop offset="0.5" stopColor="#A6529A" />
        <stop offset="1" stopColor="#E72B68" />
      </linearGradient>
    </defs>
  </svg>
);

export default Logo;