import { Button } from 'antd';
import styled from 'styled-components';

const Link = styled(Button).attrs({
  type: 'link'
})`
  & span {
    text-decoration: underline;
  }
`

export default Link;