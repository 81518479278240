import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  border-radius: 50%;
  bottom: -23px;
  display: flex;
  height: 46px;
  justify-content: center;
  position: absolute;
  opacity: 0;
  right: 12px;
  width: 46px;
  transform: scale(.5);
  transition: .25s transform ease, .1s opacity ease;

  ${({ color }) => color && `
    background: ${color};
    opacity: 1;
    transform: scale(1);
  `}
`
