import PropTypes from 'prop-types';
import Sidebar from '../../sidebar';
import Topbar from '../../topbar';
import Column from '../../column';
import PageContent from './page-content';
import { Container, GlobalStyle } from './styles';
import Footer from './footer';

const BasicLayout = ({ children, footerSettings, sidebarSettings, topbarSettings }) => (
  <>
    <GlobalStyle />
    <Container>
      <Column>
        <Sidebar sidebarSettings={sidebarSettings} />
      </Column>
      <Column style={{ flex: 1, flexDirection: 'column', height: '100vh' }}>
        <Topbar topbarSettings={topbarSettings} />
        <PageContent>{children}</PageContent>
        <Footer footerSettings={footerSettings} />
      </Column>
    </Container>
  </>
);

BasicLayout.defaultProps = {
  footerSettings: {},
  topbarSettings: {}
}

BasicLayout.propTypes = {
  children: PropTypes.element,
  sidebarSettings: PropTypes.shape({
    items: PropTypes.array.isRequired,
    selected: PropTypes.string,
  }).isRequired,
  footerSettings: PropTypes.object,
  topbarSettings: PropTypes.object,
}

export default BasicLayout;
