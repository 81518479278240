import { NEUTRAL_GRAY_200, NEUTRAL_GRAY_400, NEUTRAL_GRAY_500 } from 'libs/shared-ui/src/defaults/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  color: ${NEUTRAL_GRAY_500};
  display: flex;
  justify-content: space-between;
  min-width: 240px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 8px;
  border-bottom: 1px solid ${NEUTRAL_GRAY_200};

  & > div:first-child {
    align-items: center;
    display: flex;
    gap: 16px;

    p {
      color: ${NEUTRAL_GRAY_400};
      margin: 0;
    }
  }
`;
