import { Tooltip } from 'antd';
import styled from 'styled-components';

export const StyledTooltip = styled(Tooltip).attrs({
  overlayInnerStyle: {
    borderRadius: '8px',
    color: 'white',
    padding: '0px',
    maxWidth: '100%',
  },
  overlayStyle: {
    maxWidth: '350px',
    paddingRight: '8px',
  }
})``;

export const BackgroundHelp = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: ${props => props.visible ? 'block' : 'none'};
  animation: fadeIn 0.5s;
`;

export const ButtonHelp = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  background: #fff;
  border-radius: 64px;
  margin: 16px;
  z-index: 10000;
  box-shadow: 0px 2px 4px -2px rgba(22, 22, 22, 0.06);
  box-shadow: 0px 4px 8px -2px rgba(22, 22, 22, 0.4);
  width: 64px;
  height: 64px;

  p {
    width: 100%;
    text-align: center;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0px;
    cursor: pointer;
  }
`;
