import { NEUTRAL_GRAY_200 } from '../../defaults/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  flex: 1;
  display: flex;
  height: 50vh;
  justify-content: center;

  .ant-empty-image {
    height: initial;
  }

  svg {
    width: 240px;
    height: auto;
    margin-bottom: 24px;
  }

  h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`

export const IconWrapper = styled.div`
  background-color: ${NEUTRAL_GRAY_200};
  border-radius: 50%;
  display: inline-flex;
  padding: 26px;
`
