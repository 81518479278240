
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { Backdrop, DropdownTitle, MenuWrapper, StyledDropdown } from './styles';

const Dropdown = ({ showBackdrop, onOpenChange, title, ...props }, ref) => {

  const [open, setOpen] = useState(false);

  const handleOpenChange = status => {
    setOpen(status);

    if (onOpenChange) {
      onOpenChange(status);
    }
  }

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen])

  useImperativeHandle(ref, () => ({ close }), [close]);

  return (
    <>
      {showBackdrop && <Backdrop show={open} />}
      <StyledDropdown
        {...props}
        onOpenChange={handleOpenChange}
        title={title}
        dropdownRender={(menu) =>
          title
          ? (
            <>
              <DropdownTitle>{title}</DropdownTitle>
              <MenuWrapper>{menu}</MenuWrapper>
            </>
          )
          : menu
        } />
    </>
  )
}

export default forwardRef(Dropdown);
