import styled from 'styled-components';

export const StyledButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  opacity: 1;
  margin: 0;
  padding: 0;
  line-height: initial;
  transition: .2s opacity ease;
  user-select: none;

  &:hover {
    opacity: .8;
  }
`
