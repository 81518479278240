const validateRequired = (errorMessage = 'Campo obrigatório') =>
  () => ({
    validator(_, value) {
      if (!value) {
        return Promise.reject(new Error(errorMessage));
      }
      return Promise.resolve();
    },
    required: true
  });

export default validateRequired;
