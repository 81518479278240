import ErrorTemplate from "../../components/error-template";
import { Link } from "@acesso-io/genoma-ui";
import { useTranslation } from '@frontend/feature-i18n';

const Error5xx = () => {
  const { getTranslation, getTranslationAsString } = useTranslation();

  const helpLink = "https://empresas.unico.io/hc/pt-br"

  return (
    <ErrorTemplate
      errorCode='5xx'
      title={getTranslationAsString('SystemMisbehaved', {}, 'Nosso sistema não se comportou como deveria')}
    >
      <span>
        {getTranslation(
          "ContactSupport",
          null,
          {
            Link: <Link href={helpLink} target="_blank" rel="noreferrer noopener" />,
          },
          "Pedimos desculpa pelo transtorno, por favor <Link href={helpLink} target='_blank' rel='noreferrer noopener'>entre em contato com nossa equipe de suporte.</Link>"
        )}
      </span>
    </ErrorTemplate>
  );
};

export default Error5xx;
