import { TEMPLATES } from "../../defaults/template-types";

export const templateByTag = (tags) => {
  const tag = tags.find(tag => {
    const tagNormalized = tag.toUpperCase();
    return tagNormalized.startsWith('TEMPLATE_') && TEMPLATES[tagNormalized.replace('TEMPLATE_', '')];
  });

  return tag?.toUpperCase().replace('TEMPLATE_', '') || null;
}
