import styled from 'styled-components';
import { BLUE_400, NEUTRAL_GRAY_300, NEUTRAL_GRAY_400, WHITE } from '../colors';

export const StyledButton = styled.button`
  align-items: center;
  background-color: transparent;
  box-shadow: 0px 1px 2px rgba(19, 21, 22, 0.05);
  border-radius: 1000px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  gap: 10px;
  line-height: 15px;
  height: 40px;
  outline: none;
  padding: 10px 24px;
  transition: 0.2s opacity ease;
  user-select: none;

  ${({ primary }) =>
    primary
      ? `
        border: 1px solid ${BLUE_400};
        color: ${BLUE_400};
      `
      : `
        border: 1px solid ${NEUTRAL_GRAY_300};
        color: ${NEUTRAL_GRAY_400};
      `}

  ${({ outline }) =>
    !outline &&
    `
      border: none;
      background-color: ${BLUE_400};
      color: ${WHITE};
    `}

  ${({ transparent }) =>
    transparent &&
    `
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: ${NEUTRAL_GRAY_400};
  `}

  ${({ disabled }) =>
    disabled &&
    `
    opacity: .55;
    pointer-events: none;
  `}

  &:hover {
    opacity: 0.85;
  }

  & svg {
    ${({ iconSize }) => (iconSize ? `height: ${iconSize}px;` : 'height: 10px;')}

    ${({ svgStyle }) => !!svgStyle && svgStyle}
  }

  & span {
    vertical-align: middle;
  }
`;
