import { Tabs } from 'antd';
import styled from 'styled-components';
import { BLACK } from '../../defaults/colors';

export const StyledTabs = styled(Tabs).attrs({
  tabBarStyle: {
    marginBottom: 30
  },
  tabBarGutter: 36
})`
  .ant-tabs-tab {
    color: ${BLACK};
    font-size: 14px;
    font-weight: 400;
    padding: 20px 10px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${BLACK};
  }
`
