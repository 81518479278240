import { useContextSelector } from 'use-context-selector';
import { AuthContext } from "../../context/auth"

const useAuth = () => {

  const accessToken = useContextSelector(AuthContext, context => context.accessToken);
  const setAccessToken = useContextSelector(AuthContext, context => context.setAccessToken);

  return {
    accessGranted: Boolean(accessToken),
    accessToken,
    setAccessToken,
  }
}

export default useAuth;
